import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import {
  TableHead as MuiTableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Typography } from "components";

// Hooks
import { useScreens } from "hooks";

const StyledTableHead = styled(TableCell)`
  padding: 16px 0;
  border: none;
`;

const TableHeadInnerCell = styled.div`
  display: flex;
  align-items: center;
  display: flex;
  justify-content: ${({ isFlexEnd }) =>
    isFlexEnd ? "flex-end" : "flex-start"};
`;

export const TableHead = ({ tableHeadData }) => {
  const { tabletScreens } = useScreens();
  return (
    <MuiTableHead>
      <TableRow>
        {tableHeadData?.map((dataItem, i) => {
          if (dataItem.isHidden) return null;
          return (
            <StyledTableHead key={dataItem.name}>
              <TableHeadInnerCell isFlexEnd={dataItem.isFlexEnd}>
                {dataItem.title ? (
                  <Typography
                    variant={tabletScreens ? "h3" : "h6"}
                    color={dataItem.color || "black"}
                  >
                    {dataItem.title}
                  </Typography>
                ) : (
                  dataItem.component
                )}
              </TableHeadInnerCell>
            </StyledTableHead>
          );
        })}
      </TableRow>
    </MuiTableHead>
  );
};

TableHead.propTypes = {
  tableHeadData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        colSpan: PropTypes.number,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
      }),
      PropTypes.shape({
        component: PropTypes.element,
        colSpan: PropTypes.number,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
      }),
    ])
  ),
};
