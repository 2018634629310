// Utils
import {
  createReducer,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";
import { setValue } from "utils";

// Services
import { getAuthData } from "service";

// Actions
import { AUTHENTICATION, SET_AUTHENTICATION } from "constants/index";

import amplitude from "amplitude-js";

const initialState = {
  isAuthenticated: false,
  error: null,
  loading: true,
};

export const authentication = createAsyncThunk(
  AUTHENTICATION,
  async ({ redirectUri, nonce, state, realm }, { rejectWithValue }) => {
    try {
      amplitude
        .getInstance()
        .logEvent("Loading Authentication", { loading: true });
      return await getAuthData(redirectUri, nonce, state, realm);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const setAuthentication = createAction(SET_AUTHENTICATION);

export const authenticationReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setAuthentication, (state, action) => {
      state.isAuthenticated = action.payload;
      state.loading = false;
    })
    .addCase(authentication.fulfilled, (state, action) => {
      const { access_token, expires_in, refresh_token, username } =
        action.payload || {};

      setValue("access_token", access_token);
      setValue("expires_in", expires_in);
      setValue("refresh_token", refresh_token);
      setValue("username", username);

      state.isAuthenticated = true;
      state.loading = false;
      amplitude
        .getInstance()
        .logEvent("Loading Authentication", { loading: false });
    })
    .addCase(authentication.rejected, (state, action) => {
      state.isAuthenticated = false;
      state.error = action.payload;
      state.loading = false;
      amplitude
        .getInstance()
        .logEvent("Loading Authentication", {
          loading: false,
          error: action.payload,
        });
    })
);
