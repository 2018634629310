import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Components
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { Typography } from 'components/Typography';
import { CustomIcon } from 'components/Icon/Icon';

// Constants
import { TOOLTIP } from 'constants/index';

const StyledTooltipContent = styled.div`
  display: block;
  padding: 12px 20px;
  box-shadow: 0px 4px 20px rgba(211, 214, 216, 0.6);
  border-radius: 4px;
`;

const StyledTooltip = styled.div`
  ${({ theme, hasChildren }) => `
	cursor: ${hasChildren ? 'regular' : 'pointer'};
	.MuiTooltip-tooltipPlacementBottom {
		margin-top: 7px;
	}
	.MuiTooltip-tooltip {
		background-color: ${theme.palette.white};
		padding: 0;
	}
	.MuiTooltip-popper {
		left: 62px !important;
	}
`}
`;

const StaticTooltipWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const TooltipPopper = ({ text }) => {
  return (
    <StyledTooltipContent>
      <Typography variant="caption" color="black">
        {text}
      </Typography>
    </StyledTooltipContent>
  );
};

export const Tooltip = ({ text, children }) => {
  const hasChildren = React.Children.count(children);
  return (
    <StyledTooltip hasChildren={hasChildren}>
      {!hasChildren ? (
        <MuiTooltip
          PopperProps={{ disablePortal: true }}
          title={<TooltipPopper text={text} />}
        >
          <CustomIcon type={TOOLTIP} />
        </MuiTooltip>
      ) : (
        <StaticTooltipWrapper>
          <CustomIcon type={TOOLTIP} />
          {children}
        </StaticTooltipWrapper>
      )}
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  text: PropTypes.string,
};

Tooltip.defaultProps = {
  tooltip: '',
};
