// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const ocpKey = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;

const api = apiConfig(baseURL, ocpKey);

export async function getEmployeeDetails(id) {
  const { data } = await api.get(`/compass/employee?id=${id}`);

  return data?.hits?.hits?.[0]?._source;
}

export async function getEmployeeCompanies(id) {
  const { data } = await api.get(`/compass/companies?id=${id}`);

  return data.hits.hits.map(
    ({
      _source: {
				id,
        name,
        acquisitionDate,
        exitDate,
        sector,
        country: geography,
        dealTeam,
        investmentSupportTeam,
        iLevelURL,
      },
    }) => ({
      name: { companyName: name, id },
      acquisitionDate,
      exitDate,
      sector,
      geography,
      dealTeam,
      investmentSupportTeam,
    })
  );
}

export async function getEmployeeExpertise(id) {
  const { data } = await api.get(`/compass/expertise?id=${id}`);

  return data.aggregations.ExpertiseByPerson.buckets[0]?.Expertise?.ExpertiseItem?.buckets?.map(
    ({ key }) => ({ key })
  );
}
