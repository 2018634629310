import React, { forwardRef } from "react";
import PropTypes from "prop-types";

// Utils
import { Controller, useWatch } from "react-hook-form";
import styled from "styled-components";

// Components
import { TextField as MUIInput } from "@material-ui/core";
import { InputLabel } from "components/InputLabel";

export const Input = styled(
  forwardRef((props, ref) => <MUIInput ref={ref} {...props} />)
)`
  ${({ theme, value }) => `
		width: 100%;
		margin-bottom: 24px;
		.MuiInputBase-root.Mui-disabled {
			background-color: ${theme.palette.graySuperLight};
		}
		.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
		}
		.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
			border-width: 1px;
		}
		.MuiInputBase-input:hover.MuiOutlinedInput-input.Mui-disabled {
			border-color: rgba(161, 171, 187, 0.5);
			border-width: 1px;
		}
		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.blueAccent};
			border-width: 1px;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
		}
		.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.red};
			border-width: 1px;
		}
		.MuiInputBase-root {
			height: 36px;
		}
		.MuiFormHelperText-root.Mui-error {
			display: flex;
			justify-content: flex-end;
			color: ${theme.palette.red};
			margin-right: 0;
			font-size: 10px;
		}
		&&& .MuiInputBase-input {
			color: ${theme.palette.black};
			font-size: 14px;
			font-weight: ${theme.typography.body1.fontWeight};
			line-height: ${theme.typography.body1.lineHeight};

			&::-webkit-calendar-picker-indicator {
				opacity: 0.5;
			}

			&::-webkit-datetime-edit-text,
			&::-webkit-datetime-edit-month-field,
			&::-webkit-datetime-edit-day-field,
			&::-webkit-datetime-edit-year-field {
				font-size: 14px;
				font-weight: ${theme.typography.body1.fontWeight};
				line-height: ${theme.typography.body1.lineHeight};
  			color: ${value ? theme.palette.black : theme.palette.darkGray};
			}

			&::placeholder {
				color: ${theme.palette.darkGray};
				opacity: 1 !important;
				font-size: 14px;
				font-weight: ${theme.typography.body1.fontWeight};
				line-height: ${theme.typography.body1.lineHeight};
			}
		}
    &&& .MuiOutlinedInput-multiline {
      height: auto;
      padding: 8px 16px;

      .MuiInputBase-input {
        padding-left: 0;
      }
    }
	`}
`;

export function DatePicker({
  inputProps: {
    label,
    tooltip,
    labelTypographyVariant,
    minDate,
    placeholder,
    ...inputProps
  },
  controllerProps,
}) {
  const value = useWatch({
    control: controllerProps.control,
    name: controllerProps.name,
  });

  return (
    <Controller
      render={({ field, fieldState }) => (
        <>
          {label && (
            <InputLabel
              text={label}
              tooltip={tooltip}
              typographyVariant={labelTypographyVariant}
            />
          )}
          <Input
            type="date"
            inputProps={{
              min: minDate,
              placeholder,
            }}
            {...field}
            variant="outlined"
            error={Boolean(fieldState?.error)}
            helperText={fieldState?.error?.message}
            {...inputProps}
            value={Boolean(value) ? value : ""}
          />
        </>
      )}
      {...controllerProps}
      defaultValue={value || ""}
    />
  );
}

DatePicker.propTypes = {
  inputProps: PropTypes.shape({
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    labelTypographyVariant: PropTypes.string,
  }),
  controllerProps: PropTypes.shape({
    control: PropTypes.object,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    tooltip: PropTypes.string,
  }),
};

DatePicker.defaultProps = {
  inputProps: PropTypes.shape({
    label: "",
    autoFocus: false,
    placeholder: "",
    tooltip: "",
    labelTypographyVariant: "h6",
  }),
};
