// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const ocpKey = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;

const api = apiConfig(baseURL, ocpKey);

export async function getOktaApps(username) {
  const { data } = await api.get(`/okta/applications/${username}`, {
    transformRequest: (data, headers) => {
      headers["Ocp-Apim-Subscription-Key"] = ocpKey;

      return data;
    }
  });

  return data;
}
