import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

// Utils
import { Controller, useWatch } from 'react-hook-form';
import styled from 'styled-components';

// Components
import { InputAdornment, TextField as MUIInput } from '@material-ui/core';
import { InputLabel } from 'components/InputLabel';
import { Icon } from 'components';

// Constants
import { SEARCH_MUI } from 'constants/icons';

export const Input = styled(
  forwardRef(({ isSearchInput, ...rest }, ref) => (
    <MUIInput ref={ref} {...rest} />
  ))
)`
  ${({ theme }) => `
		width: 100%;
		margin-bottom: 24px;
		.MuiInputBase-root.Mui-disabled {
			background-color: ${theme.palette.graySuperLight};
		}
		.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
		}
		.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
			border-width: 1px;
		}
		.MuiInputBase-input:hover.MuiOutlinedInput-input.Mui-disabled {
			border-color: rgba(161, 171, 187, 0.5);
			border-width: 1px;
		}
		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.blueAccent};
			border-width: 1px;
		}
		.MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
		}
		.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
			border-color: ${theme.palette.red};
			border-width: 1px;
		}
		.MuiInputBase-root {
			height: 36px;
		}
		.MuiFormHelperText-root.Mui-error {
			display: flex;
			justify-content: flex-end;
			color: ${theme.palette.red};
			margin-right: 0;
			font-size: 10px;
		}
		&&& .MuiInputBase-input {
			padding-left:${({ isSearchInput }) => (isSearchInput ? 0 : '16px')};
			color: ${theme.palette.black};
			font-size: 14px;
			font-weight: ${theme.typography.body1.fontWeight};
			line-height: ${theme.typography.body1.lineHeight};

			&::placeholder {
				color: ${theme.palette.darkGray};
				opacity: 1 !important;
				font-size: 14px;
				font-weight: ${theme.typography.body1.fontWeight};
				line-height: ${theme.typography.body1.lineHeight};
			}
		}
    &&& .MuiOutlinedInput-multiline {
      height: auto;
      padding: 8px 16px;

      .MuiInputBase-input {
        padding-left: 0;
      }
    }
	`}
`;

export function TextInput({
  inputProps: { label, tooltip, labelTypographyVariant, ...inputProps },
  controllerProps,
  isSearchInput,
}) {
  const value = useWatch({
    control: controllerProps.control,
    name: controllerProps.name,
  });

  return (
    <Controller
      render={({ field, fieldState }) => (
        <>
          {label && (
            <InputLabel
              text={label}
              tooltip={tooltip}
              typographyVariant={labelTypographyVariant}
            />
          )}
          <Input
            autoComplete="off"
            isSearchInput={isSearchInput}
            {...field}
            variant="outlined"
            error={Boolean(fieldState?.error)}
            helperText={fieldState?.error?.message}
            {...inputProps}
            value={Boolean(value) ? value : ''}
            {...(isSearchInput && {
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon type={SEARCH_MUI} color="darkGray" />
                  </InputAdornment>
                ),
              },
            })}
          />
        </>
      )}
      {...controllerProps}
      defaultValue={value || ''}
    />
  );
}

TextInput.propTypes = {
  inputProps: PropTypes.shape({
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    labelTypographyVariant: PropTypes.string,
  }),
  controllerProps: PropTypes.shape({
    control: PropTypes.object,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    tooltip: PropTypes.string,
  }),
  isSearchInput: PropTypes.bool,
};

TextInput.defaultProps = {
  inputProps: PropTypes.shape({
    label: '',
    autoFocus: false,
    placeholder: '',
    tooltip: '',
    labelTypographyVariant: 'h6',
  }),
  isSearchInput: false,
};
