import PropTypes from "prop-types";

// Components
import { Route } from "react-router-dom";
import { Suspense } from "react";
import { Loader } from "components/Loader";

export const RouteWithSuspense = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={props => (
      <Suspense fallback={<Loader fullPage />}>
        <Component {...props} />
      </Suspense>
    )}
  />
);

RouteWithSuspense.propTypes = {
  component: PropTypes.elementType.isRequired
};
