import { useCallback, useEffect } from "react";

// Utils
import { useHistory } from "react-router-dom";

export function useResetState(status, setError, initValue) {
  const history = useHistory();
  const resetState = useCallback(
    () => setError(initValue),
    [initValue, setError]
  );

  useEffect(() => {
    if (status) {
      resetState();
    }
    return () => history.listen(() => resetState());
  }, [history, resetState, status]);
}
