import { useCallback, useMemo } from "react";

// Components
import { CSVExport } from "components";

// Utils
import { format } from "date-fns";

const headers = [
  { label: "Company Name", key: "name.companyName" },
  { label: "Acquisition Date", key: "acquisitionDate" },
  { label: "Exit Date", key: "exitDate" },
  { label: "Geography", key: "geography" },
  { label: "Sector", key: "sector" },
  { label: "Deal Team", key: "dealTeam" },
  { label: "Investment Support Team", key: "investmentSupportTeam" },
];

export function EmployeeCompaniesExport({ employeeCompanies }) {
  const formatDate = useCallback(
    (d) => format(new Date(d), "MMM. dd, yyyy"),
    []
  );

  const reduceTeamData = useCallback(
    (t) =>
      t.reduce(
        (a, { name }, i) =>
          a.concat(`${name} ${i === t.length - 1 ? "" : ", "}`),
        ""
      ),
    []
  );

  const mappedEmployeeCompanies = useMemo(
    () =>
      employeeCompanies.map(
        ({
          dealTeam,
          investmentSupportTeam,
          acquisitionDate,
          exitDate,
          ...rest
        }) => ({
          ...rest,
          acquisitionDate: formatDate(acquisitionDate),
          exitDate: exitDate ? formatDate(exitDate) : "Active",
          dealTeam: reduceTeamData(dealTeam),
          investmentSupportTeam: reduceTeamData(investmentSupportTeam),
        })
      ),
    [employeeCompanies, formatDate, reduceTeamData]
  );

  return (
    <CSVExport
      data={mappedEmployeeCompanies}
      headers={headers}
      filename="knowledge-map-employee-companies.csv"
    />
  );
}
