import { useEffect, useMemo } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getUserData } from "redux/reducers/userReducer";

export function useUserData() {
  const dispatch = useDispatch();

  const { user, loading, error } = useSelector(({ user }) => user);

  const memoState = useMemo(
    () => ({
      user,
      loading,
      error,
    }),
    [error, loading, user]
  );

  useEffect(() => {
    if (!memoState.user) {
      dispatch(getUserData());
    }
  }, [dispatch, memoState.user]);

  return { ...memoState };
}
