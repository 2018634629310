import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";

// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import { dismissToastFromStore } from "redux/reducers/toastReducer";

// Components
import { Toast } from "../components/Toast";

const ToastContext = createContext({ openToast: () => {} });

export const ToastProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { toast: reduxToast, open: isOpen } = useSelector(({ toast }) => toast);

  const [open, setOpen] = useState(true);
  const [toast, setToast] = useState(null);

  const openToast = useCallback((data) => {
    setOpen(true);
    setToast(data);
  }, []);

  const dismissToast = useCallback(() => {
    setOpen(false);
    setToast(null);
    dispatch(dismissToastFromStore());
  }, [dispatch]);

  const actionsValue = useMemo(
    () => ({
      openToast,
      dismissToast,
    }),
    [openToast, dismissToast]
  );

  return (
    <ToastContext.Provider value={actionsValue}>
      {children}
      {(toast || reduxToast) && (
        <Toast
          open={open || isOpen}
          toast={toast || reduxToast}
          dismissToast={dismissToast}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
