import { useEffect, useState } from "react";

// Service
import { getEmployeeExpertise } from "service";

export function useEmployeeExpertise(id) {
  const [employeeExpertise, setEmployeeExpertise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!employeeExpertise) {
      (async () => {
        try {
          const data = await getEmployeeExpertise(id);

          setEmployeeExpertise(data);
          setLoading(false);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      })();
    }
  }, [id, employeeExpertise]);

  return {
    employeeExpertise,
    setEmployeeExpertise,
    loading,
    error,
  };
}
