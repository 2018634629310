import { useEffect } from "react";

// Utils
import { useLinearProgressContext, useModal } from "contexts";
import { errors } from "../utils";

// Components
import { FormActionMessageModal } from "components/FormActionMessageModal";

export function useHandle409(status, type, props) {
  const { onOpenModal, onCloseModal } = useModal();
  const { setProgress } = useLinearProgressContext();

  useEffect(() => {
    if (status === 409) {
      onOpenModal(
        <FormActionMessageModal
          {...errors.modal[type](props)}
          onCancel={onCloseModal}
        />,
        { modalWidth: "516px" }
      );
      setProgress(false);
    }
  }, [onCloseModal, onOpenModal, props, setProgress, status, type]);
}
