// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const ocpKey = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;

const api = apiConfig(baseURL, ocpKey);

export async function getWelcomePage() {
  const { data: response } = await api.get("/compass/welcomepage");
  const {
    data: {
      title,
      subtitle,
      contactEmail: email,
      contactPerson: name,
      welcomePageNews: news,
    },
  } = response;
  return {
    welcomePage: {
      title,
      subtitle,
      email,
      name,
    },
    news: news.sort(function (a, b) {
      return a.sortOrder - b.sortOrder;
    }),
  };
}

export async function getQuickLinks() {
  const { data: response } = await api.get("/compass/quicklinks");
  const links = response.data;
  return links.map(({ id, title, url }) => ({ id, title, url }));
}

export async function updateWelcomePage(payload) {
  const dataToSend = JSON.stringify(payload);
  const { data: response } = await api.patch(
    "/compass/welcomepage",
    dataToSend
  );
  return response.data;
}

export async function updateQuickLinks(payload) {
  const dataToSend = JSON.stringify(payload);
  const { data: response } = await api.patch("/compass/quicklinks", dataToSend);
  return response.data;
}

export async function updateSingleNews(id, payload) {
  const dataToSend = JSON.stringify(payload);
  const { data: response } = await api.patch(
    `/compass/welcomepage/news/${id}`,
    dataToSend
  );
  return response.data;
}

export async function deleteSingleNews(id) {
  const { data: response } = await api.delete(
    `/compass/welcomepage/news/${id}`
  );
  return response.data;
}

export async function createSingleNews(payload) {
  const dataToSend = JSON.stringify(payload);
  const { data: response } = await api.post(
    `/compass/welcomepage/news`,
    dataToSend
  );
  return response.data;
}

export async function getWelcomePageVisible() {
  const { data: response } = await api.get("/compass/welcomepage/visible");
  return response;
}

export async function updateWelcomePageVisible(value) {
  const { data: response } = await api.post(
    `/compass/welcomepage/visible=${value}`,
    ""
  );
  return response;
}
