import { useCallback, useState } from "react";
import { useParams } from "react-router";

// Services
import {
  removeTagFromExistingSubcategory,
  addTagToExistingSubcategory,
} from "service";

// Utils
import {
  useErrorHandler,
  useLinearProgressContext,
  useToastContext,
} from "contexts";

// Constants
import {
  ASSIGN_TAG,
  UNASSIGN_TAG,
  UNASSIGN_TAG_WARNING,
} from "constants/index";

export function useEditTags({
  subcategoryTags: allTags,
  subcategory: { subCategoryTags, name: subCategoryName },
}) {
  const { setError } = useErrorHandler();
  const { subcategory: subCategoryId } = useParams();
  const { progress, setProgress } = useLinearProgressContext();
  const { openToast } = useToastContext();

  const [selectedTags, setSelectedTags] = useState(subCategoryTags);

  const items = allTags.map(
    (tag) => subCategoryTags.find(({ name }) => name === tag.name) || tag
  );

  const handleAddTag = useCallback(
    async (item) => {
      const { id, name } = item;
      setProgress(true);

      try {
        const payload = { id, subCategoryId, name, visible: true };

        await addTagToExistingSubcategory(payload);

        openToast({
          message: "Changes were successfully saved",
          subtitle: `Tag assigned to Sub-Category ${subCategoryName}`,
          variant: "success",
        });

        setSelectedTags((tags) => [...tags, item]);

        setProgress(false);
      } catch (e) {
        setError((error) => ({
          ...error,
          status: e.response.status,
          type: ASSIGN_TAG,
        }));
      }
    },
    [setProgress, subCategoryId, openToast, subCategoryName, setError]
  );

  const handleRemoveTag = useCallback(
    async ({ id, name }) => {
      setProgress(true);

      try {
        const payload = { id, subCategoryId };

        await removeTagFromExistingSubcategory(payload);

        openToast({
          message: "Changes were successfully saved",
          subtitle: `Tag unassigned to Sub-Category ${subCategoryName}`,
          variant: "success",
        });

        setSelectedTags((tags) => tags.filter((tag) => tag.name !== name));

        setProgress(false);
      } catch (e) {
        if (e.response.status === 409) {
          return setError((error) => ({
            ...error,
            status: e.response.status,
            type: UNASSIGN_TAG_WARNING,
            props: { subCategoryName, id: subCategoryId },
          }));
        }
        setError((error) => ({
          ...error,
          status: e.response.status,
          type: UNASSIGN_TAG,
        }));
      }
    },
    [setProgress, subCategoryId, openToast, subCategoryName, setError]
  );

  return {
    progress,
    items,
    selectedTags,
    setSelectedTags,
    handleAddTag,
    handleRemoveTag,
  };
}
