// Utils
import { useMemo } from "react";
import styled from "styled-components";
import { format } from "date-fns";

// Components
import { CustomLink, Typography } from "components";
import { Box } from "@material-ui/core";
// Constants
import { KNOWLEDGE_MAP, EMPLOYEE_PROFILE } from "constants/index";

// Hooks
import { useExpandTableRow } from "hooks";

const iLevelBaseUrl = `${process.env.REACT_APP_ILEVEL_URL}`;

const TeamLink = styled(CustomLink)`
  margin-right: 15px;
  margin-bottom: 10px;
`;

const TeamTypography = styled(Typography)`
  margin-right: 15px;
  margin-bottom: 10px;
`;

const ExpandTypography = styled(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const ActiveWrap = styled.span`
  ${({ theme }) => `
    position: relative;
    padding-left: 4px;
    margin-left: 8px;

    &::before {
      position: absolute;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${theme.palette.greenLight};
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
    }
  `}
`;

export const tableHeadData = [
  { title: "Company Name", name: "name" },
  { title: "Acquisition Date", name: "acquisitionDate" },
  { title: "Exit Date", name: "exitDate" },
  { title: "Sector", name: "sector" },
  { title: "Geography", name: "geography" },
  { title: "Deal Team", name: "dealTeam" },
  { title: "Investment Support Team", name: "investmentSupportTeam" },
];

export function useRows(employeeCompanies) {
  const { expandedRow, onExpand, sliceExpandedRowItems } = useExpandTableRow();
  function emailIsValid(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const rows = useMemo(
    () =>
      employeeCompanies?.map((company) =>
        Object.keys(company).map((key) => {
          const commonCell = {
            name: key,
            width: "max-content",
            textColor: company.exitDate ? "darkGray" : "black",
          };

          switch (key) {
            case "dealTeam":
            case "investmentSupportTeam":
              const team = sliceExpandedRowItems({
                row: company.name.companyName,
                rowItems: company[key],
              });

              return {
                ...commonCell,
                component: (
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="start"
                    alignItems="flex-start"
                  >
                    <Box display="flex" alignItems="center" flexWrap="wrap">
                      {team.map(({ name, email }) => {
                        return emailIsValid(email) ? (
                          <TeamLink
                            newTab
                            to={`/${KNOWLEDGE_MAP}/${EMPLOYEE_PROFILE}/${email}`}
                          >
                            <Typography variant="h6" color="blueAccent">
                              {name}
                            </Typography>
                          </TeamLink>
                        ) : (
                          <TeamTypography variant="h6" color="darkGray">
                            {name}
                          </TeamTypography>
                        );
                      })}
                    </Box>
                    <Box display="flex" alignItems="center">
                      <Box pl={2}>
                        <Typography variant="h6" color="darkGray">
                          {company[key].length - 3 > 0 &&
                          expandedRow !== company.name.companyName
                            ? `(+${company[key].length - 3})`
                            : ""}
                        </Typography>
                      </Box>
                      {company[key].length - 3 > 0 && (
                        <Box pl={2} minWidth={120}>
                          <ExpandTypography
                            variant="body1"
                            color="blueAccent"
                            onClick={() => onExpand(company.name.companyName)}
                          >
                            {expandedRow === company.name.companyName
                              ? "Show less"
                              : "Show more"}
                          </ExpandTypography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ),
                width: "15%",
              };
            case "name":
              return {
                ...commonCell,
                component: (
                  <CustomLink
                    href={`${iLevelBaseUrl}/${company[key].id}`}
                    newTab
                  >
                    <Typography variant="h6" color="blueAccent">
                      {company[key].companyName}
                    </Typography>
                  </CustomLink>
                ),
                width: "4%",
              };
            case "acquisitionDate":
            case "exitDate":
              return {
                ...commonCell,
                component: company[key] ? (
                  <Typography color={company.exitDate ? "darkGray" : "black"}>
                    {format(new Date(company[key]), "MMM. dd, yyyy")}
                  </Typography>
                ) : (
                  <ActiveWrap>Active</ActiveWrap>
                ),
                width: "6%",
              };
            default:
              return { ...commonCell, text: company[key], width: "6%" };
          }
        })
      ),
    [employeeCompanies, expandedRow, onExpand, sliceExpandedRowItems]
  );

  return {
    tableHeadData,
    rows,
  };
}
