import { useState } from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import {
  IconButton,
  Fade,
  Popper as MUIPopper,
  ClickAwayListener,
  Paper as MUIPaper,
} from "@material-ui/core";
import { Icon } from "components/Icon";

// Constants
import { ARROW_UP } from "constants/index";

const ArrowUpIcon = styled(Icon)`
  width: 15px;
  height: 10px;
  position: absolute;
  top: -8px;
  right: 20px;
`;

const Popper = styled(MUIPopper)`
  z-index: 5;
  width: 340px;
`;

const Paper = styled(MUIPaper)`
  padding: 20px;
`;

export function PageHeaderPopper({
  iconComponent,
  children,
  onClick = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowEl, setArrowEl] = useState(null);
  const open = Boolean(anchorEl);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
    onClick();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton onClick={handleClick}>{iconComponent}</IconButton>
      <Popper
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        placement="bottom-end"
        transition
        modifiers={{
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowEl,
          },
        }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={1}>
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <ArrowUpIcon ref={setArrowEl} type={ARROW_UP} />
                  {children({ handleClose })}
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
}

PageHeaderPopper.propTypes = {
  iconComponent: PropTypes.object,
};

PageHeaderPopper.defaultProps = {
  iconComponent: null,
};
