import React from 'react';
import PropTypes from 'prop-types';

// Utils
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

// Components
import { Avatar } from '@material-ui/core';
import { CustomLink as Link } from 'components/CustomLink';

// Hooks
import { useScreens } from 'hooks';
import { Typography } from 'components';

const AvatarWithNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAvatar = styled(({ customImageSize, tabletScreens, ...rest }) => (
  <Avatar {...rest} />
))`
  width: 32px;
  height: 32px;
  margin-right: 8px;
  ${ifProp(
    'tabletScreens',
    css`
      width: ${({ customImageSize }) => customImageSize ?? '40px'};
      height: ${({ customImageSize }) => customImageSize ?? '40px'};
    `
  )}
`;

export const AvatarWithName = ({
	onTextClick,
  image,
  name,
  link,
  href,
  regularText,
	textVariant,
	textColor,
  newTab,
  customImageSize,
  className,
}) => {
  const { tabletScreens } = useScreens();

  return (
    <AvatarWithNameContainer className={className}>
      <StyledAvatar
        alt={name}
        src={image ?? '/images/placeholders/avatar-placeholder.png'}
        tabletScreens={tabletScreens}
        customImageSize={customImageSize}
      />
      {regularText ? (
        <Typography variant={textVariant} color={textColor} onClick={() => onTextClick ? onTextClick() : null}>{name}</Typography>
      ) : (
        <Link variant="h6" to={link} href={href} newTab={newTab}>
          {name}
        </Link>
      )}
    </AvatarWithNameContainer>
  );
};

AvatarWithName.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string,
  link: PropTypes.string,
  regularText: PropTypes.bool,
	textVariant: PropTypes.string,
	textColor: PropTypes.string,
  href: PropTypes.string,
  newTab: PropTypes.bool,
  customImageSize: PropTypes.string,
};
