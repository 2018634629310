import React from "react";

// Utils
import styled from "styled-components";

// Components
import { IconButton as MUIIconButton } from "@material-ui/core";
import { Icon, Typography } from "components";

// Constants
import { BACK_ARROW } from "constants/icons";

// Hooks
import { useHistory } from "react-router-dom";

const IconButton = styled(MUIIconButton)`
  padding: 0;
  border-radius: 4px;
  &:hover {
    background: transparent;
  }
`;

export const BackButton = () => {
  const history = useHistory();

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <IconButton onClick={handleBackClick}>
      <Icon type={BACK_ARROW} width="30px" />
      <Typography variant="h3" color="blueMain">
        Back
      </Typography>
    </IconButton>
  );
};
