import React from "react";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { Typography } from "components";

// Hooks
import { useScreens } from "hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding-bottom: 12px;
  &:not(:last-of-type) {
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(161, 171, 187, 0.4);
    margin-bottom: 24px;
  }

  ${ifProp(
    "tabletScreens",
    css`
      &:not(:last-of-type) {
        padding-bottom: ${({ noPaddingOnTablet, isSmallGroupPadding }) => {
          if (isSmallGroupPadding) {
            return noPaddingOnTablet ? 0 : "16px";
          }
          return noPaddingOnTablet ? 0 : "48px";
        }}
    `
  )}
`;

const Label = styled(({ isClickable, ...props }) => <Typography {...props} />)`
  margin-bottom: 8px;

  ${ifProp(
    "isClickable",
    css`
      cursor: pointer;
    `
  )}
`;

const ExpertiseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 37px;
  row-gap: 8px;

  ${ifProp(
    "tabletScreens",
    css`
      grid-template-columns: repeat(3, 1fr);
    `
  )}
  ${ifProp(
    "laptopScreens",
    css`
      grid-template-columns: repeat(6, 1fr);
    `
  )}
`;

const ExpertiseItem = styled(Typography)`
  cursor: pointer;
`;

export const ExpertiseGroup = ({
  expertiseGroup,
  handleSingleSelected,
  onCategoryNameClick,
  isCategoryNameClickable,
  isSmallGroupPadding,
}) => {
  const { tabletScreens, laptopScreens } = useScreens();

  const onClick = (expertise) => {
    handleSingleSelected(expertise);
  };

  return (
    <Container
      tabletScreens={tabletScreens}
      noPaddingOnTablet={expertiseGroup?.CategoryName === "View All"}
      isSmallGroupPadding={isSmallGroupPadding}
    >
      <Label
        variant="h6"
        color={isCategoryNameClickable ? "blueAccent" : "black"}
        isClickable={isCategoryNameClickable}
        onClick={() => {
          isCategoryNameClickable &&
            onCategoryNameClick(expertiseGroup.CategoryName);
        }}
      >
        {expertiseGroup.CategoryName}
      </Label>
      {expertiseGroup?.ExpertiseTypeItems && (
        <ExpertiseContainer
          tabletScreens={tabletScreens}
          laptopScreens={laptopScreens}
        >
          {expertiseGroup?.ExpertiseTypeItems?.map((expertise) => (
            <ExpertiseItem
              key={expertise?.ExpertiseId}
              variant="body2"
              color={expertise.isSelected ? "darkGray" : "black"}
              onClick={() => onClick(expertise)}
            >
              {expertise?.ExpertiseName}
            </ExpertiseItem>
          ))}
        </ExpertiseContainer>
      )}
    </Container>
  );
};
