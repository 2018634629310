export const surveySort = (surveysList, sort) => {
  switch (sort) {
    case "topic_asc":
      return surveysList.sort((a, b) =>
        a.topic?.toLowerCase() > b.topic?.toLowerCase()
          ? 1
          : b.topic?.toLowerCase() > a.topic?.toLowerCase()
          ? -1
          : 0
      );
    case "topic_desc":
      return surveysList.sort((a, b) =>
        a.topic?.toLowerCase() < b.topic?.toLowerCase()
          ? 1
          : b.topic?.toLowerCase() < a.topic?.toLowerCase()
          ? -1
          : 0
      );
    case "status_asc":
      return surveysList.sort((a, b) =>
        a.isDraft === b.isDraft ? 0 : a.isDraft ? -1 : 1
      );
    case "status_desc":
      return surveysList.sort((a, b) =>
        a.isDraft === b.isDraft ? 0 : a.isDraft ? 1 : -1
      );
    case "created_by_asc":
      return surveysList.sort((a, b) =>
        a.user?.fullName?.toLowerCase() > b.user?.fullName?.toLowerCase()
          ? 1
          : b.user?.fullName?.toLowerCase() > a.user?.fullName?.toLowerCase()
          ? -1
          : 0
      );
    case "created_by_desc":
      return surveysList.sort((a, b) =>
        a.user?.fullName?.toLowerCase() < b.user?.fullName?.toLowerCase()
          ? 1
          : b.user?.fullName?.toLowerCase() < a.user?.fullName?.toLowerCase()
          ? -1
          : 0
      );
    case "created_date_asc":
      return surveysList.sort(
        (a, b) => new Date(a.submitterDateTime) - new Date(b.submitterDateTime)
      );
    case "created_date_desc":
      return surveysList.sort(
        (a, b) => new Date(b.submitterDateTime) - new Date(a.submitterDateTime)
      );
    case "launch_date_asc":
      return surveysList.sort(
        (a, b) => new Date(a.targetLaunchDate) - new Date(b.targetLaunchDate)
      );
    case "launch_date_desc":
      return surveysList.sort(
        (a, b) => new Date(b.targetLaunchDate) - new Date(a.targetLaunchDate)
      );
    case "team_members_asc":
      return surveysList.sort((a, b) =>
        a.teamMembers?.toLowerCase() > b.teamMembers?.toLowerCase()
          ? 1
          : b.teamMembers?.toLowerCase() > a.teamMembers?.toLowerCase()
          ? -1
          : 0
      );
    case "team_members_desc":
      return surveysList.sort((a, b) =>
        a.teamMembers?.toLowerCase() < b.teamMembers?.toLowerCase()
          ? 1
          : b.teamMembers?.toLowerCase() < a.teamMembers?.toLowerCase()
          ? -1
          : 0
      );
    case "sector_asc":
      return surveysList.sort((a, b) =>
        a.sector?.[0]?.toLowerCase() > b.sector?.[0]?.toLowerCase()
          ? 1
          : b.sector?.[0]?.toLowerCase() > a.sector?.[0]?.toLowerCase()
          ? -1
          : 0
      );
    case "sector_desc":
      return surveysList.sort((a, b) =>
        a.sector?.[0]?.toLowerCase() < b.sector?.[0]?.toLowerCase()
          ? 1
          : b.sector?.[0]?.toLowerCase() < a.sector?.[0]?.toLowerCase()
          ? -1
          : 0
      );
    case "deal_entity_asc":
      return surveysList.sort((a, b) =>
        a.dealEntityName?.[0]?.toLowerCase() > b.dealEntityName?.[0]?.toLowerCase()
          ? 1
          : b.dealEntityName?.[0]?.toLowerCase() > a.dealEntityName?.[0]?.toLowerCase()
          ? -1
          : 0
      );
    case "deal_entity_desc":
      return surveysList.sort((a, b) =>
        a.dealEntityName?.[0]?.toLowerCase() < b.dealEntityName?.[0]?.toLowerCase()
          ? 1
          : b.dealEntityName?.[0]?.toLowerCase() < a.dealEntityName?.[0]?.toLowerCase()
          ? -1
          : 0
      );
    case "business_type_asc":
      return surveysList.sort((a, b) =>
        a.businessType?.toLowerCase() > b.businessType?.toLowerCase() 
          ? 1
          : b.businessType?.toLowerCase()  > a.businessType?.toLowerCase() 
          ? -1
          : 0
      );
    case "business_type_desc":
      return surveysList.sort((a, b) =>
        a.businessType?.toLowerCase()  < b.businessType?.toLowerCase() 
          ? 1
          : b.businessType?.toLowerCase()  < a.businessType?.toLowerCase() 
          ? -1
          : 0
      );
    case "deal_type_asc":
      return surveysList.sort((a, b) =>
        a.dealType?.toLowerCase()  > b.dealType?.toLowerCase()  ? 1 : b.dealType?.toLowerCase()  > a.dealType?.toLowerCase()  ? -1 : 0
      );
    case "deal_type_desc":
      return surveysList.sort((a, b) =>
        a.dealType?.toLowerCase()  < b.dealType?.toLowerCase()  ? 1 : b.dealType?.toLowerCase()  < a.dealType?.toLowerCase()  ? -1 : 0
      );
    default:
      return surveysList.sort(
        (a, b) => new Date(b.targetLaunchDate) - new Date(a.targetLaunchDate)
      );
  }
};
