// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import {
  getWelcomePage as fetchWelcomePage,
  updateSingleNews as editSingleNews,
  deleteSingleNews as removeSingleNews,
  createSingleNews as addSingleNews,
} from "service";

// Actions
import {
  GET_NEWS,
  CREATE_SINGLE_NEWS,
  UPDATE_SINGLE_NEWS,
  DELETE_SINGLE_NEWS,
} from "constants/index";

const initialState = {
  news: null,
  error: null,
  loading: true,
};

export const getNews = createAsyncThunk(
  GET_NEWS,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchWelcomePage();
    } catch (e) {
			console.log(e);
      return rejectWithValue(e);
    }
  }
);

export const createSingleNews = createAsyncThunk(
  CREATE_SINGLE_NEWS,
  async (newsData, { rejectWithValue }) => {
    try {
      await addSingleNews(newsData.payload);
      return await fetchWelcomePage();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const updateSingleNews = createAsyncThunk(
  UPDATE_SINGLE_NEWS,
  async (newsData, { rejectWithValue }) => {
    try {
      await editSingleNews(newsData.id, newsData.payload);
      return await fetchWelcomePage();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteSingleNews = createAsyncThunk(
  DELETE_SINGLE_NEWS,
  async (newsData, { rejectWithValue }) => {
    try {
      return await removeSingleNews(newsData.id);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const newsReducer = createReducer(initialState, (builder) =>
  builder
		.addCase(getNews.pending, (state) => {
			state.loading = true;
		})
    .addCase(getNews.fulfilled, (state, action) => {
      state.news = action.payload.news;
      state.loading = false;
    })
    .addCase(getNews.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(createSingleNews.fulfilled, (state, action) => {
      state.news = action.payload.news;
    })
    .addCase(deleteSingleNews.fulfilled, (state, action) => {
      const singleNewsIndex = state.news.findIndex(
        ({ id }) => id === action.payload.id
      );
      state.news.splice(singleNewsIndex, 1);
    })
    .addCase(updateSingleNews.fulfilled, (state, action) => {
      state.news = action.payload.news;
    })
);
