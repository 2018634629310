import styled from 'styled-components';
import { switchProp } from 'styled-tools';

export const Fader = styled.div`
width: 80px;
height: ${props => props.faderHeight}px;
position: absolute;
${switchProp("direction", {
	left: 'left: 0;', 
	right: 'right: 0;'
})}
top: 0;
background: linear-gradient(
	${switchProp("direction", {left: '270', right: '90'})}deg,
	rgba(256,256,256,0), 
	rgba(256,256,256,1)
);
`;