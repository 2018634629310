import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';


// Components
import { Typography } from "components";

// Constants
import { SUBCATEGORIES } from 'constants/index';

const CardWrapper = styled.div`
  padding: 10px 15px 20px;
`;

const StyledSubcategoryCard = styled.div`
	cursor: pointer;
  padding: 22px 0 22px 20px;
  width: 210px;
	height: 92px;
  box-sizing: border-box;
  background: #ffffff;
	${ifProp('isSelected', css`background: ${props => props.theme.palette.blueAccent};`)}
  box-shadow: 0px 4px 20px rgba(211, 214, 216, 0.6);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
	align-items: center;
`;



const Image = styled.img`
  width: 44px;
	margin-right: 8px;
`;

export function SubcategoryCard({ subcategory, isSelected, showDocumentsOnAction }) {
	const history = useHistory();
	
	const onClick = (e) => {
		history.push(`/${SUBCATEGORIES}/${subcategory.id}`);
	}

	if (!subcategory.visible) return null;

  return (
		<CardWrapper>
			<StyledSubcategoryCard onClick={onClick} isSelected={isSelected}>
				<Info>
					<Image
						src={subcategory.iconUrl}
						alt="subcategory icon"
					/>
					<Typography variant="h3" color={isSelected ? "white" : "blueMain"}>
						{subcategory.name}
					</Typography>
				</Info>
			</StyledSubcategoryCard>
		</CardWrapper>
  );
}
