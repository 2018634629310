// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;

const api = apiConfig(baseURL);

export async function getSurveyLookups() {
  const { data } = await api.get("/compass/Lookups");

  return data;
}
