// Utils
import styled, { css } from "styled-components";
import { useState } from "react";
import { useParams } from "react-router";
import { ifProp } from "styled-tools";

// Hooks
import { useMinWidth } from "hooks";

// Components
import { CustomIcon } from "components/Icon/Icon";
import { IconButton } from "@material-ui/core";
import { Autocomplete } from "components/Autocomplete";

// Service
import { getAutocompleteResult } from "service";

const StyledIcon = styled(CustomIcon)`
  ${({ theme }) => `
    svg {
      fill: ${theme.palette.white};
    }
  `}
`;

const AutocompleteWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  max-width: none;
  width: auto;
  top: 0;
  bottom: 0;
  padding: 12px 15px;

  ${ifProp(
    "tabletScreens",
    css`
      position: static;
      max-width: 340px;
      width: 100%;
      margin-left: auto;
      margin-right: 12px;
      padding: 0;
    `
  )}
`;

function MobileAutocomplete({ value }) {
  const [show, setShow] = useState(false);

  return (
    <>
      <IconButton onClick={() => setShow(true)}>
        <StyledIcon type="search-mui" />
      </IconButton>
      {show && (
        <AutocompleteWrapper>
          <Autocomplete
            variant="header"
            setShow={setShow}
            value={value}
            async
            asyncCallback={getAutocompleteResult}
          />
        </AutocompleteWrapper>
      )}
    </>
  );
}

function TabletAutocomplete({ value }) {
  return (
    <AutocompleteWrapper tabletScreens>
      <Autocomplete
        variant="header"
        value={value}
        async
        asyncCallback={getAutocompleteResult}
      />
    </AutocompleteWrapper>
  );
}

// TODO: move to lazy at some point
export function PageHeaderSearch() {
  const params = useParams();
  const tabletScreens = useMinWidth("tablet");

  if (tabletScreens) {
    return <TabletAutocomplete value={params.query || params.documentsQuery} />;
  }

  return <MobileAutocomplete value={params.query || params.documentsQuery} />;
}
