import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Actions
import { setAuthentication } from "redux/reducers/authenticationReducer";

// Services
import { refreshToken as refreshTokenService } from "service";

// Utils
import { getValue, setValue } from "utils";

export function useRefreshToken() {
  const dispatch = useDispatch();
  const refreshToken = getValue("refresh_token");
  const accessToken = getValue("access_token");

  if (accessToken) {
    dispatch(setAuthentication(true));
  }

  useEffect(() => {
    (async () => {
      try {
        if (refreshToken) {
          const {
            access_token,
            expires_in,
            refresh_token,
            authentication: { username },
          } = (
            await refreshTokenService(refreshToken)) || {};

          setValue("access_token", access_token);
          setValue("expires_in", expires_in);
          setValue("refresh_token", refresh_token);
          return setValue("username", username);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [dispatch, refreshToken]);
}
