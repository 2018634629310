// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;

const env = `${process.env.REACT_APP_ENV}`;

const api = apiConfig(baseURL);

export async function getSurveys() {
  const { data } = await api.get(`/compass/GetSurveys?env=${env}`);

  return data;
}

export async function getDraftSurvey(id) {
  const { data } = await api.get(`/compass/GetDraftSurveyById?surveyId=${id}`);

  return {
    ...data,
    categories: data?.categories?.sort(function (a, b) {
      return a.sortOrder - b.sortOrder;
    }) ?? null,
  };
}

export async function createSurvey(payload) {
  const dataToSend = JSON.stringify(payload);
  const response = await api.post("/compass/CreateSurvey", dataToSend);
  return response.data;
}

export async function deleteSurvey(id) {
  const { data } = await api.delete(`/compass/DeleteSurveyById?surveyId=${id}`);

  return data;
}
