// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import {
  createCategory as addCategory,
  getCategories as fetchCategories,
  updateCategory,
  deleteCategory as removeCategory,
  sortCategory as updateOrder,
} from "service";

// Actions
import {
  GET_CATEGORIES,
  EDIT_CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  SORT_CATEGORY,
} from "constants/index";

const initialState = {
  categories: null,
  error: null,
  loading: true,
};

export const getCategories = createAsyncThunk(
  GET_CATEGORIES,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const editCategory = createAsyncThunk(
  EDIT_CATEGORY,
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      await updateCategory(payload, id);
      return await fetchCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  CREATE_CATEGORY,
  async (payload, { rejectWithValue }) => {
    try {
      await addCategory(payload);
      return await fetchCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteCategory = createAsyncThunk(
  DELETE_CATEGORY,
  async ({ id }, { rejectWithValue }) => {
    try {
      await removeCategory(id);
      return await fetchCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const sortCategory = createAsyncThunk(
  SORT_CATEGORY,
  async ({ id, sortOrder }, { rejectWithValue }) => {
    try {
      await updateOrder(id, sortOrder);
      return await fetchCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const categoriesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.loading = false;
    })
    .addCase(getCategories.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(editCategory.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.loading = false;
    })
    .addCase(editCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(createCategory.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.loading = false;
    })
    .addCase(createCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(deleteCategory.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.loading = false;
    })
    .addCase(deleteCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(sortCategory.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
    })
    .addCase(sortCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
