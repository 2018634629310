// Components
import { Button as MuiButton, Typography } from '@material-ui/core';

// Utils
import styled from 'styled-components';
import { ifProp } from 'styled-tools';
import PropTypes from 'prop-types';

const getStyles = (theme, variant, isDisabled) => {
  if (isDisabled) {
    return `
        color: ${theme.palette.darkGray};
        background-color: ${theme.palette.graySuperLight2};
        cursor: auto;
        `;
  }
  switch (variant) {
    case 'primary':
      return `
      color: ${theme.palette.white};
      background-color: ${theme.palette.blueAccent};
      &:hover {
        background-color: ${theme.palette.blueMain};
      }
      `;
    case 'secondary':
      return `
      color: ${theme.palette.blueAccent};
      background-color: ${theme.palette.white};
      border: 1px solid ${theme.palette.blueAccent};
      &:hover {
        background-color: ${theme.palette.blueMain};
        color: ${theme.palette.white};
        border: none;
      }
      `;
    case 'tertiary':
      return `
			color: ${theme.palette.darkGray};
			background-color: ${theme.palette.white};
			border: 1px solid rgba(161, 171, 187, 0.5);
			min-width: 85px;
			height: 36px;
			&:hover {
				background-color: ${theme.palette.white};
			}
			`;
    case 'quaternary':
      return `
			color: ${theme.palette.blueAccent};
			background-color: ${theme.palette.white};
			border: 1px solid ${theme.palette.blueAccent};
			min-width: 85px;
			height: 36px;
			&:hover {
				background-color: ${theme.palette.white};
			}
			`;
    default:
      return `
        color: ${theme.palette.white};
        background-color: ${theme.palette.blueAccent}
        `;
  }
};

export const StyledButton = styled(
  ({ isDisabled, variant, isSmall, width, ...rest }) => <MuiButton {...rest} />
)`
  border-radius: 4px;
  border: none;
  font-weight: 500;
  width: ${({ width }) => width};
  min-width: 135px;
  cursor: pointer;
  text-transform: unset;
  height: ${ifProp('isSmall', '40px', '48px')};
  ${({ theme, variant, isDisabled }) => getStyles(theme, variant, isDisabled)};
`;

export const Button = ({
  variant,
  text,
  onClick = () => {},
  isDisabled = false,
  isSmall = false,
  width,
}) => {
  return (
    <StyledButton
      variant={variant}
      onClick={isDisabled ? () => {} : onClick}
      isDisabled={isDisabled}
      isSmall={isSmall}
      width={width}
    >
      <Typography variant={isSmall ? 'h6' : 'h3'}>{text}</Typography>
    </StyledButton>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  width: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  isDisabled: false,
  isSmall: false,
  width: '100%',
};
