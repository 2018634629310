import { useMinWidth } from "./useMinWidth";

export const useScreens = () => {
  const smallMobileScreens = useMinWidth("smallMobile");
  const smallTabletScreens = useMinWidth("smallTablet");
  const tabletScreens = useMinWidth("tablet");
  const largeTabletScreens = useMinWidth("largeTablet");
  const laptopScreens = useMinWidth("laptop");
  const smallDesktopScreens = useMinWidth("smallDesktop");
  const desktopScreens = useMinWidth("desktop");
  const largeDesktopScreens = useMinWidth("largeDesktop");

  return {
    smallMobileScreens,
    smallTabletScreens,
    tabletScreens,
    largeTabletScreens,
    laptopScreens,
    smallDesktopScreens,
    desktopScreens,
    largeDesktopScreens,
  };
};
