import HeeboLight from "assets/fonts/Heebo-Light.ttf";
import HeeboRegular from "assets/fonts/Heebo-Regular.ttf";
import HeeboMedium from "assets/fonts/Heebo-Medium.ttf";
import HeeboBold from "assets/fonts/Heebo-Bold.ttf";

const createFontConfig = ({ ...config }) => ({
  fontFamily: "Heebo",
  fontDisplay: "swap",
  src: `
    local('Heebo'),
    local('Heebo-${config.fontStyle
      .charAt(0)
      .toUpperCase()}${config.fontStyle.slice(1)}'),
    url(${config.font}) format('truetype')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
  ...config
});

export const heeboLight = createFontConfig({
  fontStyle: "light",
  font: HeeboLight,
  fontWeight: 300
});

export const heeboRegular = createFontConfig({
  fontStyle: "regular",
  font: HeeboRegular,
  fontWeight: 400
});

export const heeboMedium = createFontConfig({
  fontStyle: "medium",
  font: HeeboMedium,
  fontWeight: 500
});

export const heeboBold = createFontConfig({
  fontStyle: "bold",
  font: HeeboBold,
  fontWeight: 700
});
