// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const ocpKey = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;

const api = apiConfig(baseURL, ocpKey);

export async function getKeyHighlights(subcategoryTagId, pageSize, pageNumber) {
  const { data: response } = await api.get(
    `/compass/subcategorytags/${subcategoryTagId}/files?pageSize=${pageSize}&pageNumber=${
      pageNumber + 1
    }`
  );
  return response;
}

export async function updateKeyHighlights(payload, id) {
  const dataToSend = JSON.stringify(payload);
  const { data } = await api.patch(
    `/compass/recommendedreads/${id}`,
    dataToSend
  );

  return data;
}

export async function searchKeyHighlights({
  query,
  size,
  categoryFilter = null,
  subCategoryFilter = null,
  subCategoryTagFilter = null,
  tagsFilter = null,
  lastModifiedFilter = null,
  start = 0,
}) {
  let decQuery = `${query}`;
  let encQuery = decQuery.replace('&','%26');
  let queryString = `start=${start}`;

  if (query) {
    queryString = queryString + `&query=${encQuery}`;
  }

  if (size) {
    queryString = queryString + `&size=${size}`;
  }

  if (categoryFilter) {
    queryString = queryString + `&category=${categoryFilter}`;
  }

  if (subCategoryFilter?.length) {
    const subcategories = subCategoryFilter.map((s) => `"${s.name}"`).join(",");
    queryString = queryString + `&subCategoryFilter=${subcategories}`;
  }

  if (subCategoryTagFilter?.length &&  subCategoryTagFilter[0].id !=="all") {
    const subcategoryTags = subCategoryTagFilter
      .map((s) => `"${s.name}"`)
      .join(",");
    queryString = queryString + `&subCategoryTagFilter=${subcategoryTags}`;
  }

  if (tagsFilter?.length) {
    const tags = tagsFilter.map((t) => `"${t.name}"`).join(",");
    queryString = queryString + `&tagsFilter=${tags}`;
  }

  if (lastModifiedFilter) {
    queryString = queryString + `&lastModifiedFilter=${lastModifiedFilter}`;
  }

  const { data: response } = await api.get(
    `/compass/elasticresultsqueryrecommended?${queryString}`
  );

  return response;
}

export async function searchResultsPage({
  query,
  size,
  categoryFilter = null,
  subCategoryFilter = null,
  subCategoryTagFilter = null,
  tagsFilter = null,
  lastModifiedFilter = null,
  start = 0,
}) {
  let decQuery = `${query}`;
  let encQuery = decQuery.replace('&','%26');
  let queryString = `start=${start}`;

  if (query) {
    queryString = queryString + `&query=${encQuery}`;
  }

  if (size) {
    queryString = queryString + `&size=${size}`;
  }

  if (categoryFilter) {
    queryString = queryString + `&category=${categoryFilter}`;
  }

  if (subCategoryFilter?.length) {
    const subcategories = subCategoryFilter.map((s) => `"${s.name}"`).join(",");
    queryString = queryString + `&subCategoryFilter=${subcategories}`;
  }

  if (subCategoryTagFilter?.length) {
    const subcategoryTags = subCategoryTagFilter
      .map((s) => `"${s.name}"`)
      .join(",");
    queryString = queryString + `&subCategoryTagFilter=${subcategoryTags}`;
  }

  if (tagsFilter?.length) {
    const tags = tagsFilter.map((t) => `"${t.name}"`).join(",");
    queryString = queryString + `&tagsFilter=${tags}`;
  }

  if (lastModifiedFilter) {
    queryString = queryString + `&lastModifiedFilter=${lastModifiedFilter}`;
  }

  const { data: response } = await api.get(
    `/compass/resultsquery?${queryString}`
  );

  return response;
}