import { useMemo, useCallback } from 'react';
// Utils
import { useDispatch, useSelector } from 'react-redux';

// Actions
import {
  editKeyHighlight,
  getKeyHighlights as getKeyHighlightsAction,
} from 'redux/reducers/keyHighlightsReducer';

export function useKeyHighlights() {
  const dispatch = useDispatch();

  const {
    keyHighlights,
    loading,
    error,
    totalCount,
    tagsAggregation,
    aggregatedDocCount,
  } = useSelector(({ keyHighlights }) => keyHighlights);

  const getKeyHighlights = useCallback(
    ({ size, subCategoryFilter, subCategoryTagFilter, start, query }) => {
      dispatch(
        getKeyHighlightsAction({
          size,
          subCategoryFilter,
          subCategoryTagFilter,
          start,
          query,
        })
      );
    },
    [dispatch]
  );

  const updateKeyHighlight = useCallback(
    ({
      payload,
      id,
      size,
      subCategoryFilter,
      subCategoryTagFilter,
      start,
      query,
    }) => {
      dispatch(
        editKeyHighlight({
          payload,
          id,
          size,
          subCategoryFilter,
          subCategoryTagFilter,
          start,
          query,
        })
      );
    },
    [dispatch]
  );

  const memoState = useMemo(
    () => ({
      keyHighlights,
      loading,
      error,
      getKeyHighlights,
      updateKeyHighlight,
      totalCount,
      tagsAggregation,
      aggregatedDocCount,
    }),
    [
      error,
      loading,
      keyHighlights,
      getKeyHighlights,
      updateKeyHighlight,
      totalCount,
      tagsAggregation,
      aggregatedDocCount,
    ]
  );

  return { ...memoState };
}
