export const SUBCATEGORY_STEPPER_LABELS = [
  "general sub-category information",
  "key information links",
  "sub-category tags",
];

export const SURVEYS_STEPPER_LABELS = ["DETAILS", "DESIGN", "REVIEW"];

export const MANAGE_CATEGORIES_LABEL = "Manage Categories";
export const MANAGE_QUESTIONS_LABEL = "Manage Questions";
