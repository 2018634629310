import React from "react";
import PropTypes from "prop-types";

// Components
import { SelectWithCheckboxes } from "components";

export const CheckboxSelect = ({
  checkboxItems,
  label,
  setCheckedItems,
  selectedItems,
}) => {
  const onChange = (e) => {
    setCheckedItems(
      checkboxItems.filter((item) => e.target.value.includes(item.name))
    );
  };

  return (
    <SelectWithCheckboxes
      onChange={onChange}
      itemsList={checkboxItems}
      selectedItems={selectedItems.map((item) => item.name)}
      label={label}
    />
  );
};

CheckboxSelect.propTypes = {
  checkboxItems: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  setCheckedItems: PropTypes.func,
};
