import { useEffect, useState } from 'react';
import styled from 'styled-components';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import { Typography } from 'components';

const Wrapper = styled.div`
  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
  .MuiAccordion-root:before {
    height: 0;
  }
  .MuiAccordion-root {
    border-bottom: 1px solid rgba(161, 171, 187, 0.4);
  }
  .MuiAccordionSummary-content {
    padding: 16px 0;
    margin: 0;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
  .MuiAccordion-rounded:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .MuiAccordion-rounded:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .MuiAccordion-root.Mui-disabled {
    background-color: #fff;
  }
`;

export const Accordion = ({
  title,
  children,
  disabled = false,
  iconColor = 'black',
  expanded = false,
}) => {
	const [isExpanded, setIsExpanded] = useState(false)

	useEffect(() => {
		setIsExpanded(expanded)
	}, [expanded])

	const onAccordionToogle = () => {
		setIsExpanded(prevState => !prevState)
	}

  return (
    <Wrapper>
      <MuiAccordion disabled={disabled} expanded={isExpanded}>
        <AccordionSummary
					onClick = {onAccordionToogle}
          expandIcon={<ExpandMoreIcon style={{ fill: iconColor }} />}
        >
          <Typography variant="h6" color="blueMain">
            {title}
          </Typography>
        </AccordionSummary>
        {children}
      </MuiAccordion>
    </Wrapper>
  );
};
