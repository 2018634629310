import { createAsyncThunk, createReducer } from '@reduxjs/toolkit';
import {
  GET_SURVEY_QUESTIONS,
  CREATE_SURVEY_QUESTION,
  EDIT_SURVEY_QUESTION,
  DELETE_SURVEY_QUESTION,
} from 'constants/actions';

import {
  getSurveyQuestions as fetchSurveyQuestions,
  createSurveyQuestion as addSurveyQuestion,
  updateSurveyQuestion,
  deleteSurveyQuestion as removeSurveyQuestion,
} from 'service';

const initialState = {
  surveyQuestions: null,
  error: null,
  loading: true,
};

export const getSurveyQuestions = createAsyncThunk(
  GET_SURVEY_QUESTIONS,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchSurveyQuestions();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const createSurveyQuestion = createAsyncThunk(
  CREATE_SURVEY_QUESTION,
  async (payload, { rejectWithValue }) => {
    try {
      await addSurveyQuestion(payload);
      return await fetchSurveyQuestions();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const editSurveyQuestion = createAsyncThunk(
  EDIT_SURVEY_QUESTION,
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      await updateSurveyQuestion(payload, id);
      return await fetchSurveyQuestions();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteSurveyQuestion = createAsyncThunk(
  DELETE_SURVEY_QUESTION,
  async (id, { rejectWithValue }) => {
    try {
      await removeSurveyQuestion(id);
      return await fetchSurveyQuestions();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const surveyQuestionsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getSurveyQuestions.fulfilled, (state, action) => {
      state.surveyQuestions = action.payload;
      state.loading = false;
    })
    .addCase(getSurveyQuestions.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(createSurveyQuestion.fulfilled, (state, action) => {
      state.surveyQuestions = action.payload;
      state.loading = false;
    })
    .addCase(createSurveyQuestion.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(editSurveyQuestion.fulfilled, (state, action) => {
      state.surveyQuestions = action.payload;
      state.loading = false;
    })
    .addCase(editSurveyQuestion.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(deleteSurveyQuestion.fulfilled, (state, action) => {
      state.surveyQuestions = action.payload;
      state.loading = false;
    })
    .addCase(deleteSurveyQuestion.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
