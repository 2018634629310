import { Link } from "react-router-dom";

// Components
import { Link as MuiLink } from "@material-ui/core";

// Utils
import styled from "styled-components";

const StayledExternalLink = styled.a`
  ${({ theme }) => `
		color: ${theme.palette.blueAccent};
		cursor: pointer;
		text-decoration: none;
		font-family: Heebo;
		font-style: normal;
		display: inline-block;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		&:visited {
			color: ${theme.palette.blueAccent};
		}
		&:hover {
				text-decoration: ${"underline"};
		};
	`}
`;

const ExternalLink = ({ href, children, newTab }) => {
  return (
    <StayledExternalLink href={href} target={newTab ? "_blank" : "self"}>
      {children}
    </StayledExternalLink>
  );
};

export const CustomLink = styled(
  ({ href, isDisabled, children, newTab, ...rest }) => {
    if (href)
      return (
        <ExternalLink href={href} newTab={newTab} >
          {children}
        </ExternalLink>
      );
    return (
      <MuiLink component={Link} target={newTab ? "_blank" : ""} {...rest}>
        {children}
      </MuiLink>
    );
  }
)`
  ${({ theme, isDisabled }) => `
		color: ${isDisabled ? theme.palette.grayMiddle : theme.palette.blueAccent};
		cursor: pointer;
		text-decoration: none;
		&:hover {
				text-decoration: ${isDisabled ? "none" : "underline"};
		};
	`}
`;
