import styled, { css } from "styled-components";
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";

// Hooks
import { useScreens } from "hooks";

const Input = styled(({ tabletScreens, tabletVariant, ...rest }) => (
  <OutlinedInput {...rest} />
))`
  ${({ theme, tabletScreens, tabletVariant }) => `
		.MuiOutlinedInput-input {
			padding: 8px 0;
		}
		&.MuiOutlinedInput-root {
			width: 100%;
			max-width: 340px;
		}
		&.MuiOutlinedInput-adornedEnd {
			padding-right: 10px;
		}
		&.MuiOutlinedInput-adornedStart {
			padding-left: 0;
		}
		&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
			border-width: 1px;
		}
		&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
			border-width: 1px;
		}
		&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
			border-color: rgba(161, 171, 187, 0.5);
			border-width: 1px;
		}
		&&& .MuiInputBase-input {
			color: ${theme.palette.black};
			font-size: 14px;
			font-weight: ${theme.typography.body1.fontWeight};
			line-height: ${theme.typography.body1.lineHeight};

			&::placeholder {
				color: ${theme.palette.darkGray};
				opacity: 1 !important;
				font-size: 14px;
				font-weight: ${theme.typography.body1.fontWeight};
				line-height: ${theme.typography.body1.lineHeight};
			}
		}
		${
      Boolean(tabletScreens && tabletVariant) &&
      css`
        &.MuiOutlinedInput-root {
          background-color: white;
        }
        &.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      `
    }
	`}
`;

export const SearchInput = ({
  value,
  setValue,
  onSubmit,
  placeholder,
  clearButton,
  className,
  onClear: onClearHandler,
  tabletVariant,
}) => {
  const { tabletScreens } = useScreens();
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onSearch = () => {
    onSubmit();
  };
  const onKeypress = (e) => {
    if (e.charCode === 13 && value) onSubmit();
  };

  const onClear = () => {
    setValue("");
    if (onClearHandler) onClearHandler();
  };

  return (
    <Input
      tabletScreens={tabletScreens}
      tabletVariant={tabletVariant}
      type="text"
      value={value}
      onKeyPress={onKeypress}
      onChange={onChange}
      placeholder={placeholder}
      startAdornment={
        <InputAdornment position="start">
          <IconButton onClick={onSearch} edge="end">
            <Search fontSize="small" />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        clearButton && (
          <InputAdornment position="end">
            <IconButton onClick={onClear} edge="end">
              <Close fontSize="small" />
            </IconButton>
          </InputAdornment>
        )
      }
      className={className}
    />
  );
};
