import { useEffect } from "react";

// Utils
import { useLinearProgressContext, useToastContext } from "contexts";
import { errors } from "../utils";

export function useHandle500(status, type) {
  const { openToast } = useToastContext();
  const { setProgress } = useLinearProgressContext();

  useEffect(() => {
    if (status === 500) {
      openToast(errors.toast[type]);
      setProgress(false);
    }
  }, [openToast, setProgress, status, type]);
}
