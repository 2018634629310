import React from "react";
import PropTypes from "prop-types";

// Components
import { MenuItem } from "@material-ui/core";
import { Select } from "components";
import { SelectFilterWrapper } from "../SelectFilterWrapper";

export const SelectFilter = ({ selected, onSelect, options, label }) => {
  const handleChange = (value) => {
    onSelect(value)
  };

  return (
    <SelectFilterWrapper label={label} customWidth="300px">
      <Select
        value={selected}
      >
        {options.map((option) => {
          return (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleChange(option.value)}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </SelectFilterWrapper>
  );
};

SelectFilter.propTypes = {
  dateSelected: PropTypes.object,
  setDateSelected: PropTypes.func,
};
