import { useMemo, useState } from "react";

export const useTablePagination = ({
  initialRowsPerPage,
  rows,
  setNumOfResults,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setNumOfResults && setNumOfResults(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tableRowsDataPerPage = useMemo(() => {
    if (!rows) return;

    return rowsPerPage > 0
      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : rows;
  }, [rowsPerPage, rows, page]);

  return {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    tableRowsDataPerPage,
    setPage,
  };
};
