import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

// Hooks
import { useScreens } from 'hooks';
import {
  Tooltip,
  CustomLink,
  Typography,
  BackButton,
  Button,
} from 'components';

// Constants
import { SUBCATEGORIES } from 'constants/index';

const HeaderWrapper = styled.div`
	background-color: ${({ theme }) => theme.palette.graySuperLight2};
  display: flex;
  justify-content: space-between;
	flex-direction: column;
	${ifProp(
    'tabletScreens',
    css`
      background-color: ${({ theme }) => theme.palette.white};
			flex-direction: row;
			align-items: center;
			margin-bottom: 32px;
    `
  )}
`;

const TitleWrapper = styled.div`
  padding: 20px 15px;

  ${ifProp(
    'tabletScreens',
    css`
      display: flex;
      justify-content: flex-start;
      align-items: center;
      
      padding: 0;
      background-color: ${({ theme }) => theme.palette.white};
    `
  )}
`;

const BackButtonWrapper = styled.div`
  margin-left: -8px;
  margin-bottom: 20px;
`;

const ActionButtonWrapper = styled.div`
	padding: 0px 15px 20px 15px;
	${ifProp(
    'tabletScreens',
    css`
			padding: 0;
    `
  )}
`;

const StyledTypography = styled(({ tabletScreens, ...rest }) => (
  <Typography {...rest} />
))`
  margin-bottom: 4px;
  ${ifProp(
    'tabletScreens',
    css`
      margin-right: 18px;
      margin-bottom: 0;
    `
  )}
`;

export function SubcategoryHeader({
  subcategoryName,
  id,
  actionButton,
  actionButtonOnClick,
}) {
  const { tabletScreens } = useScreens();

  return (
    <HeaderWrapper tabletScreens={tabletScreens}>
      <TitleWrapper tabletScreens={tabletScreens}>
        {!tabletScreens && (
          <BackButtonWrapper>
            <BackButton />
          </BackButtonWrapper>
        )}
        <StyledTypography
          variant="h1"
          color={tabletScreens ? 'black' : 'blueMain'}
          tabletScreens={tabletScreens}
        >
          {subcategoryName}
        </StyledTypography>
        <Tooltip>
          <CustomLink to={`/${SUBCATEGORIES}/${id}`}>
            Show Category Overview
          </CustomLink>
        </Tooltip>
      </TitleWrapper>
			{actionButton && <ActionButtonWrapper tabletScreens={tabletScreens}>
      	<Button variant="primary" text={actionButton} isSmall width="135px" onClick={actionButtonOnClick}/>
			</ActionButtonWrapper>}
    </HeaderWrapper>
  );
}
