import { useCallback, useEffect, useState } from "react";

// Utils
import styled from "styled-components";

// Components
import { Box } from "@material-ui/core";
import { SearchInput } from "components/SearchInput";
import { Select, Typography } from "components";
import { EmployeeCompaniesExport } from "./components/EmployeeCompaniesExport";

// Hooks
import {
  useFilterEmployeeCompanies,
  useValueOptions,
  useRenderOptions,
} from "./hooks";

const StyledSelect = styled(Select)`
  max-width: 270px;
  margin-right: 35px;
  .MuiList-root {
    padding: 0;
  }
  .MuiPaper-root.MuiMenu-paper {
    padding: 10px 20px;
    width: auto;
  }
`;

const ResetTypography = styled(Typography)`
  height: 35px;
  width: fit-content;
  border-radius: 4px;
  margin-right: 40px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const Input = styled(SearchInput)`
  ${({ theme }) => `
      &&& {
        max-width: 270px;
        margin-right: 35px;
        height: 36px;

        .MuiIconButton-root {
          padding: 6px;
          margin-left: 6px;
          margin-right: -6px;
        }
      }
    `}
`;

const FilterTypography = styled(Typography)`
  margin-right: 15px;
`;

export function EmployeeCompaniesFilters({
  employeeCompaniesRef,
  employeeCompanies,
  setEmployeeCompanies,
}) {
  const [value, setValue] = useState("");
  const [clear, setClear] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({
    value: "sector",
    label: "Sector",
  });

  const { valueOptions, setValueOptions, valueOptionsMemo, categoryOptions } =
    useValueOptions(employeeCompaniesRef, selectedCategory);

  const { filterEmployeeCompanies } = useFilterEmployeeCompanies({
    value,
    employeeCompaniesRef,
    setEmployeeCompanies,
  });

  const handleSubmit = useCallback(() => {
    filterEmployeeCompanies(selectedValue);
  }, [filterEmployeeCompanies, selectedValue]);

  const findOption = useCallback(
    (a, v) => a.find(({ value }) => value === v),
    []
  );

  const handleCategoryChange = useCallback(
    ({ target: { value } }) => {
      setSelectedValue(null);
      setSelectedCategory(findOption(categoryOptions, value));
      setValueOptions(
        valueOptionsMemo.filter(({ category }) => category === value)
      );
    },
    [findOption, categoryOptions, setValueOptions, valueOptionsMemo]
  );

  const handleValueChange = useCallback(
    ({ target: { value } }) => {
      const newOption = findOption(valueOptions, value);

      setSelectedValue(newOption);

      filterEmployeeCompanies(newOption);
    },
    [findOption, valueOptions, filterEmployeeCompanies]
  );

  const { renderOptions } = useRenderOptions();

  const resetFilters = useCallback(() => {
    setValue("");
    setSelectedValue(null);
    setSelectedCategory({
      value: "sector",
      label: "Sector",
    });
    setEmployeeCompanies(employeeCompaniesRef);
    setValueOptions(
      valueOptionsMemo.filter(({ category }) => category === "sector")
    );
  }, [
    employeeCompaniesRef,
    setEmployeeCompanies,
    setValueOptions,
    valueOptionsMemo,
  ]);

  const handleClear = useCallback(() => {
    setValue("");
    setClear(true);
  }, []);

  useEffect(() => {
    if (clear && !value) {
      filterEmployeeCompanies(selectedValue);
      setClear(false);
    }
  }, [clear, filterEmployeeCompanies, selectedValue, value]);

  return (
    <Box pt={2.5} display="flex" alignItems="center">
      <Input
        value={value}
        setValue={setValue}
        onSubmit={handleSubmit}
        placeholder="Search for company, deal team etc."
        clearButton
        onClear={handleClear}
      />
      <FilterTypography variant="body1">Filter</FilterTypography>
      <StyledSelect
        displayEmpty
        renderValue={(selected) => selected}
        value={selectedCategory?.label ?? ""}
        onChange={handleCategoryChange}
      >
        {renderOptions({ items: categoryOptions })}
      </StyledSelect>
      <StyledSelect
        displayEmpty
        renderValue={(selected) =>
          selected || (
            <Typography variant="body1" color="darkGray">
              {`Select ${selectedCategory?.label}`}
            </Typography>
          )
        }
        value={selectedValue?.label ?? ""}
        onChange={handleValueChange}
      >
        {renderOptions({ items: valueOptions })}
      </StyledSelect>
      <ResetTypography onClick={resetFilters} variant="h6" color="blueAccent">
        Clear Filters
      </ResetTypography>
      <EmployeeCompaniesExport employeeCompanies={employeeCompanies} />
    </Box>
  );
}
