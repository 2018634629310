import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Controller } from "react-hook-form";
import { Box, Switch as MUISwitch } from "@material-ui/core";
import { Typography } from "components/Typography";

const StyledSwitch = styled(MUISwitch)`
  ${({ theme }) => `
    width: 30px;
    height: 16px;
    padding: 0;
    border-radius: 25px;
    margin-left: 10px;

    .MuiSwitch-thumb {
      width: 10px;
      height: 10px;
      box-shadow: none;
      margin-left: 6px;
      color: ${theme.palette.white};
    }

    &&& .MuiSwitch-track {
      opacity: 1;
      background: ${theme.palette.darkGray};
    }

    .MuiSwitch-switchBase {
      padding: 0;
      color: red;
      height: 100%;
      left: -2px;

      &.Mui-checked {
        transform: translateX(12px);

        + .MuiSwitch-track {
          background: ${theme.palette.blueAccent};
        }
      }
    }
  `}
`;

export function Switch({ control, title, label, name }) {
  return (
    <Box mb={3}>
      {label && (
        <Typography variant="h6" color="black">
          {label}
        </Typography>
      )}
      <Box display="flex" alignItems="center" height={36} mt={1}>
        <Typography variant="body1" color="black">
          {title}
        </Typography>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <StyledSwitch
              disableRipple
              {...field}
              checked={Boolean(field.value)}
              value={Boolean(field.value)}
            />
          )}
        />
      </Box>
    </Box>
  );
}

Switch.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired
};

Switch.defaultProps = {
  title: "Switch",
  label: ""
};
