import { useEffect, useMemo } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getOktaApps } from "redux/reducers/oktaReducer";

export function useOktaApps(username) {
  const dispatch = useDispatch();

  const { oktaApps, loading, error } = useSelector(({ okta }) => okta);

  const memoState = useMemo(
    () => ({
      oktaApps,
      loading,
      error
    }),
    [error, loading, oktaApps]
  );

  useEffect(() => {
    if (!memoState.oktaApps) {
      dispatch(getOktaApps(username));
    }
  }, [dispatch, memoState.oktaApps, username]);

  return { ...memoState };
}
