import PropTypes from "prop-types";
import { ifProp } from "styled-tools";

// Utils
import styled, { css } from "styled-components";

// Components
import { Typography } from "components";

const StyledDrawerMenuItem = styled.div`
  min-height: 20px;
	display: flex;
	${ifProp(
    'selected',
    css`
      background-color: ${(props) => props.theme.palette.blueAccent};
      color: ${(props) => props.theme.palette.white};
    `
  )};
`;
const IconWrapper = styled.div`
	margin-right: 10px;
`;

export function DrawerMenuItem({
  iconComponent,
  title,
  onClick,
  typographyVariant,
  typographyColor,
  className,
	selected,
}) {
  return (
    <StyledDrawerMenuItem disableGutters onClick={onClick} className={className} selected={selected} hasIcon={!!iconComponent}>
			{
				iconComponent && (
					<IconWrapper>
						{iconComponent}
					</IconWrapper>
				)
			}
      <Typography variant={typographyVariant} color={typographyColor}>
        {title}
      </Typography>
    </StyledDrawerMenuItem>
  );
}

DrawerMenuItem.propTypes = {
  iconComponent: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  typographyVariant: PropTypes.string,
  typographyColor: PropTypes.string,
};

DrawerMenuItem.defaultProps = {
  iconComponent: null,
  typographyVariant: "h6",
  typographyColor: "blueAccent",
	selected: false
};
