import React from 'react';
import PropTypes from 'prop-types';

// Components
import { Typography, TablePagination, Icon } from 'components';
import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody as MUITableBody,
  Paper,
  Tooltip as MuiTooltip,
} from "@material-ui/core";

// Hooks
import { useScreens } from 'hooks';

// Utils
import styled, { css } from 'styled-components';

// Theme
import theme from 'theme/theme';
import { DroppableComponent, DraggableComponent } from './components';
import { ifProp } from 'styled-tools';
import { KEYBOARD_ARROW_DOWN } from 'constants/icons';

const TableWrapper = styled(({ isFirstColumnSticky, ...rest }) => (
  <Paper {...rest} />
))`
  border-radius: 4px;

  width: 100%;

  ${ifProp(
    "isFirstColumnSticky",
    css`
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    `,
    css`
      overflow: hidden;
    `
  )}
`;

export const TableBody = styled(MUITableBody)``;

const StyledTable = styled(({ tabletScreens, tableMinWidth, ...rest }) => (
  <MuiTable {...rest} />
))`
  height: 100%;
  ${ifProp(
    "isFirstColumnSticky",
    css`
      border-collapse: separate;
    `
  )}

  &.MuiTable-root {
    min-width: ${({ tableMinWidth }) => tableMinWidth};
    width: 100%;
  }
`;

const StyledTableHead = styled(({ bgColor, ...rest }) => (
  <TableHead {...rest} />
))`
  background-color: ${({ bgColor }) => bgColor};
  height: 54px;
`;

export const CellInnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  ${ifProp(
    'noPaddings',
    css`
      padding: 0;
    `,
    css`
      padding: 16px 10px;
    `
  )};
`;

export const StyledTableCell = styled(
  ({ customWidth, color, rowIndex, noPaddings, isSticky, ...rest }) => (
    <TableCell {...rest} />
  )
)`
  padding: 0;
  background-color: ${({ color, rowIndex }) => {
    if (color) {
      return color;
    }
    return rowIndex % 2 ? theme.palette.graySuperLight : theme.palette.white;
  }};
  border-bottom: none;
  width: ${({ customWidth }) => customWidth || "auto"};

  ${ifProp(
    "isSticky",
    css`
      position: sticky;
      left: 0;
      z-index: 1;
      border-right: 1px solid rgba(161, 171, 187, 0.25);
    `
  )}

  &:first-of-type {
    ${CellInnerWrapper} {
      ${ifProp(
        'noPaddings',
        css`
          padding-left: 0;
        `,
        css`
          padding-left: 20px;
        `
      )}
    }
  }
  &:last-of-type {
    ${CellInnerWrapper} {
      ${ifProp(
        'noPaddings',
        css`
          padding-right: 0;
        `,
        css`
          padding-right: 20px;
        `
      )}
    }
  }
`;

const TableHeadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100% */
`;

const TableHeadCell = styled(({ bgColor, customWidth, isSticky, ...rest }) => (
  <TableCell {...rest} />
))`
  background-color: ${({ bgColor, theme }) =>
    bgColor || theme.palette.blueSoft};
  ${CellInnerWrapper} {
    background-color: ${({ bgColor, theme }) =>
      bgColor || theme.palette.blueSoft};
    height: 54px;
  }
  width: ${({ customWidth }) => customWidth || 'auto'};
  padding: 0;

  ${ifProp(
    "isSticky",
    css`
      position: sticky;
      left: 0;
      z-index: 1;
      border-right: 1px solid rgba(161, 171, 187, 0.25);
    `
  )}

  &:first-of-type {
    ${CellInnerWrapper} {
      padding-left: 20px;
    }
  }
  &:last-of-type {
    ${CellInnerWrapper} {
      padding-right: 20px;
    }
  }
`;

const StyledTableRow = styled(({ isWithTableBodyHeading, ...rest }) => (
  <TableRow {...rest} />
))`
  &:last-of-type {
    ${StyledTableCell} {
      border-bottom: none;
    }
    .MuiTableCell-root {
      &:first-of-type {
        ${StyledTableCell} {
          border-radius: 0 0 0 4px;
        }
      }
      &:last-of-type {
        ${StyledTableCell} {
          border-radius: 0 0 4px 0;
        }
      }
    }
  }
  &:not(:last-of-type) {
    ${StyledTableCell} {
      border-bottom: 1px solid rgba(161, 171, 187, 0.25);
    }
  }
`;

const SortIconWrapper = styled.div`
  transform: ${({ isIconUp }) =>
    isIconUp ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: all 0.3s ease-in-out;
`;

const Tooltip = styled(MuiTooltip)`
  ${({ theme }) => `
    + .MuiTooltip-popper {
    
      .MuiTooltip-tooltipPlacementTop{
          margin: 4px 0;
      }
    }
  `}
`;

const TextWrapper = styled.div`
  position: relative;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const Table = ({
  tableHeadData,
  tableRowsData,
  tableBodyHeading,
  tableMinWidth,
  tableHeadColor,
  isDraggable,
  onDragEnd,
  rawData,
  paginationOptions,
  noPaddings,
  isFirstColumnSticky,
}) => {
  const { tabletScreens } = useScreens();

  return (
    <>
      <TableWrapper elevation={2} isFirstColumnSticky={isFirstColumnSticky}>
        <StyledTable
          tabletScreens={tabletScreens}
          aria-label="customized table"
          tableMinWidth={tableMinWidth}
          isFirstColumnSticky={isFirstColumnSticky}
        >
          {tableHeadData && (
            <StyledTableHead bgColor={tableHeadColor}>
              <TableRow>
                {tableHeadData?.map((dataItem, i) => (
                  <TableHeadCell
                    key={dataItem.name}
                    bgColor={tableHeadColor}
                    colSpan={dataItem.colSpan}
                    customWidth={dataItem.width}
                    isSticky={i === 0 && isFirstColumnSticky}
                    className={i === 0 && isFirstColumnSticky ? "sticky" : ""}
                  >
                    <CellInnerWrapper
                      noPaddings={noPaddings}
                      isClickable={!!dataItem.onSortClick}
                      onClick={() =>
                        dataItem.onSortClick && dataItem.onSortClick()
                      }
                    >
                      {dataItem.title ? (
                        <TableHeadWrapper>
                          <Typography variant={'h6'} color="white">
                            {dataItem.title}
                          </Typography>
                          {dataItem.onSortClick && (
                            <SortIconWrapper isIconUp={dataItem.isSortIconUp}>
                              <Icon
                                type={KEYBOARD_ARROW_DOWN}
                                color={
                                  dataItem.isSortIconActive ? 'white' : 'gray'
                                }
                                isActive={dataItem.isSortIconActive}
                              />
                            </SortIconWrapper>
                          )}
                        </TableHeadWrapper>
                      ) : (
                        dataItem.component
                      )}
                    </CellInnerWrapper>
                  </TableHeadCell>
                ))}
              </TableRow>
            </StyledTableHead>
          )}
          <TableBody component={DroppableComponent(onDragEnd)}>
            {tableBodyHeading && (
              <StyledTableRow isWithTableBodyHeading={tableBodyHeading}>
                <StyledTableCell
                  colSpan={tableHeadData.length}
                  noPaddings={noPaddings}
                >
                  <CellInnerWrapper noPaddings={noPaddings}>
                    {tableBodyHeading}
                  </CellInnerWrapper>
                </StyledTableCell>
              </StyledTableRow>
            )}
            {tableRowsData?.map((row, i) => {
              return (
                <StyledTableRow
                  key={rawData?.[i]?.id ?? i}
                  isWithTableBodyHeading={tableBodyHeading}
                  component={DraggableComponent(
                    `${rawData?.[i]?.id ?? i}`,
                    i,
                    isDraggable
                  )}
                >
                  {row?.map((rowItem, index) => {
                    return (
                      <StyledTableCell
                        key={rowItem.name}
                        customWidth={rowItem.width}
                        color={rowItem.color}
                        rowIndex={i}
                        colSpan={rowItem.colSpan}
                        noPaddings={noPaddings}
                        isSticky={index === 0 && isFirstColumnSticky}
                      >
                        <CellInnerWrapper noPaddings={noPaddings}>
                          {rowItem.text ? (
                            <Tooltip
                              placement="top-start"
                              arrow
                              title={rowItem.text}
                              PopperProps={{
                                disablePortal: true,
                              }}
                            >
                              <TextWrapper>
                                <Typography
                                  variant="body1"
                                  color={rowItem.textColor || "black"}
                                >
                                  {rowItem.text}
                                </Typography>
                              </TextWrapper>
                            </Tooltip>
                          ) : (
                            rowItem.component
                          )}
                        </CellInnerWrapper>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableWrapper>
      {paginationOptions && tableRowsData?.length > 0 && (
        <TablePagination paginationOptions={paginationOptions} />
      )}
    </>
  );
};

Table.propTypes = {
  tableHeadData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        colSpan: PropTypes.number,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        onSortClick: PropTypes.func,
      }),
      PropTypes.shape({
        component: PropTypes.element,
        colSpan: PropTypes.number,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
      }),
    ])
  ),
  tableRowsData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        component: PropTypes.element,
        width: PropTypes.string,
        name: PropTypes.string.isRequired,
        textColor: PropTypes.string,
      })
    )
  ),
  tableBodyHeading: PropTypes.element,
  tableMinWidth: PropTypes.string,
  tableHeadColor: PropTypes.string,
  isDraggable: PropTypes.bool,
  rawData: PropTypes.array,
  paginationOptions: PropTypes.shape({
    rowsPerPageOptions: PropTypes.array,
    rowsNumber: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    displayedRowsLabel: PropTypes.string,
  }),
};

Table.defaultProps = {
  tableMinWidth: '945px',
  tableHeadColor: theme.palette.blueSoft,
  isDraggable: false,
  paginationOptions: null,
};
