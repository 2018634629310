// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const ocpKey = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;

const api = apiConfig(baseURL, ocpKey);

export async function getAllSubcategories(visible = false) {
  const path = `/compass/categories${visible ? "?visible=true" : ""}`;
  const { data: response } = await api.get(path);

  return response.data.reduce((acc, category) => {
    const mappedSubcategories = category.subCategories.map((subcategory) => ({
      ...subcategory,
      categoryName: category.name,
    }));
    return [...acc, ...mappedSubcategories];
  }, []);
}

export async function getSubcategoriesByCategoryId(categoryId) {
  const { data: response } = await api.get(
    `/compass/categories/${categoryId}/subcategories`
  );
  return response.data.subCategories.map((subcategory) => ({
    ...subcategory,
    categoryName: response.data.category.name,
  }));
}

export async function sortSubcategory(id, sortOrder) {
  const { data } = await api.patch(
    `/compass/subcategories/${id}/sort/${sortOrder}`
  );
  return data;
}

export async function getSubcategoryById(subcategoryId) {
  const { data } = await api.get(`/compass/subcategories/${subcategoryId}`);
  return data.data.subCategories[0];
}

export async function updateSubcategory(subcategoryId, subcategoryData) {
  const { data } = await api.patch(
    `/compass/subcategories/${subcategoryId}`,
    subcategoryData
  );

  return data.data.subCategories[0];
}

export async function patchKeyInformation(keyInformationData) {
  const dataToSend = JSON.stringify(keyInformationData);
  const { data } = await api.patch(`/compass/keyinformation`, dataToSend);

  return data.data[0];
}

export async function updateKeyInformation(keyInformationData) {
  const dataToSend = JSON.stringify(keyInformationData);
  const { data } = await api.put(`/compass/keyinformation`, dataToSend);

  return data.data[0];
}

export async function deleteKeyInformation(keyInformationData) {
  const dataToSend = JSON.stringify(keyInformationData);
  const { data } = await api.delete(`/compass/keyinformation`, {
    data: dataToSend,
  });

  return data;
}

export async function deleteSubcategory(id) {
  const { data } = await api.delete(`/compass/subcategories/${id}`);

  return data;
}

export async function createSubcategory(payload) {
  const { data: response } = await api.post("/compass/subcategories", payload);

  const { data } = response;
  return data;
}

export async function getSubcategory(subcategoryId) {
  const { data: response } = await api.get(
    `/compass/subcategories/${subcategoryId}`
  );

  const subcategory = response.data.subCategories[0];

  return {
    ...subcategory,
    keyInformation: subcategory.keyInformation.sort(function (a, b) {
      return a.sortOrder - b.sortOrder;
    }),
  };
}
