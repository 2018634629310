import React, { useEffect, useMemo } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getSurveyCategories,
  createSurveyCategory,
  editSurveyCategory,
  deleteSurveyCategory,
} from "redux/reducers/surveyCategoriesReducer";

export function useSurveyCategories() {
  const dispatch = useDispatch();

  const { surveyCategories, loading, error } = useSelector(
    ({ surveyCategories }) => surveyCategories
  );

  const setCategories = React.useCallback(() => {
    dispatch(getSurveyCategories());
  }, [dispatch]);

  const addSurveyCategory = React.useCallback(
    (payload) => {
      return dispatch(createSurveyCategory(payload));
    },
    [dispatch]
  );

  const updateSurveyCategory = React.useCallback(
    (payload, id) => {
      dispatch(editSurveyCategory({ payload, id }));
    },
    [dispatch]
  );

  const removeSurveyCategory = React.useCallback(
    (id) => {
      return dispatch(deleteSurveyCategory(id));
    },
    [dispatch]
  );

  const memoState = useMemo(
    () => ({
      surveyCategories,
      setCategories,
      addSurveyCategory,
      updateSurveyCategory,
      removeSurveyCategory,
      loading,
      error,
    }),
    [
      error,
      loading,
      surveyCategories,
      setCategories,
      addSurveyCategory,
      updateSurveyCategory,
      removeSurveyCategory,
    ]
  );

  useEffect(() => {
    if (!memoState.surveyCategories) {
      dispatch(getSurveyCategories());
    }
  }, [dispatch, memoState.surveyCategories]);

  return { ...memoState };
}
