import styled from "styled-components";

// Components
import { Icon } from "components/Icon";
import { PageHeaderLogo } from "components/Layouts/PageLayout/PageHeader/components";

// Contexts
import { useDrawer } from "contexts";

//Constants
import { CLOSE } from "constants/index";

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
  align-items: center;
	padding: 16px 15px;
`;

const IconWrapper = styled.div`
	padding: 2px;
`;

export const DrawerHeader = () => {
	const { toggleDrawer } = useDrawer();

	return (
			<Wrapper>
				<PageHeaderLogo />
				<IconWrapper onClick={() => toggleDrawer(false)}>
					<Icon type={ CLOSE } />
				</IconWrapper>
			</Wrapper>
	)
}