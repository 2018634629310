import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { FormTableRowActionButtons, Switch, TextInput } from "components";
import { CategoriesSelect } from ".";
import { TagsSelect } from "./TagsSelect";
import { useWatch } from "react-hook-form";

const QuestionInfo = styled.div`
  display: flex;
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid rgba(161, 171, 187, 0.25);
  align-items: center;
`;

const Wrapper = styled.div`
  width: ${({ width }) => width || "auto"};
`;

const FormElementWrapper = styled.div`
  width: 200px;
  .MuiFormControl-root {
    margin-bottom: 0;
  }
`;

const SwitchWrapper = styled.div`
  .MuiBox-root {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const ActionsButtonWrapper = styled.div`
  position: relative;
  height: 40px;
`;

export const QuestionInfoForm = ({
  question,
  control,
  onCancelClick,
  handleSubmit,
}) => {
  const b2cField = useWatch({
    control,
    name: "b2cSectors",
  });
  const b2bField = useWatch({
    control,
    name: "b2bSectors",
  });

  return (
    <QuestionInfo>
      <Wrapper width="4%" />
      <Wrapper width="15%">
        <FormElementWrapper>
          <TextInput
            inputProps={{
              autoFocus: true,
              placeholder: "Enter Title",
            }}
            controllerProps={{
              control,
              name: "name",
              rules: {
                required: "Question name is required",
                maxLength: {
                  value: 200,
                  message: "Max length: 200 symbols",
                },
              },
              value: question?.name,
            }}
          />
        </FormElementWrapper>
      </Wrapper>
      <Wrapper width="16%">
        <FormElementWrapper>
          <CategoriesSelect control={control} />
        </FormElementWrapper>
      </Wrapper>
      <Wrapper width="19%">
        <FormElementWrapper>
          <TagsSelect
            name="b2bSectors"
            label="b2b Sectors"
            control={control}
            isRequired={!b2cField.length}
          />
        </FormElementWrapper>
      </Wrapper>
      <Wrapper width="19%">
        <FormElementWrapper>
          <TagsSelect
            control={control}
            label="b2c Sectors"
            name="b2cSectors"
            isRequired={!b2bField?.length}
          />
        </FormElementWrapper>
      </Wrapper>
      <Wrapper width="10%">
        <SwitchWrapper>
          <Switch title="" name={"isPreselected"} control={control} />
        </SwitchWrapper>
      </Wrapper>
      <Wrapper width="9%">
        <SwitchWrapper>
          <Switch title="" name={"isBrandLoop"} control={control} />
        </SwitchWrapper>
      </Wrapper>
      <Wrapper width="8%">
        <ActionsButtonWrapper>
          <FormTableRowActionButtons
            control={control}
            onCancelClick={() => {
              onCancelClick();
            }}
            onSaveClick={handleSubmit}
          />
        </ActionsButtonWrapper>
      </Wrapper>
    </QuestionInfo>
  );
};

QuestionInfoForm.propTypes = {
  question: PropTypes.object,
  onCancelClick: PropTypes.func,
};
