import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useMemo,
} from "react";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { LinearProgress } from "@material-ui/core";

// Hooks
import { useScreens } from "hooks";
import { useSelector } from "react-redux";

const LinearProgressContext = createContext({
  setProgress: () => {},
});

const ProgressWrap = styled(({ tabletScreens, ...rest }) => <div {...rest} />)`
  ${({ theme }) => css`
    height: 4px;
    width: 100%;
    background: transparent;
    position: fixed;
    top: 60px;
    z-index: 1000;

    .MuiLinearProgress-barColorPrimary {
      background-color: ${theme.palette.blueAccent};
    }

    .MuiLinearProgress-colorPrimary {
      background-color: ${theme.palette.grayMiddle};
    }
  `}

  ${ifProp(
    "tabletScreens",
    css`
      top: 76px;
    `
  )}
`;

export const LinearProgressProvider = ({ children }) => {
  const { loading } = useSelector(({ linearProgress }) => linearProgress);
  const { tabletScreens } = useScreens();
  const [progress, setProgress] = useState(false);

  const setProgressCallback = useCallback(setProgress, [setProgress]);

  const progressMemo = useMemo(() => progress, [progress]);

  const valueMemo = useMemo(
    () => ({
      setProgress: setProgressCallback,
      progress: progressMemo,
    }),
    [setProgressCallback, progressMemo]
  );

  return (
    <LinearProgressContext.Provider value={valueMemo}>
      <ProgressWrap tabletScreens={tabletScreens}>
        {(progress || loading) && <LinearProgress />}
      </ProgressWrap>
      {children}
    </LinearProgressContext.Provider>
  );
};

export const useLinearProgressContext = () => useContext(LinearProgressContext);
