// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { useParams } from "react-router";

// Components
import { Loader, Typography } from "components";
import { EmployeeCompaniesTable } from "./components/EmployeeCompaniesTable";
import { EmployeeCompaniesFilters } from "./components/EmployeeCompaniesFilters";
import { Box } from "@material-ui/core";

// Hooks
import { useScreens, useEmployeeCompanies } from "hooks";

const Wrapper = styled.div`
  padding: 0 20px;
  ${ifProp(
    "tabletScreens",
    css`
      padding: 0 40px;
    `
  )}
`;

export const Divider = styled.span`
  ${({ theme }) => `
        width: 100%;
        height: 1px;
        margin: 25px 0;
        background-color: ${theme.palette.grayMiddle};
        display: flex;
    `}
`;

export function EmployeeCompanies({ profileId, noContentDescription }) {
  const params = useParams();
  const { tabletScreens } = useScreens();
  profileId = profileId ? profileId : params.employeeId;
  const {
    employeeCompanies,
    employeeCompaniesRef,
    setEmployeeCompanies,
    loading,
    error,
  } = useEmployeeCompanies(profileId);

  if (loading) {
    return <Loader fullPage />;
  }

  if (error) {
    // return <Redirect to="/error" />;
  }

  return (
    <Box mt={!tabletScreens ? 3 : 0}>
      <Wrapper tabletScreens={tabletScreens}>
        {tabletScreens && <Divider />}
        <Typography variant="h2" color="blueMain">
          Related Companies
        </Typography>
        {tabletScreens && (
          <EmployeeCompaniesFilters
            employeeCompaniesRef={employeeCompaniesRef}
            employeeCompanies={employeeCompanies}
            setEmployeeCompanies={setEmployeeCompanies}
          />
        )}
      </Wrapper>
      <EmployeeCompaniesTable
        employeeCompanies={employeeCompanies}
        noContentDescription={noContentDescription}
      />
    </Box>
  );
}
