import { useCallback } from "react";

// Components
import { MenuItem } from "@material-ui/core";

export function useRenderOptions() {
  const renderOptions = useCallback(
    ({ items }) =>
      items?.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      )),
    []
  );

  return { renderOptions };
}
