// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;

const api = apiConfig(baseURL);

export async function getSurveyCategories() {
  const { data } = await api.get("/compass/GetCategories");

  return data.sort(function (a, b) {
    return a.sortOrder - b.sortOrder;
  });
}

export async function createSurveyCategory(payload) {
  const dataToSend = JSON.stringify(payload);
  const { data } = await api.post("/compass/CreateCategory", dataToSend);
  return data;
}

export async function updateSurveyCategory(payload, id) {
  const fullPayload = {
    ...payload,
    id,
  };
  const dataToSend = JSON.stringify(fullPayload);
  const { data } = await api.put(`/compass/UpdateCategory`, dataToSend);

  return data;
}

export async function deleteSurveyCategory(id) {
  const { data } = await api.delete(`/compass/DeleteCategory?categoryId=${id}`);

  return data;
}
