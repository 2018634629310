// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { Avatar as MUIAvatar } from "@material-ui/core";
import { Icon, Loader, MenuItem } from "components";
import { PageHeaderPopper } from "../PageHeaderPopper";
import { PageHeaderDivider } from "../PageHeaderDivider";
import { PageHeaderUserInfo } from "./PageHeaderUserInfo";

// Constants
import { ADMIN, LOGOUT, PROFILE } from "constants/index";

// Hooks
import { useUserData, useHandleLogoutWithRedirect, useMinWidth } from "hooks";
import { useHistory } from "react-router";
import { useState } from "react";
import { fetchIsAdmin } from "service";
import { encrypt, setValue } from "utils";

const Avatar = styled(({ tabletScreens, ...rest }) => <MUIAvatar {...rest} />)`
  width: 24px;
  height: 24px;
  font-size: 16px;

  ${ifProp(
    "tabletScreens",
    css`
      width: 32px;
      height: 32px;
    `
  )}
`;

export function PageHeaderAvatar() {
  const history = useHistory();
  const tabletScreens = useMinWidth("tablet");
  const { handleLogout: logout } = useHandleLogoutWithRedirect();
  const {
    user: { fullName, email, imgSrc },
  } = useUserData();

  const [isUserAdmin, setIsUserAdmin] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const onPopperClick = async () => {
    try {
      setIsLoading(true);
      const { data } = await fetchIsAdmin();
      setIsUserAdmin(data?.isAdmin);
      const isAdminEncrypted = encrypt(data?.isAdmin.toString());
      setValue("isAdmin", isAdminEncrypted);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  const handleLogout = (handleClose) => async () => {
    try {
      await logout();
      handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  const handleGoToAdminPanel = (handleClose) => () => {
    history.push("/admin");
    handleClose();
  };

  const handleGoToMyProfile = (handleClose) => () => {
    history.push(`/profile/${email}/`);
    handleClose();
  };

  return (
    <PageHeaderPopper
      onClick={onPopperClick}
      iconComponent={
        <Avatar alt={fullName} src={imgSrc} tabletScreens={tabletScreens} />
      }
    >
      {({ handleClose }) =>
        isLoading ? (
          <Loader />
        ) : (
          <>
            <PageHeaderUserInfo
              fullName={fullName}
              email={email}
              imgSrc={imgSrc}
            />
            <MenuItem
              iconComponent={<Icon type={PROFILE} />}
              title="My Profile"
              onClick={handleGoToMyProfile(handleClose)}
            />
            {isUserAdmin && (
              <MenuItem
                iconComponent={<Icon type={ADMIN} />}
                title="Admin Panel Settings"
                onClick={handleGoToAdminPanel(handleClose)}
              />
            )}
            <PageHeaderDivider />
            <MenuItem
              iconComponent={<Icon type={LOGOUT} />}
              title="Logout"
              onClick={handleLogout(handleClose)}
            />
          </>
        )
      }
    </PageHeaderPopper>
  );
}
