import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Icon } from "components/Icon";
import { Typography } from "components/Typography";
import { Tooltip as MUITooltip, Box } from "@material-ui/core";

// Constants
import { INFO } from "constants/index";

const InfoIcon = styled(Icon)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledTooltip = styled(MUITooltip)`
  ${({ theme }) => `
    + .MuiTooltip-popper {
      .MuiTooltip-tooltip {
        background: ${theme.palette.white};
        box-shadow: ${theme.palette.shadowCard};
        padding: 12px 20px;
        max-width: none;
      }

      .MuiTooltip-tooltipPlacementRight {
        margin: 0 10px;
      }

      .MuiTooltip-arrow {
        color: ${theme.palette.white};
      }
    }
  `}
`;

export function Tooltip({ children, open, errorMessage }) {
  return (
    <StyledTooltip
      open={open && Boolean(errorMessage)}
      title={
        <Box display="flex" alignItems="center">
          <InfoIcon type={INFO} color="red" />
          <Typography variant="caption" color="black">
            {errorMessage}
          </Typography>
        </Box>
      }
      placement="right"
      arrow
      PopperProps={{
        disablePortal: true,
      }}
    >
      {children}
    </StyledTooltip>
  );
}

Tooltip.propTypes = {
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};
