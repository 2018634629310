import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { NoContentCard, Table } from "components";

// Hooks
import { useScreens, useTablePagination } from "hooks";
import { useRows } from "./useRows";
import { surveySort } from "./surveySort";

const TableScrollWrapper = styled.div`
  margin-top: 16px;
  overflow-x: auto;
  padding-bottom: 20px;
  display: flex;
`;

const TableWrapper = styled.div`
  display: inline-block;
  flex-grow: 1;
  padding: 0 15px;
  ${ifProp(
    "tabletScreens",
    css`
      padding: 0 32px;
    `
  )}
`;

export const SurveysTable = ({ surveys, showNoResultsCard, removeSurvey }) => {
  const { tabletScreens } = useScreens();
  const [sort, setSort] = useState("launch_date_desc");

  const [sortedSurveys, setSortedSurveys] = useState(surveys);

  useEffect(() => {
    if (surveys) {
      setSortedSurveys(surveys);
    }
  }, [surveys]);

  const onTopicSort = () => {
    const nextVal = sort === "topic_asc" ? "topic_desc" : "topic_asc";
    setSort(nextVal);
  };

  const onStatusSort = () => {
    const nextVal = sort === "status_asc" ? "status_desc" : "status_asc";
    setSort(nextVal);
  };

  const onCreatedBySort = () => {
    const nextVal =
      sort === "created_by_asc" ? "created_by_desc" : "created_by_asc";
    setSort(nextVal);
  };

  const onCreatedDateSort = () => {
    const nextVal =
      sort === "created_date_asc" ? "created_date_desc" : "created_date_asc";
    setSort(nextVal);
  };

  const onLaunchDateSort = () => {
    const nextVal =
      sort === "launch_date_asc" ? "launch_date_desc" : "launch_date_asc";
    setSort(nextVal);
  };

  const onTeamMembersSort = () => {
    const nextVal =
      sort === "team_members_asc" ? "team_members_desc" : "team_members_asc";
    setSort(nextVal);
  };

  const onSectorSort = () => {
    const nextVal = sort === "sector_asc" ? "sector_desc" : "sector_asc";
    setSort(nextVal);
  };

  const onDealEntitySort = () => {
    const nextVal =
      sort === "deal_entity_asc" ? "deal_entity_desc" : "deal_entity_asc";
    setSort(nextVal);
  };

  const onBusinessTypeSort = () => {
    const nextVal =
      sort === "business_type_asc" ? "business_type_desc" : "business_type_asc";
    setSort(nextVal);
  };
  const onDealTypeSort = () => {
    const nextVal =
      sort === "deal_type_asc" ? "deal_type_desc" : "deal_type_asc";
    setSort(nextVal);
  };

  const tableHeadData = [
    {
      title: "Topic",
      name: "topic",
      onSortClick: onTopicSort,
      isSortIconActive: sort?.includes("topic"),
      isSortIconUp: sort === "topic_asc",
    },
    {
      title: "Status",
      name: "status",
      onSortClick: onStatusSort,
      isSortIconActive: sort?.includes("status"),
      isSortIconUp: sort === "status_asc",
    },
    {
      title: "Created By",
      name: "createdBy",
      onSortClick: onCreatedBySort,
      isSortIconActive: sort?.includes("created_by"),
      isSortIconUp: sort === "created_by_asc",
    },
    {
      title: "Created Date",
      name: "createdDate",
      onSortClick: onCreatedDateSort,
      isSortIconActive: sort?.includes("created_date"),
      isSortIconUp: sort === "created_date_asc",
    },
    {
      title: "Target Launch Date",
      name: "targetLaunchDate",
      onSortClick: onLaunchDateSort,
      isSortIconActive: sort?.includes("launch_date"),
      isSortIconUp: sort === "launch_date_asc",
    },
    {
      title: "Team Members",
      name: "teamMembers",
      onSortClick: onTeamMembersSort,
      isSortIconActive: sort?.includes("team_members"),
      isSortIconUp: sort === "team_members_asc",
    },
    {
      title: "Sector",
      name: "sector",
      onSortClick: onSectorSort,
      isSortIconActive: sort?.includes("sector"),
      isSortIconUp: sort === "sector_asc",
    },
    {
      title: "Deal Entity Name",
      name: "dealEntityName",
      onSortClick: onDealEntitySort,
      isSortIconActive: sort?.includes("deal_entity"),
      isSortIconUp: sort === "deal_entity_asc",
    },
    {
      title: "Business Type",
      name: "businessType",
      onSortClick: onBusinessTypeSort,
      isSortIconActive: sort?.includes("business_type"),
      isSortIconUp: sort === "business_type_asc",
    },
    {
      title: "Deal Type",
      name: "dealType",
      onSortClick: onDealTypeSort,
      isSortIconActive: sort?.includes("deal_type"),
      isSortIconUp: sort === "deal_type_asc",
    },
    { title: "Action", name: "action" },
  ];

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    tableRowsDataPerPage: surveysPerPage,
  } = useTablePagination({ initialRowsPerPage: 25, rows: sortedSurveys });

  const rows = useRows(surveysPerPage, removeSurvey);

  useEffect(() => {
    setSortedSurveys((prev) => {
      if (!prev?.length) return;
      return surveySort([...prev], sort);
    });
  }, [sort, setSortedSurveys]);

  const paginationOptions = {
    rowsPerPageOptions: [5, 10, 25],
    rowsNumber: surveys?.length,
    page: page,
    rowsPerPage: rowsPerPage,
    handleChangePage: handleChangePage,
    handleChangeRowsPerPage: handleChangeRowsPerPage,
  };

  if (showNoResultsCard && !surveys?.length)
    return <NoContentCard title="Sorry, there are no results" />;

  return (
    <TableScrollWrapper>
      <TableWrapper tabletScreens={tabletScreens}>
        <Table
          paginationOptions={paginationOptions}
          tableHeadData={tableHeadData}
          tableMinWidth="1800px"
          tableRowsData={rows}
          rawData={surveys}
        />
      </TableWrapper>
    </TableScrollWrapper>
  );
};

SurveysTable.propTypes = {
  surveys: PropTypes.array,
  showNoResultsCard: PropTypes.bool,
};
