// Utils
import { createReducer } from "@reduxjs/toolkit";
import {
  createCategory,
  deleteCategory,
  editCategory,
  sortCategory,
} from "./categoriesReducer";
import { deleteSubcategory, sortSubcategory } from "./categoryReducer";

// Actions
import { updateSubcategory } from "./subcategoriesReducer";
import {
  patchKeyInformation,
  deleteKeyInformation,
  updateKeyInformation,
} from "./subcategoryReducer";
import {
  createSubcategoryTag,
  deleteSubcategoryTag,
} from "./subcategoryTagsReducer";
import { editKeyHighlight } from "./keyHighlightsReducer";
import {
  updateSingleNews,
  deleteSingleNews,
  createSingleNews,
} from "./newsReducer";
import {
  createSurveyCategory,
  deleteSurveyCategory,
  editSurveyCategory,
} from "./surveyCategoriesReducer";
import {
  createSurveyQuestion,
  deleteSurveyQuestion,
  editSurveyQuestion,
} from "./surveyQuestionsReducer";
import { deleteSurvey } from "./surveysReducer";

const initialState = {
  loading: false,
};

export const linearProgressReducer = createReducer(initialState, (builder) =>
  builder
    // Subcategory
    .addCase(updateSubcategory.pending, (state, _) => {
      state.loading = true;
    })
    .addCase(updateSubcategory.fulfilled, (state, _) => {
      state.loading = false;
    })
    .addCase(updateSubcategory.rejected, (state, _) => {
      state.loading = false;
    })
    .addCase(deleteSubcategory.pending, (state, _) => {
      state.loading = true;
    })
    .addCase(deleteSubcategory.fulfilled, (state, _) => {
      state.loading = false;
    })
    .addCase(deleteSubcategory.rejected, (state, _) => {
      state.loading = false;
    })
    .addCase(sortSubcategory.pending, (state, _) => {
      state.loading = true;
    })
    .addCase(sortSubcategory.fulfilled, (state, _) => {
      state.loading = false;
    })
    .addCase(sortSubcategory.rejected, (state, _) => {
      state.loading = false;
    })
    // KeyInformation
    .addCase(patchKeyInformation.pending, (state, _) => {
      state.loading = true;
    })
    .addCase(patchKeyInformation.fulfilled, (state, _) => {
      state.loading = false;
    })
    .addCase(patchKeyInformation.rejected, (state, _) => {
      state.loading = false;
    })
    .addCase(deleteKeyInformation.pending, (state, _) => {
      state.loading = true;
    })
    .addCase(deleteKeyInformation.fulfilled, (state, _) => {
      state.loading = false;
    })
    .addCase(deleteKeyInformation.rejected, (state, _) => {
      state.loading = false;
    })
    .addCase(updateKeyInformation.pending, (state, _) => {
      state.loading = true;
    })
    .addCase(updateKeyInformation.fulfilled, (state, _) => {
      state.loading = false;
    })
    .addCase(updateKeyInformation.rejected, (state, _) => {
      state.loading = false;
    })
    // Category
    .addCase(createCategory.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createCategory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createCategory.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(editCategory.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(editCategory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(editCategory.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteCategory.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteCategory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteCategory.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(sortCategory.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(sortCategory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(sortCategory.rejected, (state, action) => {
      state.loading = false;
    })
    // Subcategory Tag
    .addCase(createSubcategoryTag.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createSubcategoryTag.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createSubcategoryTag.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSubcategoryTag.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteSubcategoryTag.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSubcategoryTag.rejected, (state, action) => {
      state.loading = false;
    })
    // Key Highlights
    .addCase(editKeyHighlight.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(editKeyHighlight.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(editKeyHighlight.rejected, (state, action) => {
      state.loading = false;
    })
    // News
    .addCase(updateSingleNews.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(updateSingleNews.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(updateSingleNews.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSingleNews.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteSingleNews.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSingleNews.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(createSingleNews.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createSingleNews.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createSingleNews.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(createSurveyCategory.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createSurveyCategory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createSurveyCategory.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(editSurveyCategory.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(editSurveyCategory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(editSurveyCategory.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSurveyCategory.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteSurveyCategory.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSurveyCategory.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(createSurveyQuestion.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(createSurveyQuestion.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(createSurveyQuestion.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(editSurveyQuestion.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(editSurveyQuestion.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(editSurveyQuestion.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSurveyQuestion.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteSurveyQuestion.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSurveyQuestion.rejected, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSurvey.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(deleteSurvey.fulfilled, (state, action) => {
      state.loading = false;
    })
    .addCase(deleteSurvey.rejected, (state, action) => {
      state.loading = false;
    })
);
