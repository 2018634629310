import React from "react";
import PropTypes from "prop-types";

// Components
import { Box, IconButton } from "@material-ui/core";
import { Icon, Typography } from "components";

// Constants
import { EDIT, DELETE } from "constants/icons";

// Utils
import styled from "styled-components";

const ActionButtonWrapper = styled(IconButton)`
  display: flex;
  padding: 0;
  &:hover {
    background: none;
  }
  &:not(:last-of-type) {
    margin-right: 16px;
  }
`;

const EditTypography = styled(Typography)`
  margin-left: 4px;
`;

export const EditDeleteButtons = ({
  isEditOnly,
  isDeleteOnly,
  onEditClick,
  onDeleteClick,
}) => {
  return (
    <Box display="flex">
      {!isDeleteOnly && (
        <ActionButtonWrapper onClick={onEditClick}>
          <Icon type={EDIT} width="16px" color="blueAccent" />
          <EditTypography variant="h6" color="blueAccent">
            Edit
          </EditTypography>
        </ActionButtonWrapper>
      )}
      {!isEditOnly && (
        <ActionButtonWrapper onClick={onDeleteClick}>
          <Icon type={DELETE} width="16px" color="darkGray" />
          <EditTypography variant="h6" color="darkGray">
            Delete
          </EditTypography>
        </ActionButtonWrapper>
      )}
    </Box>
  );
};

EditDeleteButtons.propTypes = {
  isEditOnly: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

EditDeleteButtons.defaultProps = {
  isEditOnly: false,
  onEditClick: () => {},
  onDeleteClick: () => {},
};
