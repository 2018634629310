import React, { useContext, useState } from "react";

// Components
import { Drawer } from "components/Drawer";

const DrawerContext = React.createContext();

export const useDrawer = () => {
  const context = useContext(DrawerContext);

  if (!context) {
    throw new Error("useDrawerContext cannot be used without DrawerContext");
  }

  return context;
};

export const DrawerProvider = ({ children }) => {
  const [drawerConfig, setDrawerConfig] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("");

  const toggleDrawer = (state) => {
    setIsOpen(state);
  };

  const onDrawerInit = (config) => {
    setDrawerConfig(config);
  };

  const value = React.useMemo(
    () => ({
      toggleDrawer,
      onDrawerInit,
      setSelectedMenuItem,
      selectedMenuItem,
    }),
    [selectedMenuItem]
  );

  return (
    <DrawerContext.Provider value={value}>
      <Drawer
        anchor={"left"}
        open={isOpen && Boolean(drawerConfig?.content)}
        onClose={() => toggleDrawer(false)}
      >
        {drawerConfig?.content}
      </Drawer>
      {children}
    </DrawerContext.Provider>
  );
};
