// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import { getKeyInformationList as fetchKeyInformationList } from "service";

// Actions
import { GET_KEY_INFORMATION_LIST } from "constants/index";

const initialState = {
  keyInformationList: null,
  error: null,
  loading: true,
};

export const getKeyInformationList = createAsyncThunk(
  GET_KEY_INFORMATION_LIST,
  async (subcategoryId, { rejectWithValue }) => {
    try {
      return await fetchKeyInformationList(subcategoryId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const keyInformationReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getKeyInformationList.fulfilled, (state, action) => {
      state.keyInformationList = action.payload;
      state.loading = false;
    })
    .addCase(getKeyInformationList.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
