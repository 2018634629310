import { SaveCancelButtons } from 'components';
import React from 'react';
import { useWatch } from 'react-hook-form';
import styled from 'styled-components';

const SaveAndCancelWrapper = styled.div`
  position: absolute;
  right: 0;
`;

export const FormTableRowActionButtons = ({
  control,
  name,
  onCancelClick,
  onSaveClick,
}) => {
  const field = useWatch({
    control,
    name: name,
  });

  return (
    <SaveAndCancelWrapper>
      <SaveCancelButtons
        onCancelClick={onCancelClick}
        isSaveButtonDisabled={!field}
        onSaveClick={onSaveClick}
      />
    </SaveAndCancelWrapper>
  );
};
