import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Hooks
import { useScreens } from "hooks";

// Components
import { SearchInput } from "components";

const FiltersWrapper = styled.div`
  display: flex;
  align-items: start;
  padding: 20px 15px;
  flex-direction: column;
  margin-bottom: 50px;
  ${ifProp(
    "tabletScreens",
    css`
      padding: 0;
      flex-direction: row;
      margin: 0 32px 24px;
      align-items: start;
    `
  )}
`;

export function SurveysSearch({ surveys, setSurveysList }) {
  const { tabletScreens } = useScreens();
  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = useCallback(() => {
    const searchedSurveys = surveys.filter((survey) =>
      survey.topic.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSurveysList(searchedSurveys);
  }, [searchTerm, setSurveysList, surveys]);

  useEffect(() => {
    if (!searchTerm) {
      setSurveysList(surveys);
    }
  }, [searchTerm, setSurveysList, surveys]);

  return (
    <FiltersWrapper tabletScreens={tabletScreens}>
      <SearchInput
        placeholder="Search for survey name"
        value={searchTerm}
        setValue={setSearchTerm}
        onSubmit={onSearch}
        clearButton
      />
    </FiltersWrapper>
  );
}

SurveysSearch.propTypes = {
  surveys: PropTypes.array,
  setSurveysList: PropTypes.func,
};
