// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import {
  getCategory as fetchCategory,
  sortSubcategory as updateOrder,
  deleteSubcategory as removeSubcategory,
} from "service";

// Actions
import {
  GET_CATEGORY,
  SORT_SUBCATEGORY,
  DELETE_SUBCATEGORY,
} from "constants/index";

const initialState = {
  category: null,
  error: null,
  loading: true,
};

export const getCategory = createAsyncThunk(
  GET_CATEGORY,
  async (categoryId, { rejectWithValue }) => {
    try {
      return await fetchCategory(categoryId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const sortSubcategory = createAsyncThunk(
  SORT_SUBCATEGORY,
  async ({ id, sortOrder, categoryId }, { rejectWithValue }) => {
    try {
      await updateOrder(id, sortOrder);
      return await fetchCategory(categoryId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteSubcategory = createAsyncThunk(
  DELETE_SUBCATEGORY,
  async ({ id, categoryId }, { rejectWithValue }) => {
    try {
      await removeSubcategory(id);
      return await fetchCategory(categoryId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const categoryReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getCategory.fulfilled, (state, action) => {
      state.category = action.payload;
      state.loading = false;
    })
    .addCase(getCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(sortSubcategory.fulfilled, (state, action) => {
      state.loading = false;
      state.category = action.payload;
    })
    .addCase(sortSubcategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(deleteSubcategory.fulfilled, (state, action) => {
      state.category = action.payload;
      state.loading = false;
    })
    .addCase(deleteSubcategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
