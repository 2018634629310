import { sleep } from "utils";
import { categoriesSeed } from ".";

const common = {
  description:
    "Organizational design, executive recruitment, compensation systems and board-building initiatives",
  thumbnail: "/images/placeholders/subcategory-placeholder-icon.svg",
  contactPerson: "Cristofer Curtis",
  lastEditDate: "May 12, 2021",
  editedBy: "Kaiya Rosser",
};

export const subcategories = [
  {
    id: 1,
    name: "GA Pitchdeck Materials",
    slug: "ga-pitchdeck-materials",
    categoryId: 2,
    categoryName: "Growth Acceleration",
    isVisible: true,
    ...common,
  },
  {
    id: 2,
    name: "Strategic Due Diligence",
    slug: "strategic-due-diligence",
    categoryId: 2,
    categoryName: "Growth Acceleration",
    isVisible: false,
    ...common,
  },
  {
    id: 3,
    name: "Pricing",
    slug: "pricing",
    categoryId: 2,
    categoryName: "Growth Acceleration",
    isVisible: true,
    ...common,
  },
  {
    id: 4,
    name: "Digital Marketing",
    slug: "digital-marketing",
    categoryId: 2,
    categoryName: "Growth Acceleration",
    isVisible: true,
    ...common,
  },
  {
    id: 5,
    name: "GA Network",
    slug: "ga-network",
    categoryId: 2,
    categoryName: "Growth Acceleration",
    isVisible: true,
    ...common,
  },
  {
    id: 6,
    name: "Second Measure",
    slug: "second-measure",
    categoryId: 3,
    categoryName: "Data",
    isVisible: true,
    ...common,
  },
  {
    id: 7,
    name: "AppAnnie",
    slug: "appannie",
    categoryId: 3,
    categoryName: "Data",
    isVisible: false,
    ...common,
  },
  {
    id: 8,
    name: "SimilarWeb",
    slug: "similarweb",
    categoryId: 3,
    categoryName: "Data",
    isVisible: true,
    ...common,
  },
  {
    id: 9,
    name: "Linkedin FTEs",
    slug: "linkedin-ftes",
    categoryId: 3,
    categoryName: "Data",
    isVisible: true,
    ...common,
  },
  {
    id: 10,
    name: "Shopify",
    slug: "shopify",
    categoryId: 3,
    categoryName: "Data",
    isVisible: true,
    ...common,
  },
  {
    id: 11,
    name: "Expert Calls",
    slug: "expert-calls",
    categoryId: 4,
    categoryName: "Research",
    isVisible: true,
    ...common,
  },
  {
    id: 12,
    name: "Surveys",
    slug: "surveys",
    categoryId: 4,
    categoryName: "Research",
    isVisible: true,
    ...common,
  },
  {
    id: 13,
    name: "Thematic Research",
    slug: "thematic-research",
    categoryId: 4,
    categoryName: "Research",
    isVisible: false,
    ...common,
  },
  {
    id: 14,
    name: "Research Reports",
    slug: "research-reports",
    categoryId: 4,
    categoryName: "Research",
    isVisible: true,
    ...common,
  },
  {
    id: 15,
    name: "Portfolio Human Capital",
    slug: "portfolio-human-capital",
    categoryId: 5,
    categoryName: "Portfolio Human Capital",
    isVisible: true,
    ...common,
  },
  {
    id: 16,
    name: "Transaction Resources",
    slug: "transaction-resources",
    categoryId: 7,
    categoryName: "Transaction Resources",
    isVisible: true,
    ...common,
  },
  {
    id: 17,
    name: "Knowledge Map",
    slug: "knowledge-map",
    categoryId: 8,
    categoryName: "Portfolio Management",
    isVisible: true,
    ...common,
  },
  {
    id: 18,
    name: "Business Models",
    slug: "business-models",
    categoryId: 8,
    categoryName: "Portfolio Management",
    isVisible: true,
    ...common,
  },
  {
    id: 19,
    name: "Portfolio Companies",
    slug: "portfolio-companies",
    categoryId: 8,
    categoryName: "Portfolio Management",
    isVisible: true,
    ...common,
  },
];

export const fetchSubcategories = async (categoryId = null) => {
  await sleep(1000);
  if (categoryId)
    return subcategories.filter(
      (subcategory) => subcategory.categoryId === categoryId
    );
  return subcategories;
};

const getMappedMenuItems = () => {
  const result = [...categoriesSeed];
  subcategories.forEach((subcategory) => {
    result.forEach((category) => {
      if (category.id === subcategory.categoryId) {
        category.subcategories.push(subcategory);
      }
    });
  });
  return result;
};

export const mappedMenuItems = getMappedMenuItems();
