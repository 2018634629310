import { useEffect, useState } from "react";

// Service
import { getAuthRedirectUrl } from "service";

// Utils
import { setValue, getValue } from "utils";

export async function handleLoginWithRedirect() {
  try {
    const {
      redirect: redirectUrl,
      nonce,
      realm,
      state,
    } = await getAuthRedirectUrl();

    setValue("nonce", nonce);
    setValue("realm", realm);
    setValue("state", state);

    return window.open(redirectUrl, "_parent");
  } catch (e) {
    localStorage.clear();
    window.open("/session-expired", "_parent");
    // setError(e);
  }
}
export function useHandleLoginWithRedirect() {
  const [error, setError] = useState(null);
  const token = getValue("access_token");

  useEffect(() => {
    if (!token) {
      (async () => {
        await handleLoginWithRedirect();
      })();
    } else {
      window.open("/", "_parent");
    }
  }, [token]);

  return { error };
}
