// Axios config
import apiConfig from "./config";

// Constants
import {
  DOCUMENT_PREVIEW,
  GA_NETWORK,
  KNOWLEDGE_MAP,
  RESULTS,
  SEARCH,
  SUBCATEGORY,
} from "constants/routeSegments";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;

const api = apiConfig(baseURL);

const getSlug = (title, subcategoryId) => ({
  "knowledge-portal-introducers": `/${GA_NETWORK}/${title}`,
  "knowledge-portal-expertise": `/${KNOWLEDGE_MAP}/${title}`,
  "knowledge-portal": `/${SEARCH}/${RESULTS}/${SUBCATEGORY}/${title}/${subcategoryId}`,
});

function getCategory(index) {
  switch (index) {
    case "knowledge-portal-introducers":
      return "GA Network";
    case "knowledge-portal-expertise":
      return "Knowledge Map";
    default:
      return index;
  }
}

export async function getAutocompleteResult(query) {
  const { data } = await api.get(`/compass/autocompletequery?query=${query}`);

  const _searchResults = data.aggregations.indexes.buckets
    .map(
      ({
        hits: {
          hits: { hits, total },
        },
      }) => ({ ...hits[0], total })
    )
    .filter(({ _index }) => _index !== "knowledge-portal")
    .map(({ _index: index, _id: id, total }) => {
      const title = query;

      const link = `${getSlug(title)[index]}`;

      return {
        id,
        title,
        link,
        category: getCategory(index),
        count: total.value,
      };
    });

  const subcategorySearchResults =
    data.aggregations.subcategories.subs.subs.buckets.map(
      ({
        doc_count,
        key,
        folder_ids: {
          ids: {
            hits: { hits },
          },
        },
      }) => ({
        id: key,
        title: query,
        count: doc_count,
        link: `${
          getSlug(query, hits[0]._source.FOLDERIDS[2])["knowledge-portal"]
        }`,
        category: key,
      })
    );

  const searchResults = [..._searchResults, ...subcategorySearchResults];

  const knowledgePortalData =
    data.aggregations.indexes.buckets.find(
      ({ key }) => key === "knowledge-portal"
    ) || [];

  const suggestions = knowledgePortalData?.hits?.hits?.hits?.map(
    ({ _id: id, _source: { source } }) => {
      return {
        id,
        title: source?.name,
        category: "Suggestions",
        link: `/${DOCUMENT_PREVIEW}/${source?.id}`,
      };
    }
  );

  return [searchResults, suggestions];
}
