// Utils
import styled from "styled-components";

// Components
import { Divider as MUIDivider } from "@material-ui/core";

export const PageHeaderDivider = styled(MUIDivider)`
  ${({ theme }) => `
    margin: 24px 0 12px 0;
    background: ${theme.palette.grayMiddle};
  `}
`;
