import { useMediaQuery } from "@material-ui/core";

// Theme
import theme from "theme/theme";

export const useMinWidth = (width) =>
  useMediaQuery(`(min-width:${theme.breakpoints.values[width]}px)`, {
    noSsr: true,
    defaultMatches: true,
  });
