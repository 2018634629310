// Utils
import styled, { css } from "styled-components";
import { ifNotProp } from "styled-tools";

// Components
import { Icon } from "components/Icon";

// Constants
import { LOGO } from "constants/index";

const Wrapper = styled.div`
  width: 105px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => css`
    width: 130px;
    height: 25px;

    svg {
      width: 130px;
    }

    circle {
      fill: transparent;
    }

    ${ifNotProp(
      { variant: "light" },
      css`
        path {
          fill: ${theme.palette.blueMain};
        }
      `
    )}
  `}
`;

export function PageHeaderLogo({ type }) {
  return (
    <Wrapper>
      <StyledIcon type={LOGO} variant={type} />
    </Wrapper>
  );
}
