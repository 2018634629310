import React from "react";
import PropTypes from "prop-types";

// Components
import { MenuItem } from "@material-ui/core";
import { Select, Typography } from "components";
import { useSurveyCategories } from "hooks";
import { Controller } from "react-hook-form";

const renderValue = (selected) => {
  if (!selected) {
    return (
      <Typography variant="body1" color="darkGray">
        Select Category
      </Typography>
    );
  }

  return selected?.name;
};

export const CategoriesSelect = ({ control }) => {
  const { surveyCategories } = useSurveyCategories();

  return (
    <Controller
      control={control}
      name="category"
      rules={{ required: true }}
      render={({
        field: { onChange, value: fieldValue },
        fieldState,
      }) => {
        return (
          <Select
            displayEmpty
            renderValue={(selected) => renderValue(selected)}
            value={fieldValue ?? ""}
            onChange={onChange}
            error={!!fieldState?.error}
          >
            {surveyCategories?.map((option) => {
              return (
                <MenuItem key={option.name} value={option}>
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        );
      }}
    />
  );
};

CategoriesSelect.propTypes = {
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
};
