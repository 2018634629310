import React, { Suspense } from "react";
import PropTypes from "prop-types";

// Utils
import { withRouter } from "react-router";
import { useSelector } from "react-redux";

// Components
import { Route, Redirect } from "react-router-dom";
import { Loader } from "components";

// Hooks
import { useRefreshToken, useUserData } from "hooks";

import { decrypt } from "../../utils/encrypt";
import { getValue } from "../../utils/localStorage";

const composeLayouts = (layouts) => (Component, componentProps) =>
  layouts.reduceRight(
    (ComponentAcc, { layout: Layout, layoutProps }, i) =>
      () =>
        (
          <Layout {...layoutProps}>
            <ComponentAcc
              {...(i === layouts.length - 1 && { ...componentProps })}
            />
          </Layout>
        ),
    Component
  );

function PrivateRouteComponent({
  component: Component,
  layouts,
  isAdminRoute,
  ...rest
}) {
  const { loading } = useUserData();
  useRefreshToken();

  const { isAuthenticated } = useSelector(
    ({ authentication }) => authentication
  );

  const isAdminValue = getValue("isAdmin");

  const isAdmin = isAdminValue
    ? decrypt(getValue("isAdmin")) === "true"
    : false;

  if (loading) {
    return <Loader fullPage />;
  }

  function ComponentWithSuspense({ ...props }) {
    return (
      <Suspense fallback={<Loader fullPage />}>
        <Component {...props} />
      </Suspense>
    );
  }

  const ComposedLayouts = composeLayouts(layouts)(ComponentWithSuspense, {
    ...rest,
  });

  const AdminRoute = ({ location }) =>
    isAdmin ? (
      <ComposedLayouts />
    ) : (
      <Redirect
        to={{
          pathname: "/unauthorized",
          state: { from: location },
        }}
      />
    );

  const AuthenticatedRoute = ({ location }) =>
    isAdminRoute ? <AdminRoute location={location} /> : <ComposedLayouts />;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <AuthenticatedRoute {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

PrivateRouteComponent.propTypes = {
  component: PropTypes.elementType.isRequired,
  layouts: PropTypes.arrayOf(
    PropTypes.shape({
      layout: PropTypes.elementType,
      layoutProps: PropTypes.object,
    })
  ),
};

PrivateRouteComponent.defaultProps = {
  layouts: [],
};

export const PrivateRoute = withRouter(PrivateRouteComponent);
