import {
  PDF,
  XLS,
  XLSX,
  PPT,
  PPTX,
  HTML,
  TXT,
  CSV,
  DOC,
  DOCX,
} from "constants/icons";

export const getIconType = (fileExt) => {
  switch (fileExt) {
    case "pdf":
      return PDF;
    case "xls":
      return XLS;
    case "xlsx":
      return XLSX;
    case "ppt":
      return PPT;
    case "pptx":
      return PPTX;
    case "html":
      return HTML;
    case "txt":
      return TXT;
    case "csv":
      return CSV;
    case "doc":
      return DOC;
    case "docx":
      return DOCX;
    default:
      return DOC;
  }
};
