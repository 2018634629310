import React, { forwardRef } from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Icon as MUIIcon } from "@material-ui/core";

// Constants
import {
  LOGO_LIGHT,
  LOGO,
  ARROW_UP,
  LOGOUT,
  PROFILE,
  APPS,
  MENU,
  ADMIN,
  SEARCH,
  SEARCH_MUI,
  UBER,
  UBEREZ,
  UBERITO,
  CLOSE,
  HELP,
  LOGOUT_LARGE,
  INFO,
  TOOLTIP,
  ADD,
  CHECKBOX_OUTLINE_SHARP,
  CHECKBOX_SHARP,
  DRAG_DROP,
  DELETE,
  DELETE_OUTLINE,
  VISIBILITY,
  VISIBILITY_OFF,
  EDIT,
  STEPPER_COMPLITED,
  CHECK_CIRCLE,
  ERROR,
  STAR_BORDER,
  STAR,
  FIRST_PAGE,
  LAST_PAGE,
  KEYBOARD_ARROW_LEFT,
  KEYBOARD_ARROW_RIGHT,
  ARROW_DOWN,
  BACK_ARROW,
  DOC,
  HTML,
  PDF,
  PPT,
  XLS,
  CSV,
  TXT,
  PPTX,
  XLSX,
  DOCX,
  FOLDER,
  FOLDER1,
  DEPARTMENT,
  LOCATION,
  ASSIGNMENT,
  OFFICE,
  MOBILE,
  EXPORT,
  KEYBOARD_ARROW_DOWN,
  FILTER,
  LOOP,
  NOTE,
} from "constants/index";

// Icons
import { ReactComponent as LogoLight } from "assets/icons/logo-light.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as Profile } from "assets/icons/profile.svg";
import { ReactComponent as Admin } from "assets/icons/admin.svg";
import { ReactComponent as Search } from "assets/icons/search-icon.svg";
import { ReactComponent as Uber } from "assets/icons/uber.svg";
import { ReactComponent as Uberez } from "assets/icons/uberez.svg";
import { ReactComponent as Uberito } from "assets/icons/uberito.svg";
import { ReactComponent as Help } from "assets/icons/help.svg";
import { ReactComponent as LogoutLarge } from "assets/icons/logout-large.svg";
import { ReactComponent as Tooltip } from "assets/icons/tooltip.svg";
import { ReactComponent as StepperComplited } from "assets/icons/stepper_complited.svg";
import { ReactComponent as BackArrow } from "assets/icons/back-arrow.svg";
import { ReactComponent as Doc } from "assets/icons/doc.svg";
import { ReactComponent as Html } from "assets/icons/html.svg";
import { ReactComponent as Pdf } from "assets/icons/pdf.svg";
import { ReactComponent as Ppt } from "assets/icons/ppt.svg";
import { ReactComponent as Xls } from "assets/icons/xls.svg";
import { ReactComponent as Csv } from "assets/icons/csv.svg";
import { ReactComponent as Txt } from "assets/icons/txt.svg";
import { ReactComponent as Xlsx } from "assets/icons/xlsx.svg";
import { ReactComponent as Pptx } from "assets/icons/pptx.svg";
import { ReactComponent as Docx } from "assets/icons/docx.svg";
import { ReactComponent as Folder } from "assets/icons/folder.svg";
import { ReactComponent as Folder1 } from "assets/icons/folder-icon.svg";
import { ReactComponent as Department } from "assets/icons/department.svg";
import { ReactComponent as Mobile } from "assets/icons/mobile.svg";
import { ReactComponent as Export } from "assets/icons/export.svg";
// import { ReactComponent as Filter } from "assets/icons/filter.svg";
import { ReactComponent as Note } from "assets/icons/note.svg";
import {
  Apps,
  Menu,
  Search as MUISearch,
  Close,
  Info,
  Add,
  CheckBoxOutlineBlankSharp,
  CheckBoxSharp,
  DragIndicatorSharp,
  Visibility,
  VisibilityOff,
  EditOutlined,
  DeleteOutline,
  CheckCircle,
  Error as ErrorIcon,
  StarBorder,
  Star,
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  ArrowDownward,
  LocationOn,
  AssignmentInd,
  Business,
  KeyboardArrowDown,
  FilterList,
  Loop,
} from "@material-ui/icons";
const icons = {
  [LOGO_LIGHT]: LogoLight,
  [LOGO]: Logo,
  [ARROW_UP]: ArrowUp,
  [LOGOUT]: Logout,
  [LOGOUT_LARGE]: LogoutLarge,
  [PROFILE]: Profile,
  [SEARCH]: Search,
  [SEARCH_MUI]: MUISearch,
  [APPS]: Apps,
  [MENU]: Menu,
  [ADMIN]: Admin,
  [UBER]: Uber,
  [UBEREZ]: Uberez,
  [UBERITO]: Uberito,
  [CLOSE]: Close,
  [HELP]: Help,
  [INFO]: Info,
  [TOOLTIP]: Tooltip,
  [ADD]: Add,
  [CHECKBOX_OUTLINE_SHARP]: CheckBoxOutlineBlankSharp,
  [CHECKBOX_SHARP]: CheckBoxSharp,
  [DRAG_DROP]: DragIndicatorSharp,
  [DELETE]: DeleteOutline,
  [VISIBILITY]: Visibility,
  [VISIBILITY_OFF]: VisibilityOff,
  [EDIT]: EditOutlined,
  [DELETE_OUTLINE]: DeleteOutline,
  [STEPPER_COMPLITED]: StepperComplited,
  [CHECK_CIRCLE]: CheckCircle,
  [ERROR]: ErrorIcon,
  [STAR_BORDER]: StarBorder,
  [STAR]: Star,
  [FIRST_PAGE]: FirstPage,
  [LAST_PAGE]: LastPage,
  [KEYBOARD_ARROW_LEFT]: KeyboardArrowLeft,
  [KEYBOARD_ARROW_RIGHT]: KeyboardArrowRight,
  [KEYBOARD_ARROW_DOWN]: KeyboardArrowDown,
  [ARROW_DOWN]: ArrowDownward,
  [BACK_ARROW]: BackArrow,
  [DOC]: Doc,
  [HTML]: Html,
  [XLS]: Xls,
  [PDF]: Pdf,
  [PPT]: Ppt,
  [CSV]: Csv,
  [TXT]: Txt,
  [PPTX]: Pptx,
  [XLSX]: Xlsx,
  [DOCX]: Docx,
  [FOLDER]: Folder,
  [FOLDER1]: Folder1,
  [DEPARTMENT]: Department,
  [LOCATION]: LocationOn,
  [ASSIGNMENT]: AssignmentInd,
  [OFFICE]: Business,
  [MOBILE]: Mobile,
  [EXPORT]: Export,
  [FILTER]: FilterList,
  [LOOP]: Loop,
  [NOTE]: Note,
};

const Icon = styled(
  forwardRef(({ color, width, ...rest }, ref) => (
    <MUIIcon ref={ref} {...rest} />
  ))
)`
  ${({ theme, color, width }) => `
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${width};

    svg {
      fill: ${theme.palette[color]};
      width: ${width}
    }
  `}
`;

export const CustomIcon = forwardRef(
  ({ type, color, className, width, ...iconProps }, ref) => {
    const IconType = icons[type];

    if (IconType) {
      return (
        <Icon ref={ref} color={color} className={className} width={width}>
          <IconType {...iconProps} />
        </Icon>
      );
    }

    return null;
  }
);

CustomIcon.propTypes = {
  type: PropTypes.string.isRequired,
  color: PropTypes.string,
  width: PropTypes.string,
};

CustomIcon.defaultProps = {
  color: "black",
  width: "24px",
};
