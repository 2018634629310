export const getSortedCompanies = (employeeCompanies, sort) => {
  switch (sort) {
    case "name_asc":
      return employeeCompanies.sort((a, b) =>
        a.name?.companyName?.toLowerCase() > b.name?.companyName?.toLowerCase()
          ? 1
          : b.name?.companyName?.toLowerCase() >
            a.name?.companyName?.toLowerCase()
          ? -1
          : 0
      );
    case "name_desc":
      return employeeCompanies.sort((a, b) =>
        a.name?.companyName?.toLowerCase() < b.name?.companyName?.toLowerCase()
          ? 1
          : b.name?.companyName?.toLowerCase() <
            a.name?.companyName?.toLowerCase()
          ? -1
          : 0
      );
    case "acquisition_date_asc":
      return employeeCompanies.sort(
        (a, b) =>
          (new Date(a.acquisitionDate).getTime() || -Infinity) -
          (new Date(b.acquisitionDate).getTime() || -Infinity)
      );

    case "acquisition_date_desc":
      return employeeCompanies.sort(
        (a, b) =>
          (new Date(b.acquisitionDate).getTime() || -Infinity) -
          (new Date(a.acquisitionDate).getTime() || -Infinity)
      );

    case "exit_date_asc":
      return employeeCompanies.sort(
        (a, b) =>
          (new Date(a.exitDate).getTime() || -Infinity) -
          (new Date(b.exitDate).getTime() || -Infinity)
      );

    case "exit_date_desc":
      return employeeCompanies.sort(
        (a, b) =>
          (new Date(b.exitDate).getTime() || -Infinity) -
          (new Date(a.exitDate).getTime() || -Infinity)
      );

    case "sector_asc":
      return employeeCompanies.sort((a, b) =>
        a.sector?.toLowerCase() > b.sector?.toLowerCase()
          ? 1
          : b.sector?.toLowerCase() > a.sector?.toLowerCase()
          ? -1
          : 0
      );
    case "sector_desc":
      return employeeCompanies.sort((a, b) =>
        a.sector?.toLowerCase() < b.sector?.toLowerCase()
          ? 1
          : b.sector?.toLowerCase() < a.sector?.toLowerCase()
          ? -1
          : 0
      );
    case "geography_asc":
      return employeeCompanies.sort((a, b) =>
        a.geography?.toLowerCase() > b.geography?.toLowerCase()
          ? 1
          : b.geography?.toLowerCase() > a.geography?.toLowerCase()
          ? -1
          : 0
      );
    case "geography_desc":
      return employeeCompanies.sort((a, b) =>
        a.geography?.toLowerCase() < b.geography?.toLowerCase()
          ? 1
          : b.geography?.toLowerCase() < a.geography?.toLowerCase()
          ? -1
          : 0
      );

    default:
      return employeeCompanies.sort((a, b) =>
        a.name?.companyName?.toLowerCase() > b.name?.companyName?.toLowerCase()
          ? 1
          : b.name?.companyName?.toLowerCase() >
            a.name?.companyName?.toLowerCase()
          ? -1
          : 0
      );
  }
};
