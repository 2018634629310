import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Utils
import styled from "styled-components";

// Styles
import "react-dropzone-uploader/dist/styles.css";

// Components
import { PreviewIcon } from "./PreviewIcon";
import { Tooltip } from "./Tooltip";
import { Layout } from "./Layout";
import { Input } from "./Input";
import Dropzone from "react-dropzone-uploader";

const DropzoneWrapper = styled.div`
  width: fit-content;
`;

export function DropzoneComponent({
  field: { onChange },
  handleSetError,
  initialFiles,
  error,
  maxSizeBytes,
  accept,
  type,
	previewUrl,
}) {
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    if (initialFiles) {
      (async () => {
        try {
          const res = await fetch(initialFiles);
          const buffer = await res.arrayBuffer();
          const _file = new File([buffer], buffer, {
            type: type === "icon" ? "image/svg+xml" : "image/jpeg",
          });
          setFile(_file);
        } catch (e) {
          console.log(e);
        }
      })();
    }
  }, [initialFiles, type]);

  return (
    <Tooltip open={Boolean(error)} errorMessage={error}>
      <DropzoneWrapper>
        <Dropzone
          accept={accept}
          multiple={false}
          maxFiles={1}
          initialFiles={file ? [file] : null}
          maxSizeBytes={maxSizeBytes}
          onChangeStatus={(_, status, allFiles) => {
            switch (status) {
              case "rejected_file_type":
                if (type === "icon") {
                  allFiles.forEach((f) => f.remove());
                  setFile(null);
                  onChange(null);
                  handleSetError(
                    "Wrong file format. Please, upload another file"
                  );
                }
                break;
              case "error_file_size":
                if (type === "image") {
                  allFiles.forEach((f) => f.remove());
                  setFile(null);
                  onChange(null);
                  handleSetError("File too big. Please, upload another file");
                }
                break;
              case "error_validation":
                if (type === "icon") {
                  allFiles.forEach((f) => f.remove());
                  setFile(null);
                  onChange(null);
                  handleSetError(
                    "Wrong file ratio. Please, upload another file"
                  );
                }
                break;
              default:
                if (status === "done") {
                  setTimeout(() => {
                    if (allFiles?.length) {
                      handleSetError(null);
                    }
                    onChange([...allFiles]);
                  }, 0);
                }
                break;
            }
          }}
          validate={(fileWithMeta) =>
            type === "icon" &&
            fileWithMeta?.meta?.height !== fileWithMeta?.meta?.width
          }
          PreviewComponent={({
            fileWithMeta: {
              meta: { status, previewUrl: uploadedPreviewUrl },
            },
          }) => (
            <PreviewIcon
              src={status === "error_validation" ? null : uploadedPreviewUrl}
              error={status === "error_validation" || Boolean(error)}
              type={type}
            />
          )}
          LayoutComponent={({ ...layoutProps }) => (
            <Layout
              {...layoutProps}
              previewComponent={PreviewIcon}
              previewError={Boolean(error)}
              type={type}
							src={previewUrl ? previewUrl : null}
            />
          )}
          InputComponent={(props) => <Input {...props} type={type} />}
        />
      </DropzoneWrapper>
    </Tooltip>
  );
}

DropzoneComponent.propTypes = {
  handleSetError: PropTypes.func,
  initialFiles: PropTypes.string,
  error: PropTypes.string,
  maxSizeBytes: PropTypes.number,
  accept: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
