import { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { useParams } from "react-router";

// Components
import {
  BackButton,
  ScrollableCategoriesMenu,
  ScrollableSubcategoriesMenu,
} from "components";

// Hooks
import { useScreens, useSubcategories } from "hooks";
import { useSubcategory } from "hooks/api/useSubcategory";

const MenuWrapper = styled.div`
  ${ifProp(
    "tabletScreens",
    css`
      padding: 20px 32px;
    `
  )}
`;

const BackButtonWrapper = styled.div`
  margin-left: -8px;
  margin-bottom: 20px;
`;

export function SubcategoryPageLayout({ children }) {
  const { tabletScreens } = useScreens();
  const params = useParams();

  const { subcategories, loading } = useSubcategories(null, false, true);

  const { subcategory: loadedSubcategory } = useSubcategory(params?.subcategory);

  const subcategory = useMemo(() => {
    let subcategoryId = params.subcategory;
    if (!params?.subcategory) {
      subcategoryId = loadedSubcategory?.id;
    }
    return subcategories?.find((sub) => sub.id === subcategoryId) ?? null;
  }, [params.subcategory, subcategories, loadedSubcategory]);

  const [selectedCategory, setSelectedCategory] = useState(
    subcategory?.parentBoxId
  );

  const [categorySubcategories, setCategorySubcategories] = useState([]);

  const onSelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  useEffect(() => {
    if (!subcategory) return;
    setSelectedCategory(subcategory?.parentBoxId);
  }, [subcategory]);

  useEffect(() => {
    if (selectedCategory && subcategories) {
      setCategorySubcategories(
        subcategories?.filter(
          (subCategory) => subCategory.parentBoxId === selectedCategory
        ) ?? []
      );
    }
  }, [selectedCategory, subcategories]);

  return (
    <>
      <MenuWrapper tabletScreens={tabletScreens}>
        {tabletScreens && (
          <BackButtonWrapper>
            <BackButton />
          </BackButtonWrapper>
        )}
        {tabletScreens && subcategory?.parentBoxId && (
          <ScrollableCategoriesMenu
            onSelect={onSelect}
            preselectedCategory={selectedCategory}
            loading={loading}
          />
        )}
        {tabletScreens && subcategory?.parentBoxId && (
          <ScrollableSubcategoriesMenu
            preselectedSubcategory={subcategory?.id}
            subcategories={categorySubcategories}
            loading={loading}
          />
        )}
      </MenuWrapper>
      {children}
    </>
  );
}
