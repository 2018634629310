import PropTypes from "prop-types";
import { memo } from "react";

// Utils
import { useMinWidth } from "hooks";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { PageHeader } from "./PageHeader/PageHeader";
import { Loader } from "components/Loader";

// Hooks
import { useOktaApps, useUserData } from "hooks";

const StyledPageLayout = styled.div`
  min-height: 100vh;
`;

const Wrapper = styled.div`
  padding-top: 60px;

  ${ifProp(
    "tabletScreens",
    css`
      padding-top: 76px;
    `
  )}
`;

const WrapperMemo = memo(Wrapper);

export function PageLayout({ children, title }) {
  const tabletScreens = useMinWidth("tablet");
  const { user } = useUserData();
  const { loading, error } = useOktaApps(user.username);

  if (loading) {
    return <Loader fullPage />;
  }

  if (error) {
    // return <Redirect to="/error" />;
  }

  return (
    <StyledPageLayout>
      <PageHeader title={title} />
      <WrapperMemo tabletScreens={tabletScreens}>{children}</WrapperMemo>
    </StyledPageLayout>
  );
}

PageLayout.propTypes = {
  title: PropTypes.string,
};
