import axios from "axios";

// Service
import { refreshToken as refreshTokenService } from "service/authentication";

// Utils
import { getValue, setValue } from "utils";

import amplitude from "amplitude-js";

const config = {
  validateStatus: (status) => status >= 200 && status < 300,
  headers: {
    Accept: "*",
  },
};

const api = axios.create(config);

const apiConfig = (baseURL) => {
  api.interceptors.request.use(
    (config) => {
      const token = getValue("access_token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return { ...config, baseURL };
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const refreshToken = getValue("refresh_token");

      amplitude.getInstance().logEvent("Error", { error });

      if (
        error.response?.status === 401 &&
        !error.config?._retry &&
        refreshToken
      ) {
        error.config._retry = true;

        try {
          amplitude.getInstance().logEvent("Refresh token", { loading: true });
          const { access_token, expires_in, refresh_token } =
            await refreshTokenService(refreshToken);

          amplitude.getInstance().logEvent("Refresh token", { loading: false });

          console.log("fetch refresh token");

          setValue("access_token", access_token);
          setValue("expires_in", expires_in);
          setValue("refresh_token", refresh_token);

          error.config.headers.Authorization = `Bearer ${access_token}`;

          return axios.request(error.config);
        } catch (_error) {
          if (
            _error?.response?.status === 400 &&
            _error?.response?.data?.error === "invalid_grant"
          ) {
            localStorage.clear();
            return window.open("/login", "_parent");
          }

          return Promise.reject(_error);
        }
      }
      return Promise.reject(error);
    }
  );

  return api;
};

export default apiConfig;
