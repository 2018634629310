import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { Accordion } from 'components';
import { DrawerMenuItem } from './DrawerMenuItem';

// Contexts
import { useDrawer } from 'contexts';

// Constants
import { SUBCATEGORIES } from 'constants/index';

const StyledDrawerMenuItem = styled(DrawerMenuItem)`
  padding: 12px 0 12px 30px;
  cursor: pointer;
`;

export const DrawerContent = ({ data }) => {
  const { toggleDrawer, setSelectedMenuItem, selectedMenuItem } = useDrawer();
  const history = useHistory();

  const handleClick = (id) => {
    history.push(`/${SUBCATEGORIES}/${id}`);
    setSelectedMenuItem(id);
    toggleDrawer(false);
  };

  useEffect(() => {
    data.forEach((category) => {
      category.subCategories.forEach((subcategory) => {
        if (
          window.location.pathname === `/${SUBCATEGORIES}/${subcategory.id}`
        ) {
          setSelectedMenuItem(subcategory.id);
        }
      });
    });
  }, [setSelectedMenuItem, data]);

  return (
    <>
      {data.map(
        (category) =>
          category.visible && (
            <Accordion
              title={category.name}
              key={category.name}
              disabled={!category.subCategories.length}
              expanded={category.subCategories.some(
                (subcategory) => subcategory.id === selectedMenuItem
              )}
            >
              {category.subCategories.map((subcategory, i) =>
                subcategory.visible ? (
                  <StyledDrawerMenuItem
                    typographyVariant={
                      selectedMenuItem === subcategory.id ? 'h6' : 'body1'
                    }
                    typographyColor={
                      selectedMenuItem === subcategory.id ? 'white' : 'black'
                    }
                    selected={selectedMenuItem === subcategory.id}
                    title={subcategory.name}
                    onClick={() => handleClick(subcategory.id)}
                    key={i}
                  />
                ) : null
              )}
            </Accordion>
          )
      )}
    </>
  );
};
