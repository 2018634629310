import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { AvatarWithName } from "components";
import { Typography } from "components/Typography";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MembersListContainer = styled.div`
  display: flex;
`;

const MemberInfoWrapper = styled.div`
  margin-right: 44px;
`;

const CirclesContainer = styled.div`
  position: relative;
  width: 56px;
  height: 32px;
  margin-right: 8px;
`;

const Circle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  top: 0;
`;

const LightGrayCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.palette.grayMiddle};
  opacity: 0.3;
  left: 0;
`;
const LightBlueCircle = styled(Circle)`
  background-color: #809bc8;
  left: 14px;
`;
const BlueCircle = styled(Circle)`
  background-color: ${({ theme }) => theme.palette.blueAccent};
  left: 28px;
`;

const NumberOfRestContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const MembersList = ({ membersToShow, numberOfRest }) => {
  return (
    <Container>
      <MembersListContainer>
        {membersToShow?.map((member) => {
          return (
            <MemberInfoWrapper key={member.id}>
              <AvatarWithName
                image={member.image}
                name={member.name}
                link=""
              />
            </MemberInfoWrapper>
          );
        })}
      </MembersListContainer>
      {!!numberOfRest && (
        <NumberOfRestContainer>
          <CirclesContainer>
            <LightGrayCircle />
            <LightBlueCircle />
            <BlueCircle />
          </CirclesContainer>
          <Typography variant="h6" color="darkGray">
            +{numberOfRest}
          </Typography>
        </NumberOfRestContainer>
      )}
    </Container>
  );
};

MembersList.propTypes = {
  membersToShow: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  numberOfRest: PropTypes.number,
};
