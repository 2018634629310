import { useState, useCallback } from "react";

export function useExpandTableRow() {
  const [expandedRow, setExpandedRow] = useState("");

  const onExpand = useCallback(
    (id) => (expandedRow === id ? setExpandedRow("") : setExpandedRow(id)),
    [expandedRow]
  );

  const sliceExpandedRowItems = useCallback(
    ({ row, rowItems }) =>
      expandedRow === row ? rowItems : rowItems.slice(0, 3),
    [expandedRow]
  );

  return { expandedRow, onExpand, sliceExpandedRowItems };
}
