import { useParams, useHistory } from "react-router-dom";

// Components
import { Button, Typography } from "components";

import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { useScreens } from "hooks";

// Constants
import { CREATE, SURVEYS } from "constants/index.js";

const MainWrapper = styled.div`
  padding: 32px 15px;

  ${ifProp(
    "tabletScreens",
    css`
      padding: 32px 30px;
    `
  )}
  ${ifProp(
    "laptopScreens",
    css`
      padding: 32px 40px;
    `
  )}
`;

export const ButtonWrapper = styled.div`
  width: 150px;
  margin-bottom: 20px;
`;

export const SurveysCreateLayout = ({ children }) => {
  const { tabletScreens, laptopScreens } = useScreens();
  const params = useParams();

  const { push } = useHistory();

  const onViewSurveysList = () => {
    push(`/${SURVEYS}`);
  };

  return (
    <MainWrapper tabletScreens={tabletScreens} laptopScreens={laptopScreens}>
      <ButtonWrapper>
        <Button
          variant="secondary"
          text="View Survey List"
          isSmall
          onClick={onViewSurveysList}
        />
      </ButtonWrapper>
      <Typography variant="h1" color="black">
        {params.mode === CREATE ? "Create New Survey" : "Edit Survey"}
      </Typography>
      {children}
    </MainWrapper>
  );
};
