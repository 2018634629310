// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import {
  getAllSubcategories as fetchAllSubcategories,
  getSubcategoriesByCategoryId as fetchSubcategoriesByCategoryId,
  updateSubcategory as editSubcategory,
} from "service";

// Actions
import { GET_SUBCATEGORIES, UPDATE_SUBCATEGORY } from "constants/index";

const initialState = {
  subcategories: null,
  error: null,
  loading: true,
  isAllFetched: false,
};

export const getSubcategories = createAsyncThunk(
  GET_SUBCATEGORIES,
  async (categoryId, { rejectWithValue }) => {
    try {
      return !categoryId
        ? await fetchAllSubcategories(true)
        : await fetchSubcategoriesByCategoryId(categoryId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const updateSubcategory = createAsyncThunk(
  UPDATE_SUBCATEGORY,
  async ({ subcategoryId, ...subcategoryData }, { rejectWithValue }) => {
    try {
      const formDataObject = new FormData();
      for (const property in subcategoryData) {
        formDataObject.append(property, subcategoryData[property]);
      }
      return await editSubcategory(subcategoryId, formDataObject);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const subcategoriesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getSubcategories.pending, (state) => {
      state.loading = true;
    })
    .addCase(getSubcategories.fulfilled, (state, action) => {
      state.subcategories = action.payload;
      state.loading = false;
      state.isAllFetched = action?.meta?.arg === null;
    })
    .addCase(getSubcategories.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(updateSubcategory.fulfilled, (state, action) => {
      const index = state.subcategories.findIndex(
        ({ id }) => id === action.payload.id
      );

      state.subcategories[index] = action.payload;
    })
);
