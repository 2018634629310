import React from "react";
import PropTypes from "prop-types";

// Components
import { ThemeProvider as MUIThemeProvider } from "@material-ui/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";

// Themes
import theme from "./theme";

function GACompassThemeProvider({ theme, children }) {
  return (
    <MUIThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </StyledThemeProvider>
    </MUIThemeProvider>
  );
}

GACompassThemeProvider.propTypes = {
  children: PropTypes.object.isRequired,
  theme: PropTypes.object
};

GACompassThemeProvider.defaultProps = {
  theme: theme
};

export default GACompassThemeProvider;