import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { TextInput } from "components";
import { Answers } from "./Answers";
import { AnswerTypeSelect } from "./AnswerTypeSelect";

const QuestionDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px 40px 15px;
  width: 100%;
`;

const InputsContainer = styled.div`
  display: flex;
  margin-right: 50px;
  width: 60%;
  align-items: center;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  max-width: 418px;
  width: 100%;
`;

const SelectWrapper = styled.div`
  width: 220px;
`;

export const QuestionDetailsForm = ({ control }) => {
  return (
    <QuestionDetails>
      <InputsContainer>
        <InputWrapper>
          <TextInput
            inputProps={{
              label: "Question",
              autoFocus: true,
              placeholder: "Name",
            }}
            controllerProps={{
              control,
              name: "question",
              rules: {
                required: "Question is required",
              },
            }}
          />
        </InputWrapper>
        <SelectWrapper>
          <AnswerTypeSelect control={control} />
        </SelectWrapper>
      </InputsContainer>
      <Answers control={control} />
    </QuestionDetails>
  );
};

QuestionDetailsForm.propTypes = {
  question: PropTypes.object,
};
