// Constants
import {
  ASSIGN_TAG,
  DELETE_CATEGORY_WARNING,
  UNASSIGN_TAG,
  UNASSIGN_TAG_WARNING,
  DELETE_SUBCATEGORY_WARNING,
  DELETE_SUBCATEGORY_TAG_WARNING,
} from "constants/index";

export const errors = {
  toast: {
    // Subcategory tags
    [ASSIGN_TAG]: {
      message: "Something went wrong",
      subtitle: "Sub-Category tag was not assigned.",
      variant: "error",
    },
    [UNASSIGN_TAG]: {
      message: "Something went wrong",
      subtitle: "Sub-Category tag was not unassigned.",
      variant: "error",
    },
  },
  modal: {
    // Subcategory tags
    [UNASSIGN_TAG_WARNING]: ({ subCategoryName, id }) => ({
      title: `Deleting ${subCategoryName} Sub-category tag?`,
      subtitle: `Before deleting the sub-category tag, please delete the associated Box folders`,
      cancelText: "OK",
    }),
    // Category
    [DELETE_CATEGORY_WARNING]: ({ name, id }) => ({
      title: `Deleting ${name} Category`,
      subtitle: `Before deleting the Category, please delete the associated Box folder and all Sub-Categories folders`,
      cancelText: "Cancel",
      confirmText: "Open Box Folder",
      onConfirm: () => {
        window.open(`${process.env.REACT_APP_BOX_URL}/folder/${id}`);
      },
    }),
    // Subcategory
    [DELETE_SUBCATEGORY_WARNING]: ({ name, id }) => ({
      title: `Deleting ${name} Sub-Category`,
      subtitle: `Before deleting the Sub-Category, please delete the associated Box folder`,
      cancelText: "Cancel",
      confirmText: "Open Box Folder",
      onConfirm: () => {
        window.open(`${process.env.REACT_APP_BOX_URL}/folder/${id}`);
      },
    }),
    // Subcategory tag
    [DELETE_SUBCATEGORY_TAG_WARNING]: ({ name, id }) => ({
      title: `Deleting ${name} Sub-category tag?`,
      subtitle: `Before deleting the sub-category tag, please delete the associated Box folders`,
      cancelText: "OK",
    }),
  },
};
