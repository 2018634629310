import React from "react";

// Utils
import styled from "styled-components";

// Components
import { Select, Typography } from "components";
import { MenuItem } from "@material-ui/core";
import { Controller } from "react-hook-form";
import { useSurveyLookups } from "hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const Label = styled(Typography)`
  margin-bottom: 8px;
`;

const renderValue = (selected) => {
  if (!selected) {
    return (
      <Typography variant="body1" color="darkGray">
        Type
      </Typography>
    );
  }

  return selected;
};

export const AnswerTypeSelect = ({ control }) => {
  const { answerTypes } = useSurveyLookups();
  return (
    <Controller
      control={control}
      name="answerType"
      rules={{ required: true }}
      render={({
        field: { onChange, value: fieldValue },
        fieldState,
      }) => (
        <Container>
          <Label variant="h6">Answer Type</Label>
          <Select
            displayEmpty
            renderValue={(selected) => renderValue(selected)}
            value={fieldValue ?? ""}
            onChange={onChange}
            error={!!fieldState?.error}
          >
            {answerTypes?.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </Select>
        </Container>
      )}
    />
  );
};
