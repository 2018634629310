// Utils
import {
  createReducer,
  createAsyncThunk,
  createAction,
} from "@reduxjs/toolkit";

// Services
import { getUserData as fetchUserData } from "service";

// Actions
import { GET_USER_DATA, SET_AUTHENTICATION } from "constants/index";
import { encrypt, setValue } from "utils";

const initialState = {
  user: null,
  error: null,
  loading: true,
};

export const getUserData = createAsyncThunk(
  GET_USER_DATA,
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetchUserData();

      const isAdmin = encrypt(data?.isAdmin?.toString());
      setValue("isAdmin", isAdmin);
      return data;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const setAuthentication = createAction(SET_AUTHENTICATION);

export const userReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getUserData.fulfilled, (state, action) => {
      const {
        full_name: fullName,
        email,
        isAdmin,
        username,
      } = action.payload || {};

      state.user = { ...state.user, fullName, email, isAdmin, username };
      state.loading = false;
    })
    .addCase(getUserData.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
