import styled from 'styled-components';

// Components
import { Icon } from 'components';
import { DrawerMenuItem } from './DrawerMenuItem';

// Contexts
import { useDrawer } from 'contexts';

//Constants
import { LOGOUT_LARGE, HELP } from 'constants/index';

// Hooks
import { useHandleLogoutWithRedirect } from "hooks";

const Wrapper = styled.div`
  padding: 20px 0 15px 15px;
`;

const StyledDrawerMenuItem = styled(DrawerMenuItem)`
  padding: 12px 0;
`;

export const BottomMenu = () => {
  const { toggleDrawer } = useDrawer();
	const { handleLogout: logout } = useHandleLogoutWithRedirect();

	async function handleLogout() {
    try {
      toggleDrawer(false);
      await logout();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Wrapper>
      <StyledDrawerMenuItem
        iconComponent={<Icon type={HELP} />}
        title="Help"
        typographyVariant="h3"
				typographyColor="blueAccent"
				onClick={() => toggleDrawer(false)}
      />
			<StyledDrawerMenuItem
        iconComponent={<Icon type={LOGOUT_LARGE} />}
        title="Logout"
        typographyVariant="h3"
				typographyColor="blueAccent"
				onClick={handleLogout}
      />
    </Wrapper>
  );
};
