export const AUTHENTICATION = "AUTHENTICATION";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const GET_USER_DATA = "GET_USER_DATA";
export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const GET_OKTA_APPS = "GET_OKTA_APPS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const SORT_CATEGORY = "SORT_CATEGORY";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_SUBCATEGORIES = "GET_ALL_SUBCATEGORIES";
export const GET_SUBCATEGORY = "GET_ALL_SUBCATEGORY";
export const SORT_SUBCATEGORY = "SORT_SUBCATEGORY";
export const DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY";
export const GET_SUBCATEGORY_TAGS = "GET_SUBCATEGORY_TAGS";
export const DELETE_SUBCATEGORY_TAG = "DELETE_SUBCATEGORY_TAG";
export const CREATE_SUBCATEGORY_TAGS = "CREATE_SUBCATEGORY_TAGS";
export const UPDATE_SUBCATEGORY = "UPDATE_SUBCATEGORY";
export const DISMISS_TOAST = "DISMISS_TOAST";
export const PATCH_KEY_INFORMATION = "PATCH_KEY_INFORMATION";
export const UPDATE_KEY_INFORMATION = "UPDATE_KEY_INFORMATION";
export const DELETE_KEY_INFORMATION = "DELETE_KEY_INFORMATION";
export const GET_KEY_INFORMATION_LIST = "GET_KEY_INFORMATION_LIST";
export const GET_KEY_HIGHLIGHTS = "GET_KEY_HIGHLIGHTS";
export const EDIT_KEY_HIGHLIGHTS = "EDIT_KEY_HIGHLIGHTS";
export const GET_NEWS = "GET_NEWS";
export const CREATE_SINGLE_NEWS = "CREATE_SINGLE_NEWS";
export const UPDATE_SINGLE_NEWS = "UPDATE_SINGLE_NEWS";
export const DELETE_SINGLE_NEWS = "DELETE_SINGLE_NEWS";
export const GET_SURVEY_CATEGORIES = "GET_SURVEY_CATEGORIES";
export const CREATE_SURVEY_CATEGORY = "CREATE_SURVEY_CATEGORY";
export const EDIT_SURVEY_CATEGORY = "EDIT_SURVEY_CATEGORY";
export const DELETE_SURVEY_CATEGORY = "DELETE_SURVEY_CATEGORY";
export const GET_SURVEY_QUESTIONS = "GET_SURVEY_QUESTIONS";
export const CREATE_SURVEY_QUESTION = "CREATE_SURVEY_QUESTION";
export const EDIT_SURVEY_QUESTION = "EDIT_SURVEY_QUESTION";
export const DELETE_SURVEY_QUESTION = "DELETE_SURVEY_QUESTION";
export const GET_SURVEY_LOOKUPS = "GET_SURVEY_LOOKUPS";
export const GET_SURVEYS = "GET_SURVEYS";
export const CREATE_SURVEY = "CREATE_SURVEY";
export const DELETE_SURVEY = "DELETE_SURVEY";
