import React from "react";

import amplitude from "amplitude-js";
import { AmplitudeProvider } from "@amplitude/react-amplitude";

// Providers
import GACompassThemeProvider from "theme";

// Contexts
import {
  ModalProvider,
  DrawerProvider,
  LinearProgressProvider,
  ToastProvider,
  ErrorHandlerProvider,
} from "contexts";

// Components
import { BrowserRouter as Router } from "react-router-dom";

// Routes
import { AppRoutes } from "routes/AppRoutes";

// Hooks
import { useHandleRedirect } from "hooks";

function App() {
  useHandleRedirect();

  return (
    <AmplitudeProvider
      amplitudeInstance={amplitude.getInstance()}
      apiKey={process.env.REACT_APP_ANALYTICS_API_KEY}
    >
      <GACompassThemeProvider>
        <Router>
          <DrawerProvider>
            <ModalProvider>
              <LinearProgressProvider>
                <ToastProvider>
                  <ErrorHandlerProvider>
                    <AppRoutes />
                  </ErrorHandlerProvider>
                </ToastProvider>
              </LinearProgressProvider>
            </ModalProvider>
          </DrawerProvider>
        </Router>
      </GACompassThemeProvider>
    </AmplitudeProvider>
  );
}

export default App;
