import React from "react";
import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";

// Components
import { Box, IconButton, Snackbar as MUISnackbar } from "@material-ui/core";
import { Icon } from "components/Icon";
import { Typography } from "components/Typography";

// Constants
import { CHECK_CIRCLE, CLOSE, ERROR } from "constants/index";

const Snackbar = styled(MUISnackbar)`
  ${({ theme }) => css`
    .MuiSnackbarContent-root {
      height: 100%;
      background-color: ${theme.palette.white};
      border-left: 8px solid ${theme.palette.green2};
      padding: 0;
      max-width: 450px;
      height: 120px;
      margin-right: 40px;

      ${(p) =>
        p.variant === "error" &&
        css`
          border-left: 8px solid ${theme.palette.red};
        `}
    }
    &.MuiSnackbar-anchorOriginTopRight {
      width: 500px;
      height: 120px;
      top: 88px;
      right: 0;
      left: auto;
      position: fixed;
    }

    .MuiSnackbarContent-message {
      width: 83%;
    }

    .MuiSnackbarContent-action {
      margin-right: 8px;
      margin-top: -18px;
    }
  `}
`;

const variants = {
  success: {
    color: "green2",
    icon: CHECK_CIRCLE,
  },
  error: {
    color: "red",
    icon: ERROR,
  },
};

export const Toast = ({ open, toast, dismissToast }) => {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      autoHideDuration={5000}
      message={
        <Box display="flex" ml={3}>
          <Icon
            type={variants[toast.variant].icon}
            color={variants[toast.variant].color}
          />
          <Box display="flex" flexDirection="column" ml={2}>
            <Typography variant="h3" color="black">
              {toast.message}
            </Typography>
            <Typography variant="body1" color="darkGray">
              {toast.subtitle}
            </Typography>
          </Box>
        </Box>
      }
      variant={toast.variant}
      onClose={dismissToast}
      action={
        <IconButton onClick={dismissToast}>
          <Icon type={CLOSE} color="grayMiddle" />
        </IconButton>
      }
    />
  );
};

Toast.propTypes = {
  open: PropTypes.bool.isRequired,
  toast: PropTypes.shape({
    message: PropTypes.string,
    subtitle: PropTypes.string,
    variant: PropTypes.string,
  }),
  dismissToast: PropTypes.func.isRequired,
};

Toast.defaultProps = {
  toast: {
    message: "Toast message",
    subtitle: "Toast subtitle text",
    variant: "success",
  },
};
