// Utils
import { RadioButtons, Typography } from "components";
import { useScreens } from "hooks";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { CheckboxSelect } from "./CheckboxSelect";
import { SelectFilter } from "./SelectFilter";

const Wrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  ${ifProp(
    "laptopScreens",
    css`
      flex-direction: row;
    `
  )}
`;
const SortControlsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  ${ifProp(
    "tabletScreens",
    css`
      flex-direction: row;
      align-items: center;
    `
  )}
  ${ifProp(
    "laptopScreens",
    css`
      margin-top: 0;
    `
  )}
`;
const FilterControlsWrapper = styled.div`
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  ${ifProp(
    "tabletScreens",
    css`
      flex-direction: row;
      align-items: center;
    `
  )}
`;
const SelectCheckboxWrapper = styled.div`
  width: 300px;
`;

const ClearFiltersLinkWrapper = styled.div`
  margin-top: 7px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  ${ifProp(
    "tabletScreens",
    css`
      margin-left: 20px;
      margin-top: 0;
    `
  )}
`;

const searchRadioButtonsData = [
  {
    label: "Ascending",
    value: "asc",
  },
  {
    label: "Descending",
    value: "desc",
  },
];

export function SortFilterControls({
  setSortingDirection,
  sortingDirection,
  setSelectedSortCriteria,
  selectedSortCriteria,
  selectedFilterCriteria,
  setSelectedFilterCriteria,
  filterOptions,
  setFilterOptions,
  clearAllSortsAndFilters,
  filterCriteriaOptions,
  sortCriteriaOptions,
  filterOnly,
  clearAllFilters,
  ...props
}) {
  const { tabletScreens, laptopScreens } = useScreens();
  return (
    <Wrapper {...props} laptopScreens={laptopScreens}>
      <FilterControlsWrapper tabletScreens={tabletScreens}>
        <SelectFilter
          options={filterCriteriaOptions}
          selected={selectedFilterCriteria}
          onSelect={(newFilterCriteria) =>
            setSelectedFilterCriteria(newFilterCriteria)
          }
          label="Filter by"
        />
        <SelectCheckboxWrapper>
          <CheckboxSelect
            checkboxItems={filterOptions}
            label={"Select Last Name(s)"}
            setCheckedItems={(items) => setFilterOptions(items)}
          />
        </SelectCheckboxWrapper>
        {filterOnly && (
          <ClearFiltersLinkWrapper
            tabletScreens={tabletScreens}
            onClick={clearAllFilters}
          >
            <Typography variant="h6" color="blueAccent">
              Clear Filters
            </Typography>
          </ClearFiltersLinkWrapper>
        )}
      </FilterControlsWrapper>

      {!filterOnly && (
        <SortControlsWrapper
          laptopScreens={laptopScreens}
          tabletScreens={tabletScreens}
        >
          <SelectFilter
            options={sortCriteriaOptions}
            selected={selectedSortCriteria}
            onSelect={(newSortCriteria) =>
              setSelectedSortCriteria(newSortCriteria)
            }
            label="Sort by"
          />
          <RadioButtons
            options={searchRadioButtonsData}
            selectedValue={sortingDirection}
            setSelectedValue={(newSortingDirection) =>
              setSortingDirection(newSortingDirection)
            }
          />
          <ClearFiltersLinkWrapper
            tabletScreens={tabletScreens}
            onClick={clearAllSortsAndFilters}
          >
            <Typography variant="h6" color="blueAccent">
              Clear Filters
            </Typography>
          </ClearFiltersLinkWrapper>
        </SortControlsWrapper>
      )}
    </Wrapper>
  );
}
