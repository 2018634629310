import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { GET_SURVEYS, DELETE_SURVEY } from "constants/actions";

import { getSurveys as fetchSurveys, deleteSurvey as removeSurvey } from "service";

const initialState = {
  surveys: null,
  error: null,
  loading: true,
};

export const getSurveys = createAsyncThunk(
  GET_SURVEYS,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchSurveys();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteSurvey = createAsyncThunk(
  DELETE_SURVEY,
  async (id, { rejectWithValue }) => {
    console.log(id);
    try {
      await removeSurvey(id);
      return await fetchSurveys();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const surveysReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getSurveys.fulfilled, (state, action) => {
      state.surveys = action.payload;
      state.loading = false;
    })
    .addCase(getSurveys.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(deleteSurvey.fulfilled, (state, action) => {
      state.surveys = action.payload;
      state.loading = false;
    })
    .addCase(deleteSurvey.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
