import { createTheme } from "@material-ui/core";

// Fonts
import { heeboLight, heeboRegular, heeboMedium, heeboBold } from "./fonts";

const gaCompassTheme = createTheme({
  breakpoints: {
    values: {
      smallMobile: 365,
      smallTablet: 600,
      tablet: 768,
      largeTablet: 1070,
      laptop: 1366,
      smallDesktop: 1450,
      desktop: 1920,
      largeDesktop: 2560,
    },
  },
  typography: {
    fontFamily: "Heebo",
  },

  palette: {
    //primary
    white: "#FFFFFF",
    black: "#010813",
    darkGray: "#6D7786",
    blueMain: "#173057",
    blueAccent: "#2B59A3",
    blueSoft: "#00517A",

    //secondary
    greenLight: "#29C9CC",
    greenMain: "#007498",
    green2: "#27BDBF",
    green3: "#0098AF",
    green4: "#036A8A",
    orange: "#FF9D42",
    gray: "#808A99",
    grayMiddle: "#A1ABBB",
    grayLight: "#E6EBF4",
    graySuperLight: "#FAFAFB",
    graySuperLight2: "#F4F4F5",

    //tertiary
    softGreen: "#669F9B",
    blueGray: "#6D7EAB",
    violet: "#92619C",
    red: "#E85454",
    orangeLight: "#FFA074",

    //shadow
    shadowCard: "0px 4px 20px rgba(211, 214, 216, 0.6)",
    shadowOverlay: "0px 2px 32px #DDE0E1",
    shadowMenu: "2px 12px 24px rgba(221, 224, 225, 0.7)",
    shadowBackdrop: "rgba(1, 8, 19, 0.2)",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [heeboLight, heeboRegular, heeboMedium, heeboBold],
        body: {
          fontFamily: "Heebo",
          height: "100%",
        },
        html: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
        "*": {
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "2px 12px 16px rgba(161, 171, 187, 0.2)",
      },
      elevation2: {
        boxShadow: "0px 4px 20px rgba(211, 214, 216, 0.6)",
      },
      elevation6: {
        boxShadow: "0px 2px 32px #DDE0E1",
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 24,
        lineHeight: "36px",
        fontWeight: 500,
      },
      h2: {
        fontSize: 18,
        lineHeight: "24px",
        fontWeight: 700,
      },
      h3: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 500,
      },
      h4: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 400,
      },
      h5: {
        fontSize: 16,
        lineHeight: "24px",
        fontWeight: 300,
      },
      h6: {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 500,
      },
      subtitle1: {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 400,
        textTransform: "uppercase",
      },
      subtitle2: {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 500,
        textTransform: "uppercase",
      },
      body1: {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        lineHeight: "20px",
        fontWeight: 300,
      },
      caption: {
        fontSize: 12,
        lineHeight: "18px",
        fontWeight: 400,
      },
      srOnly: {
        fontSize: 12,
        lineHeight: "normal",
        fontWeight: 500,
        width: "auto",
        height: "auto",
        position: "relative",
      },
      overline: {
        fontSize: 10,
        lineHeight: "16px",
        fontWeight: 400,
        textTransform: "none",
      },
    },
  },
});

export default gaCompassTheme;
