import React, { useEffect, useRef, useState } from "react";

// Utils
import { differenceWith, isEqual } from "lodash";
import styled from "styled-components";

// Components
import { Chip, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import { Button, Typography } from "components";

// Hooks
import { useScreens } from "hooks";
import { useParams } from "react-router";

const Container = styled.div`
  display: flex;
`;

const Autocomplete = styled(({ noCapitalizeChipsText, ...rest }) => (
  <MUIAutocomplete {...rest} />
))`
  width: 394px;
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  position: relative;
  .MuiInputBase-root {
    flex: 1;
  }

  .MuiAutocomplete-inputRoot {
    padding: 0;
  }

  .MuiPaper-root.MuiAutocomplete-paper {
    width: 100%;
  }

  .MuiAutocomplete-option {
    font-size: 14px;
    :hover {
      background-color: green;
    }
  }

  .MuiChip-root {
    background: transparent;

    .MuiChip-label {
      background: transparent;
      height: 26px;
      padding: 4px 12px;
      border-radius: 4px;
      text-transform: ${({ noCapitalizeChipsText }) =>
        noCapitalizeChipsText ? "none" : "capitalize"};
      display: block;
      align-items: center;
      background-color: ${({ theme }) => theme.palette.grayLight};
      margin-right: 7px;
    }
    .MuiChip-deleteIcon {
      width: 16px;
      color: ${({ theme }) => theme.palette.blueMain};
    }
  }
`;

const GroupContainer = styled.div`
  padding: 8px 10px;
  .MuiAutocomplete-option[data-focus="true"] {
    background-color: white;
    :hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .MuiAutocomplete-option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

const GroupLabel = styled(Typography)`
  cursor: pointer;
  padding: 2px 16px;
  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  background-color: ${({ isSelected }) =>
    isSelected ? "rgba(0, 0, 0, 0.08)" : "white"};
`;

export const ExpertiseSearch = ({
  expertiseList,
  handleSingleSelected,
  selectedItems,
  isCategoryNameClickable,
  onCategoryNameClick,
  onApply,
  noCapitalizeChipsText,
}) => {
  const params = useParams();
  const [value, setValue] = useState(params.query || "");
  const [open, setOpen] = useState(Boolean(params.query) || false);
  const { tabletScreens } = useScreens();

  const inputRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef.current]);

  const setTextInputRef = (element) => {
    inputRef.current = element;
  };

  const handleOnChange = (values) => {
    const addedValue = differenceWith(values, selectedItems, isEqual)[0];
    const removedValue = differenceWith(selectedItems, values, isEqual)[0];

    handleSingleSelected(addedValue || removedValue);
    setValue("");
  };

  const handleOnCategorySelect = (val) => {
    onCategoryNameClick(val.group);
    setValue("");
    setOpen(false);
  };

  const options = React.useMemo(() => {
    return expertiseList.flatMap((group) => group.ExpertiseTypeItems);
  }, [expertiseList]);

  const filterOptions = (options, state) => {
    const inputValue = state.inputValue.toLowerCase();
    if (isCategoryNameClickable) {
      return options?.filter(
        (option) =>
          option?.ExpertiseName?.toLowerCase()?.includes(inputValue) ||
          option?.ExpertiseSubCategory?.toLowerCase()?.includes(inputValue)
      );
    }
    return options?.filter((option) =>
      option?.ExpertiseName?.toLowerCase()?.includes(inputValue)
    );
  };

  return (
    <Container>
      <Autocomplete
        value={selectedItems}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        options={options}
        filterOptions={filterOptions}
        getOptionLabel={(option) => option.ExpertiseName}
        onChange={(event, values) => handleOnChange(values)}
        onInputChange={(e) => {
          if (typeof e?.target?.value === "string") {
            setValue(e?.target?.value || "");
          }
        }}
        multiple
        style={{ width: 394 }}
        inputValue={value}
        groupBy={(option) =>
          isCategoryNameClickable ? String(option.ExpertiseSubCategory) : false
        }
        noCapitalizeChipsText={noCapitalizeChipsText}
        renderGroup={(val) => {
          return (
            <GroupContainer>
              <GroupLabel
                variant="h6"
                color="blueAccent"
                onClick={() => handleOnCategorySelect(val)}
                isSelected={selectedItems?.[0]?.ExpertiseName === val?.group}
              >
                {val.group}
              </GroupLabel>
              {val.group !== "View All" && val.children}
            </GroupContainer>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputRef={setTextInputRef}
              variant="outlined"
              placeholder={selectedItems?.length ? "" : "Search an expertise"}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <IconButton>
                        <Search fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              label={option.ExpertiseName}
              deleteIcon={<Close />}
              onDelete={() => handleSingleSelected(option)}
            />
          ))
        }
      />
      {tabletScreens && (
        <Button
          variant="primary"
          text="Apply"
          isSmall
          width="140px"
          onClick={onApply}
        />
      )}
    </Container>
  );
};
