// Utils
import styled, { css } from "styled-components";

// Components
import {
  Table,
  NoContentCard,
  TableBody,
  StyledTableCell,
} from "components";

// Hooks
import { tableHeadData, useRows } from "./useRows";
import { useScreens, useTablePagination } from "hooks";
import { ifProp } from "styled-tools";
import { useEffect, useState } from "react";
import { getSortedCompanies } from "./sort";

const TableScrollWrapper = styled.div`
  overflow-x: auto;
  padding-bottom: 40px;
  padding-left: 20px;
  display: flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  ${ifProp(
    "tabletScreens",
    css`
      padding-left: 40px;
    `
  )}
`;

const TableWrapper = styled.div`
  display: inline-block;
  flex-grow: 1;
  margin-top: 16px;
  white-space: nowrap;

  .MuiTablePagination-root {
    position: absolute;
    width: calc(100vw - 100px);
  }

  ${TableBody} {
    ${StyledTableCell} {
      > div {
        align-items: flex-start;
      }
    }
  }
  ${ifProp(
    "tabletScreens",
    css`
      margin-right: 40px;
    `
  )};
`;

export function EmployeeCompaniesTable({
  employeeCompanies,
  noContentDescription,
}) {
  const { tabletScreens } = useScreens();

  const [sort, setSort] = useState("name_asc");

  const [sortedCompanies, setSortedCompanies] = useState(employeeCompanies);

  const { rows } = useRows(sortedCompanies);

  useEffect(() => {
    if (employeeCompanies) {
      setSortedCompanies(
        getSortedCompanies([...employeeCompanies], "name_asc")
      );
      setSort("name_asc");
    }
  }, [employeeCompanies]);

  useEffect(() => {
    setSortedCompanies((prev) => {
      if (!prev?.length) return;
      return getSortedCompanies([...prev], sort);
    });
  }, [sort, setSortedCompanies]);

  const onNameSort = () => {
    const nextVal = sort === "name_asc" ? "name_desc" : "name_asc";
    setSort(nextVal);
  };

  const onAcquisitionDateSort = () => {
    const nextVal =
      sort === "acquisition_date_asc"
        ? "acquisition_date_desc"
        : "acquisition_date_asc";
    setSort(nextVal);
  };
  const onExitDateSort = () => {
    const nextVal =
      sort === "exit_date_asc" ? "exit_date_desc" : "exit_date_asc";
    setSort(nextVal);
  };
  const onSectorSort = () => {
    const nextVal = sort === "sector_asc" ? "sector_desc" : "sector_asc";
    setSort(nextVal);
  };
  const onGeographySort = () => {
    const nextVal =
      sort === "geography_asc" ? "geography_desc" : "geography_asc";
    setSort(nextVal);
  };

  const sortProperties = {
    name: {
      onSortClick: onNameSort,
      isSortIconActive: sort?.includes("name"),
      isSortIconUp: sort === "name_asc",
    },
    acquisitionDate: {
      onSortClick: onAcquisitionDateSort,
      isSortIconActive: sort?.includes("acquisition_date"),
      isSortIconUp: sort === "acquisition_date_asc",
    },
    exitDate: {
      onSortClick: onExitDateSort,
      isSortIconActive: sort?.includes("exit_date"),
      isSortIconUp: sort === "exit_date_asc",
    },
    sector: {
      onSortClick: onSectorSort,
      isSortIconActive: sort?.includes("sector"),
      isSortIconUp: sort === "sector_asc",
    },
    geography: {
      onSortClick: onGeographySort,
      isSortIconActive: sort?.includes("geography"),
      isSortIconUp: sort === "geography_asc",
    },
  };

  const tableHeadDataExtended = tableHeadData.map((data) => {
    return {
      ...data,
      ...sortProperties[data.name],
    };
  });

  const {
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    tableRowsDataPerPage,
  } = useTablePagination({
    initialRowsPerPage: 25,
    rows: rows,
  });

  const paginationOptions = {
    rowsNumber: rows?.length,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPageOptions: [5, 10, 25],
  };

  return (
    <>
      <TableScrollWrapper tabletScreens={tabletScreens}>
        <TableWrapper tabletScreens={tabletScreens}>
          <Table
            tableHeadData={tableHeadDataExtended}
            tableMinWidth="1100px"
            tableRowsData={tableRowsDataPerPage}
            paginationOptions={paginationOptions}
            rawData={employeeCompanies}
          />
        </TableWrapper>
      </TableScrollWrapper>
      {!employeeCompanies?.length && (
        <NoContentCard
          title="Sorry, there are no companies selected."
          description={noContentDescription}
          offset={0}
        />
      )}
    </>
  );
}
