// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const ocpKey = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;

const api = apiConfig(baseURL, ocpKey);

export async function getSubcategoryTags() {
  const { data: response } = await api.get("/compass/subcategorytags");
  const { data } = response;
  return data.subCategoriesTag.sort(function (x, y) {
    return new Date(y.timestamp) - new Date(x.timestamp);
  });
}

export async function createSubcategoryTag(payload) {
  const dataToSend = JSON.stringify(payload);
  const { data } = await api.post("/compass/subcategorytags", dataToSend);

  return data;
}

export async function addTagsToCreatedSubcategory(payload, id) {
  const dataToSend = JSON.stringify(payload);
  const { data } = await api.post(
    `/compass/subcategories/${id}/tags`,
    dataToSend
  );

  return data;
}

export async function addTagToExistingSubcategory({
  subCategoryId,
  ...payload
}) {
  const { data } = await api.patch(
    `/compass/subcategories/${subCategoryId}/tags`,
    JSON.stringify(payload)
  );

  return data;
}

export async function removeTagFromExistingSubcategory({
  subCategoryId,
  ...payload
}) {
  const { data } = await api.delete(
    `/compass/subcategories/${subCategoryId}/tags`,
    { data: JSON.stringify(payload) }
  );

  return data;
}

export async function deleteSubcategoryTag(id, name) {
  const dataToSend = JSON.stringify({ id, name });
  const { data } = await api.delete(`/compass/subcategorytags`, {
    data: dataToSend,
  });

  return data;
}

export async function getSubcategoryTagsBySubcategoryId(subcategoryId) {
  const { data: response } = await api.get(
    `/compass/subcategories/${subcategoryId}/tags`
  );
  const { data } = response;
  return data.subCategoriesTag;
}
