import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { useHistory, useRouteMatch } from "react-router";

// Hooks
import { useMinWidth } from "hooks";

// Components
import {
  PageHeaderAvatar,
  PageHeaderLogo,
  PageHeaderSwitcher,
  PageHeaderDrawer,
  PageHeaderSearch,
} from "./components";
import { Box, IconButton as MUIIconButton } from "@material-ui/core";
import { Typography } from "components";

const Wrapper = styled.div`
  ${({ theme }) => `
    height: 60px;
    width: 100%;
    background: ${theme.palette.blueMain};
    position: fixed;
    top: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    z-index: 2;
  `}

  ${ifProp(
    "tabletScreens",
    css`
      height: 76px;
      padding: 0 40px;
    `
  )}
`;

const IconButton = styled(MUIIconButton)`
  border-radius: 10px;
`;

export function PageHeader({ title }) {
  const history = useHistory();
  const match = useRouteMatch();
  const tabletScreens = useMinWidth("tablet");
  const isSearchPage = match.url !== "/";

  const getPageSlug = (s) =>
    s.substring(0, s.indexOf("/", s.indexOf("/") + 1)) || s;

  const pageSlug = getPageSlug(match.url);

  function handleClick() {
    if (pageSlug === "/search") {
      history.push("/");
      return;
    }
    history.push(pageSlug);
  }

  return (
    <Wrapper tabletScreens={tabletScreens}>
      <Box display="flex" alignItems="center" mr="auto">
        {!tabletScreens ? <PageHeaderDrawer /> : null}
        <IconButton onClick={() => history.push("/")}>
          <PageHeaderLogo type="light" />
        </IconButton>
        {Boolean(tabletScreens && title) && (
          <Box ml={4}>
            <IconButton onClick={handleClick}>
              <Typography variant="h3" color="white">
                {`${title}`}
              </Typography>
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mr={-1.5}
        flex={tabletScreens && isSearchPage ? 1 : "unset"}
        pl={3.25}
      >
        {Boolean(isSearchPage) && <PageHeaderSearch />}
        <PageHeaderSwitcher />
        <PageHeaderAvatar />
      </Box>
    </Wrapper>
  );
}

PageHeader.propTypes = {
  title: PropTypes.string,
};
