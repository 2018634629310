import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Box, Button as MuiButton } from "@material-ui/core";
import { Button, Typography } from "components";

const CancelButton = styled(MuiButton)`
  text-transform: unset;
  padding: 0;
  margin-left: 13px;
  &:hover {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

export const SaveCancelButtons = ({
  onSaveClick,
  onCancelClick,
  isSaveButtonDisabled,
}) => {
  return (
    <Box display="flex">
      <Button
        variant="primary"
        text="Save"
        width="140px"
        isSmall
        isDisabled={isSaveButtonDisabled}
        onClick={onSaveClick}
      />
      <CancelButton onClick={onCancelClick}>
        <Typography variant="body1" color="black">
          Cancel
        </Typography>
      </CancelButton>
    </Box>
  );
};

SaveCancelButtons.propTypes = {
  onSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isSaveButtonDisabled: PropTypes.bool,
};

SaveCancelButtons.defaultProps = {
  onSaveClick: () => {},
  onCancelClick: () => {},
  isSaveButtonDisabled: false,
};
