import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { useHistory } from "react-router";
import { debounce } from "lodash";

// Components
import { Autocomplete as MUIAutocomplete } from "@material-ui/lab";
import { Typography, Icon, Loader } from "components";
import { AutocompleteOption } from "./components/AutocompleteOption";
import { AutocompleteInput } from "./components/AutocompleteInput";
import { AutocompletePaper } from "./components/AutocompletePaper";

// Hooks
import { useMinWidth } from "hooks";

// Data
import { options as autocompleteOptions } from "service";

// Constants
import { SEARCH_MUI } from "constants/index";

const StyledAutocomplete = styled(({ tabletScreens, variant, ...rest }) => (
  <MUIAutocomplete {...rest} />
))`
  ${({ theme }) => `
    max-width: 650px;
    width: 100%;
    
    .MuiInputBase-root {
      height: 44px;
      padding-left: 20px;
    }

    .MuiFormControl-root {
      background: ${theme.palette.white};
      z-index: 1;
      border-radius: 4px;
    }

    .MuiOutlinedInput-notchedOutline,
    .Mui-focused .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${theme.palette.gray};
    }

    .MuiAutocomplete-popupIndicator {
      height: 100%;
      width: 52px;
      margin-right: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      background: ${theme.palette.blueAccent};

      .MuiSvgIcon-root {
        fill: ${theme.palette.white};
      }
    }

    .MuiAutocomplete-popupIndicatorOpen {
      transform: rotate(0deg);
    }

    &&& .MuiAutocomplete-endAdornment {
      top: 0;
      right: 0;
      height: 100%;
    }
  `}

  ${ifProp(
    "open",
    css`
      .MuiInputBase-root {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `
  )}

  ${ifProp(
    "tabletScreens",
    css`
      .MuiInputBase-root {
        height: 48px;
      }
    `
  )}

  ${ifProp(
    { variant: "header" },
    css`
      max-width: none;

      .MuiInputBase-root {
        height: 36px;
        padding: 0 20px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }

      .MuiAutocomplete-popupIndicator {
        display: none;
      }

      .Mui-focused .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    `
  )}
`;

const StyledBackdrop = styled.span`
  ${({ theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    background: ${theme.palette.shadowBackdrop};
  `}
`;

function Backdrop() {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  });

  return <StyledBackdrop />;
}

export function Autocomplete({
  variant,
  setShow,
  value,
  async,
  asyncCallback,
}) {
  const history = useHistory();
  const tabletScreens = useMinWidth("tablet");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [options, setOptions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const closeTimeoutRef = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    (async () => {
      if (async && value) {
        const [searchResult, suggestionsResult] = await asyncCallback(value);

        setOptions(searchResult);
        setSuggestions(suggestionsResult);
      }
    })();
  }, [async, asyncCallback, value]);

  function handleClose() {
    setInputValue("");

    if (options.length) {
      setOptions([]);
    }

    if (setShow) {
      setShow(false);
    }
  }

  const debouncedChangeHandler = useMemo(
    () =>
      debounce(async (value) => {
        try {
          setError(false);
          const [searchResult, suggestionsResult] = await asyncCallback(value);

          setOptions(searchResult);
          setSuggestions(suggestionsResult);
          setLoading(false);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      }, 300),
    [asyncCallback]
  );

  const handleInputChange = useCallback(
    (_, v) => {
      setInputValue(v);

      if (async) {
        if (!loading) {
          setLoading(true);
        }

        if (options.length) {
          setOptions([]);
        }

        debouncedChangeHandler(v);
      }

      if (v?.length > 3) {
        return setOpen(true);
      }

      setOpen(false);
    },
    [debouncedChangeHandler, async, loading, options.length]
  );

  const handleChange = useCallback(
    (e, v, reason) => {
      if (e.key === "Enter" && reason === "select-option") {
        history.push(v.link);
      }
    },
    [history]
  );

  const goToAllResults = useCallback(
    () => history.push(`/search/results/all/${inputValue}`),
    [history, inputValue]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && inputValue) {
        goToAllResults();
      }
    },
    [goToAllResults, inputValue]
  );

  const handleOnOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleOnClose = useCallback(() => {
    closeTimeoutRef.current = setTimeout(() => {
      setOpen(false);
    }, 100);
  }, []);

  useEffect(() => {
    return () => clearTimeout(closeTimeoutRef.current);
  }, []);

  return (
    <>
      <StyledAutocomplete
        open={inputValue?.length > 3 && open}
        options={options}
        getOptionLabel={({ title }) => title}
        getOptionSelected={(option, value) => option.title === value.title}
        renderOption={({ ...props }) => <AutocompleteOption {...props} />}
        renderInput={({ ...props }) => (
          <AutocompleteInput
            {...props}
            variant={variant}
            handleClose={handleClose}
            onKeyDown={handleKeyDown}
          />
        )}
        onInputChange={handleInputChange}
        onChange={handleChange}
        inputValue={inputValue}
        onOpen={handleOnOpen}
        loading={loading}
        loadingText={<Loader />}
        onClose={handleOnClose}
        PaperComponent={({ children, ...rest }) => (
          <AutocompletePaper
            variant={variant}
            options={options}
            inputValue={inputValue}
            suggestions={suggestions}
            loading={loading}
            {...rest}
          >
            {error
              ? "We encountered an error while performing a search."
              : children}
          </AutocompletePaper>
        )}
        disablePortal
        clearOnBlur={false}
        popupIcon={<Icon type={SEARCH_MUI} />}
        disableClearable={variant !== "header"}
        noOptionsText={
          <Typography variant="body1" color="darkGray">
            {`No results for "${inputValue}" in any categories`}
          </Typography>
        }
        tabletScreens={tabletScreens}
        variant={variant}
        {...(async && { filterOptions: (x) => x })}
      />
      {inputValue?.length > 3 && open && <Backdrop />}
    </>
  );
}

Autocomplete.propTypes = {
  variant: PropTypes.string,
  setShow: PropTypes.func,
  value: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      category: PropTypes.string,
      icon: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  async: PropTypes.bool,
  asyncCallback: PropTypes.func,
};

Autocomplete.defaultProps = {
  value: "",
  items: autocompleteOptions,
};
