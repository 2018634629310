import { useEffect, useMemo, useCallback } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getSubcategories as getSubcategoriesAction } from "redux/reducers/subcategoriesReducer";

// Hooks
import { useIsMounted } from "hooks";
import amplitude from "amplitude-js";

export function useSubcategories(categoryId, noInitialLoad, forceFetchAll) {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const mounted = isMounted();

  const { subcategories, loading, error, isAllFetched } = useSelector(
    ({ subcategories }) => subcategories
  );

  const getSubcategories = useCallback(
    (categoryId) => {
      dispatch(getSubcategoriesAction(categoryId));
    },
    [dispatch]
  );

  const memoState = useMemo(
    () => ({
      subcategories,
      loading,
      error,
      getSubcategories,
    }),
    [error, loading, subcategories, getSubcategories]
  );

  useEffect(() => {
    amplitude
      .getInstance()
      .logEvent("Loading Subcategories", { loading, error });
  }, [loading, error]);

  useEffect(() => {
    if (!forceFetchAll || isAllFetched) return;
    dispatch(getSubcategoriesAction(null));
  }, [forceFetchAll, dispatch, isAllFetched]);

  useEffect(() => {
    if (noInitialLoad) return;
    if (!memoState.subcategories) {
      dispatch(getSubcategoriesAction(categoryId ?? null));
    }
  }, [dispatch, memoState.subcategories, categoryId, noInitialLoad]);

  // TODO: temporary solution, subcategories and subcategoriesByCategoryId should be separated
  useEffect(() => {
    if (noInitialLoad) return;
    if (!mounted && categoryId) {
      dispatch(getSubcategoriesAction(categoryId));
    }
  }, [categoryId, dispatch, mounted, noInitialLoad]);

  return { ...memoState };
}
