import { sleep } from 'utils';

export const categoriesSeed = [
	{
		id: 2,
		name: 'Growth Acceleration',
		slug: 'growth-acceleration',
		subcategories: [],
	},
	{
		id: 3,
		name: 'Data',
		slug: 'data',
		subcategories: [],
	},
	{
		id: 4,
		name: 'Research',
		slug: 'research',
		subcategories: [],
	},
	{
		id: 5,
		name: 'Portfolio Human Capital',
		slug: 'portfolio-human-capital',
		subcategories: [],
	},
	{
		id: 6,
		name: 'Capital Markets',
		slug: 'capital-markets',
		subcategories: [],
	},
	{
		id: 7,
		name: 'Transaction Resources',
		slug: 'transaction-resources',
		subcategories: [],
	},
	{
		id: 8,
		name: 'Portfolio Management',
		slug: 'portfolio-management',
		subcategories: [],
	}
]

export const fetchCategories = async () => {
	await sleep(1000);
	return categoriesSeed
}