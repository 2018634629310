import { Table } from "components";
import { useScreens } from "hooks";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { useRows } from "./useRows";

const TableScrollWrapper = styled.div`
  margin-top: 16px;
  overflow-x: auto;
  padding-bottom: 40px;
  display: flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TableWrapper = styled.div`
  display: inline-block;
  flex-grow: 1;
  padding: 0 15px;
  ${ifProp(
    "tabletScreens",
    css`
      padding: 0 30px;
    `
  )}
  ${ifProp(
    "laptopScreens",
    css`
      padding: 0 40px;
    `
  )}
`;

export const KeyHighlightsTable = ({
  keyHighlights,
  page,
  rowsPerPage,
  selectedTag,
  subcategory,
  updateKeyHighlight,
  paginationOptions,
  query,
}) => {
  const { tabletScreens, laptopScreens } = useScreens();
  const [keyHighlightsList, setKeyHighlightsList] = useState([]);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      description: "",
    },
    mode: "onChange",
  });

  const rows = useRows({
    keyHighlightsList,
    setKeyHighlightsList,
    control,
    setValue,
    handleSubmit,
    updateKeyHighlight,
    selectedTag,
    subcategory,
    page,
    pageSize: rowsPerPage,
    query,
  });

  useEffect(() => {
    setKeyHighlightsList(() => {
      return (
        keyHighlights?.map((keyHighlight) => ({
          ...keyHighlight,
          id: keyHighlight._id,
          name:
          keyHighlight.highlight?.["source.name.highlight"]?.[0] ||
          keyHighlight._source.source.name,
          createdDate: keyHighlight._source.source.created_at,
          lastModifiedDate: keyHighlight._source.source.modified_at,
          description:
              keyHighlight?.highlight?.["CONTENT.highlight"]?.[0] ||
              keyHighlight._source.fileMetaDataItems.description,
          isRecommendedRead:
            keyHighlight._source.fileMetaDataItems.isrecommendedread === "True"
              ? true
              : false,
          documentType: keyHighlight._source.source.extension,
          tags: keyHighlight._source.source.tags,
          category: keyHighlight._source.fileMetaDataItems.compassCategory,
          subCategory: keyHighlight._source.FOLDERPATH?.[2],
          subCategoryTag: keyHighlight._source.FOLDERPATH?.[3],
        })) ?? []
      );
    });
  }, [keyHighlights]);

  const tableHeadData = [
    { title: "", name: "empty" },
    { title: "Documents", name: "documents" },
    { title: "Description", name: "description" },
    { title: "Updated date", name: "dateCreated" },
    { title: "Action", name: "action" },
  ];

  return (
    <TableScrollWrapper>
      <TableWrapper tabletScreens={tabletScreens} laptopScreens={laptopScreens}>
        <Table
          tableHeadData={tableHeadData}
          tableMinWidth="1195px"
          tableRowsData={rows}
          rawData={keyHighlights}
          paginationOptions={paginationOptions}
        />
      </TableWrapper>
    </TableScrollWrapper>
  );
};
