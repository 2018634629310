import { lazy } from "react";

// Components
import { Switch } from "react-router-dom";
import {
  PrivateRoute,
  PageLayout,
  Loader,
  RouteWithSuspense,
  SurveysCreateLayout,
  SubcategoryPageLayout,
} from "components";

// Constants
import {
  SUBCATEGORY,
  SUBCATEGORIES,
  DOCUMENTS,
  SEARCH,
  RESULTS,
  QUERY,
  DOCUMENTS_QUERY,
  ALL,
  TYPE,
  MODE,
  STEP,
  ID,
  KNOWLEDGE_MAP,
  EMPLOYEE_PROFILE,
  EMPLOYEE_ID,
  GA_NETWORK,
  EXTERNAL_PROFILE,
  DOCUMENT_PREVIEW,
  SURVEYS,
  SURVEY,
  TRANSACTION_RESOURCES,
  BUSINESS_MODELS,
} from "constants/index.js";

// Path
const Home = lazy(() => import("routes/Home"));
const SearchResult = lazy(() => import("routes/SearchResult"));
const Subcategory = lazy(() => import("routes/Subcategory"));
const SubcategoryDocuments = lazy(() => import("routes/SubcategoryDocuments"));
const KnowledgeMap = lazy(() => import("routes/KnowledgeMap"));
const GaNetwork = lazy(() => import("routes/GaNetwork"));
const Surveys = lazy(() => import("routes/Surveys"));
const SurveysCreate = lazy(() => import("routes/SurveysCreate"));
const ExternalProfile = lazy(() => import("routes/ExternalProfile"));
const SubcategoryDocumentsResults = lazy(() =>
  import("routes/SubcategoryDocumentsResults")
);
const EmployeeProfile = lazy(() => import("routes/EmployeeProfile"));
const AdminPanel = lazy(() => import("routes/AdminPanel"));
const Login = lazy(() => import("routes/Login"));
const ErrorPage = lazy(() => import("routes/Error"));
const DocumentPreview = lazy(() => import("routes/DocumentPreview"));

const TransactionResources = lazy(() => import("routes/TransactionResources"));
const BusinessModels = lazy(() => import("routes/BusinessModels"));
const NotAssigned = lazy(() => import("routes/NotAssigned"));

const ROUTES = [
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: "/",
      component: Home,
      layouts: [{ layout: PageLayout, layoutProps: { title: "Home" } }],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: [
        `/${SUBCATEGORIES}/:${SUBCATEGORY}/${DOCUMENTS}`,
        `/${SUBCATEGORIES}/:${SUBCATEGORY}/${DOCUMENTS}/:${QUERY}`,
      ],
      component: SubcategoryDocuments,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: `/${SEARCH}/${RESULTS}/${SUBCATEGORY}/:${DOCUMENTS_QUERY}/:${SUBCATEGORY}`,
      component: SubcategoryDocumentsResults,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: `/${EXTERNAL_PROFILE}`,
      component: ExternalProfile,
      layouts: [{ layout: PageLayout, layoutProps: { title: "" } }],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: [`/${KNOWLEDGE_MAP}`, `/${KNOWLEDGE_MAP}/:${QUERY}`],
      component: KnowledgeMap,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: [`/${GA_NETWORK}`, `/${GA_NETWORK}/:${QUERY}`],
      component: GaNetwork,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: `/${SURVEYS}`,
      component: Surveys,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: `/${SURVEYS}/:${MODE}/:${STEP}/:${SURVEY}?`,
      component: SurveysCreate,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
        {
          layout: SurveysCreateLayout,
        },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: `/${TRANSACTION_RESOURCES}`,
      component: TransactionResources,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: `/${BUSINESS_MODELS}`,
      component: BusinessModels,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: `/${SUBCATEGORIES}/:${SUBCATEGORY}`,
      component: Subcategory,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "" } },
        { layout: SubcategoryPageLayout },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: [
        `/${SEARCH}/${RESULTS}/${ALL}/:${QUERY}`,
        `/${SEARCH}/${RESULTS}/:${TYPE}/:${QUERY}`,
        `/${SEARCH}/${RESULTS}/:${TYPE}/:${QUERY}/:${ID}`,
      ],
      component: SearchResult,
      layouts: [
        {
          layout: PageLayout,
          layoutProps: { title: "Home / Search Results" },
        },
      ],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      exact: true,
      path: [
        `/${KNOWLEDGE_MAP}/${EMPLOYEE_PROFILE}/:${EMPLOYEE_ID}`,
        `/profile/:${EMPLOYEE_ID}`,
        `/profile/:${EMPLOYEE_ID}/edit`,
      ],
      component: EmployeeProfile,
      layouts: [{ layout: PageLayout }],
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      path: "/admin",
      component: AdminPanel,
      layouts: [{ layout: PageLayout, layoutProps: { title: "Admin Panel" } }],
      isAdminRoute: true,
    },
  },
  {
    routeComponent: PrivateRoute,
    routeProps: {
      path: `/${DOCUMENT_PREVIEW}/:${ID}`,
      component: DocumentPreview,
      layouts: [
        { layout: PageLayout, layoutProps: { title: "Document Preview" } },
      ],
    },
  },
  {
    routeComponent: RouteWithSuspense,
    routeProps: {
      exact: true,
      path: "/login",
      component: Login,
    },
  },
  {
    routeComponent: RouteWithSuspense,
    routeProps: {
      exact: true,
      path: ["/error", "/session-expired"],
      component: ErrorPage,
    },
  },
  {
    routeComponent: RouteWithSuspense,
    routeProps: {
      exact: true,
      path: "/oidc",
      component: () => <Loader fullPage />,
      layouts: [{ layout: PageLayout, layoutProps: { title: "Home" } }],
    },
  },
  {
    routeComponent: RouteWithSuspense,
    routeProps: {
      exact: true,
      path: "/not-assigned",
      component: NotAssigned,
      layouts: [{ layout: PageLayout, layoutProps: { title: "Home" } }],
    },
  },
  {
    routeComponent: RouteWithSuspense,
    routeProps: {
      exact: true,
      path: "*",
      component: () => <div>Not found</div>,
    },
  },
];

export function AppRoutes() {
  return (
    <Switch>
      {ROUTES.map(({ routeComponent: Route, routeProps }, i) => (
        <Route key={i} {...routeProps} />
      ))}
    </Switch>
  );
}
