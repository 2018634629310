import { useEffect, useRef, useState } from "react";

// Service
import { getEmployeeCompanies } from "service";
import amplitude from "amplitude-js";

export function useEmployeeCompanies(id) {
  const employeeCompaniesRef = useRef(null);
  const [employeeCompanies, setEmployeeCompanies] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!employeeCompanies) {
      (async () => {
        try {
          const data = await getEmployeeCompanies(id);
          setEmployeeCompanies(data);
          employeeCompaniesRef.current = data;
          setLoading(false);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      })();
    }
  }, [id, employeeCompanies]);

  useEffect(() => {
    amplitude
      .getInstance()
      .logEvent("Loading Employee Companies", { loading, error });
  }, [loading, error]);

  return {
    employeeCompanies,
    employeeCompaniesRef: employeeCompaniesRef.current,
    setEmployeeCompanies,
    loading,
    error,
  };
}
