import React from 'react';
import PropTypes from 'prop-types';

// Hooks
import { useScreens } from 'hooks';
import { useRows } from './useRows';

import { useParams } from 'react-router';

// Components
import { CardsTable, NoContentCard, Typography } from 'components';
import { FOLDER1, SEARCH } from 'constants/index';

export const SearchDocumentsTable = ({
  noContent = 'default',
  documents,
  title,
  hideParentLabel,
  totalCount,
  paginationOptions,
  didYouMean,
  pageLabel,
}) => {
  const { tabletScreens, laptopScreens } = useScreens();
  const params = useParams();
  const mappedDocuments = React.useMemo(() => {
    return documents?.map((document) => {
      return {
        ...document,
        imageUrl: '/images/placeholders/document-placeholder.svg',
        showImage: document.isRecommended,
      };
    });
  }, [documents]);

  const rows = useRows(mappedDocuments, hideParentLabel, pageLabel);

  const tableHeadData = [
    {
      title:
        title || `${totalCount ? totalCount : documents?.length} Documents`,
      name: 'numberOfDocuments',
      isHidden: !tabletScreens,
    },
    { title: '', name: 'empty', isHidden: !laptopScreens },
    {
      component: (
        <>
          <Typography variant={tabletScreens ? 'h3' : 'h6'} color="darkGray">
            Updated Date
          </Typography>
        </>
      ),
      name: 'dateCreated',
      isFlexEnd: tabletScreens,
    },
  ];

  const getDescription = () => {
    if (didYouMean) {
      return (
        <>
          <Typography variant="body1" color="darkGray">
            We can't find any result for {params.query}
          </Typography>
          {didYouMean !== '' && (
            <Typography variant="body1" color="darkGray">
              Did you mean to search for:{' '}
              <a href={`/search/results/all/${didYouMean}`} title={didYouMean}>
                {didYouMean}
              </a>
            </Typography>
          )}
          <Typography variant="body1" color="darkGray">
            Try searching in the global search bar or querying in a particular
            sub-category for more relevant results.
          </Typography>
        </>
      );
    }

    if (params?.query) {
      return (
        <>
          <Typography variant="body1" color="darkGray">
            We can't find any result for {params.query}
          </Typography>
          <Typography variant="body1" color="darkGray">
            Try searching in the global search bar or querying in a particular
            sub-category for more relevant results.
          </Typography>
        </>
      );
    }

    return (
      <Typography variant="body1" color="darkGray">
        {noContent === 'default'
          ? 'There are no matching documents'
          : 'Expand your search in the global search bar for results.'}
      </Typography>
    );
  };

  if (!documents?.length) {
    return (
      <NoContentCard
        title={noContent === 'default' ? 'Oops!' : 'No Documents available'}
        iconType={noContent === 'default' ? SEARCH : FOLDER1}
        descriptionComponent={getDescription()}
      />
    );
  }

  return (
    <CardsTable
      paginationOptions={paginationOptions && paginationOptions}
      tableRowsData={rows}
      rawData={documents}
      tableHeadData={tableHeadData}
      pageLabel={pageLabel}
    />
  );
};

SearchDocumentsTable.propTypes = {
  documents: PropTypes.array,
  title: PropTypes.string,
  hidePagination: PropTypes.bool,
  hideParentLabel: PropTypes.bool,
  numOfResults: PropTypes.number,
  setNumOfResults: PropTypes.func,
  setPage: PropTypes.func,
  totalCount: PropTypes.number,
  paginationOptions: PropTypes.shape({
    rowsPerPageOptions: PropTypes.array,
    rowsNumber: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    displayedRowsLabel: PropTypes.string,
  }),
};
