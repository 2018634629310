import React from "react";

// Utils
import styled from "styled-components";
import { formatDate } from "utils/formatDate";
import { getIconType } from "utils/getIconType";

// Components
import { ColoredBulletLabel, Icon, Typography } from "components";

// Hooks
import { useScreens } from "hooks";

// Theme
import theme from "theme/theme";

// Constants
import { DOCUMENT_PREVIEW } from "constants/index";
import { Tooltip as MuiTooltip } from "@material-ui/core";

import amplitude from "amplitude-js";

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const UpperContentWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const StyledTypography = styled(Typography)`
  margin-right: 8px;
`;

const DocumentInfoWrapper = styled.div`
  display: flex;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const Description = styled(Typography)`
  margin-top: 8px;
  strong {
    background-color: rgba(230, 239, 255, 0.6);
  }
`;

const LabelsWrapper = styled.div`
  display: flex;
  margin-right: 16px;
`;

const TypographyWrapper = styled.div`
  margin-left: 8px;
  padding-left: 12px;
  position: relative;
  &:not(:first-of-type) {
    &:before {
      content: "";
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${theme.palette.darkGray};
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const Title = styled(Typography)`
  cursor: pointer;
  strong {
    background-color: rgba(230, 239, 255, 0.6);
  }

  &:visited {
    color: ${theme.palette.blueAccent};
  }
  &:hover {
    text-decoration: underline;
  }
`;

const Tooltip = styled(MuiTooltip)`
  ${({ theme }) => `
    + .MuiTooltip-popper {
      .MuiTooltip-tooltip {
        max-width: none;
        background: ${theme.palette.white};
        box-shadow: ${theme.palette.shadowCard};
      }

      .MuiTooltip-arrow {
        color: ${theme.palette.white};
      }
    }
  `}
`;

export const DocumentInfo = ({
  document,
  hideParentLabel,
  pageLabel,
  docIndex,
}) => {
  const { tabletScreens, laptopScreens } = useScreens();

  const fileExt = document.documentType;

  const getIconSize = (laptopScreens, tabletScreens, isRecommended) => {
    if (laptopScreens) {
      return isRecommended ? "48px" : "24px";
    }

    if (!tabletScreens) {
      return "40px";
    }

    return isRecommended ? "40px" : "24px";
  };

  const onOpenDoc = () => {
    amplitude.getInstance().logEvent("Document Opened", {
      openedFrom: pageLabel,
      landingPosition: docIndex,
      documentType: document?.documentType,
      documentName: document?.documentName,
    });

    window.open(`/${DOCUMENT_PREVIEW}/${document?.id}`, "_blank");
  };

  let folderPath = document?.folderPath;
  const category = document?.category;
  const subCategory = document?.subCategory;
  const subCategoryTag = document?.subCategoryTag;
  let shortFolderPath = document?.folderPath?.slice(
   Math.max(document?.folderPath?.length - 3, 0));
   if(folderPath != undefined)
  {
    if(folderPath[0]!=="Portfolio Companies")
    {
      
      if(subCategoryTag !== undefined && subCategoryTag !== " " && subCategoryTag !== "SubCategoryTag")
      {
        shortFolderPath = [category, subCategory, subCategoryTag];
        folderPath = ["Compass", category, subCategory, subCategoryTag ];

      }
      else if(subCategory !== undefined && subCategory !== " " && subCategory !== "SubCategory")
      {
        shortFolderPath = ["Compass", category, subCategory];
        folderPath = ["Compass", category, subCategory ];
        
      }
      else if(category !== undefined && category !== " " && category !== "Category")
      {
        shortFolderPath = ["Compass", category]
        folderPath = ["Compass", category];
      } 
      else
      {
        shortFolderPath = ["Compass"]
        folderPath = ["Compass"];
      }
    }
  }
  return (
    <InfoWrapper>
      <UpperContentWrapper>
        {!tabletScreens && (
          <StyledTypography variant="caption" color="black">
            {formatDate(document.modifiedDate)}
          </StyledTypography>
        )}
        {tabletScreens && !hideParentLabel && (
          <Tooltip
            title={
              <LabelsWrapper>
                {document?.folderPath?.map((folderName) => (
                  <TypographyWrapper>
                    <Typography variant="caption" color="darkGray">
                      {folderName}
                    </Typography>
                  </TypographyWrapper>
                ))}
              </LabelsWrapper>
            }
            placement="top"
            arrow
            PopperProps={{
              disablePortal: true,
            }}
          >
            <LabelsWrapper>
              {shortFolderPath?.map((folderName) => (
                <TypographyWrapper>
                  <Typography variant="caption" color="darkGray">
                    {folderName}
                  </Typography>
                </TypographyWrapper>
              ))}
            </LabelsWrapper>
          </Tooltip>
        )}
        {document.isRecommendedRead && !laptopScreens && (
          <ColoredBulletLabel
            label="Recommended"
            color="orange"
            labelColor="orange"
            labelVariant={tabletScreens ? "caption" : "srOnly"}
            bulletSize="6px"
          />
        )}
      </UpperContentWrapper>
      <DocumentInfoWrapper>
        <Icon
          type={getIconType(fileExt)}
          width={getIconSize(
            laptopScreens,
            tabletScreens,
            document.isRecommendedRead
          )}
          color="white"
        />
        <TextInfoWrapper>
          <Title
            variant={laptopScreens ? "body1" : "body1"}
            dangerouslySetInnerHTML={{ __html: document.name }}
            color="blueAccent"
            onClick={onOpenDoc}
          />

          {tabletScreens && (
            <Description
              variant="caption"
              color="black"
              dangerouslySetInnerHTML={{ __html: document.description }}
            ></Description>
          )}
        </TextInfoWrapper>
      </DocumentInfoWrapper>
    </InfoWrapper>
  );
};
