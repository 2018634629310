import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getNews,
  deleteSingleNews,
  updateSingleNews,
	createSingleNews
} from "redux/reducers/newsReducer";

export function useNews() {
  const dispatch = useDispatch();

  const { news, loading, error } = useSelector(
    ({ news }) => news
  );

  const createSingleNewsCallback = useCallback(
    (newsData) => {
      dispatch(createSingleNews(newsData));
    },
    [dispatch]
  );

  const deleteSingleNewsCallback = useCallback(
    async (newsData) => {
      await dispatch(deleteSingleNews(newsData));
    },
    [dispatch]
  );

  const updateSingleNewsCallback = useCallback(
    (newsData) => {
      dispatch(updateSingleNews(newsData));
    },
    [dispatch]
  );
  
  const getNewsCallback = useCallback(
    () => {
      dispatch(getNews());
    },
    [dispatch]
  );

  const memoState = useMemo(
    () => ({
      news,
      createSingleNews: createSingleNewsCallback,
      deleteSingleNews: deleteSingleNewsCallback,
      updateSingleNews: updateSingleNewsCallback,
      getNews: getNewsCallback,
      loading,
      error,
    }),
    [
      error,
      loading,
      news,
      createSingleNewsCallback,
      deleteSingleNewsCallback,
      updateSingleNewsCallback,
      getNewsCallback,
    ]
  );

  useEffect(() => {
    if (!memoState.news) {
      dispatch(getNews());
    }
  }, [dispatch, memoState.news]);

  return { ...memoState };
}
