import { useScreens } from "hooks";
import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

const LayoutWrapper = styled(({ type, error, ...props }) => <div {...props} />)`
  ${({ theme, error }) => `
    width: 80px;
    height: 80px;
    position: relative;
    padding: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;

    .dzu-dropzone {
      border: 1px solid ${error ? theme.palette.red : theme.palette.grayMiddle};
      border-radius: 0;
      overflow: hidden;
    }
  `}

  ${ifProp(
    { type: "image" },
    css`
      width: 300px;
      height: 240px;

      ${ifProp(
        "tabletScreens",
        css`
          width: 420px;
        `
      )}
    `
  )}
`;

const InputWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  min-height: auto;
  top: 0;
  left: 0;
`;

export function Layout({
  input,
  previews,
  dropzoneProps,
  previewComponent: PreviewComponent,
  previewError,
  type,
  src,
}) {
  const { tabletScreens } = useScreens();
  return (
    <LayoutWrapper
      error={previewError}
      type={type}
      tabletScreens={tabletScreens}
    >
      {previews?.length ? (
        previews
      ) : (
        <PreviewComponent src={src} error={previewError} type={type} />
      )}
      <InputWrapper {...dropzoneProps}>{input}</InputWrapper>
    </LayoutWrapper>
  );
}

Layout.propTypes = {
  previewComponent: PropTypes.elementType.isRequired,
  previewError: PropTypes.bool,
  type: PropTypes.string,
};

Layout.defaultProps = {
  previewError: false,
  type: "icon",
};
