import { useMemo } from "react";
import styled from "styled-components";

// Components
import { Loader, NoContentCard, ScrollableMenu } from "components";
import { SubcategoryCard } from "./SubcategoryCard";

const Wrapper = styled.div`
  min-height: 125px;
  margin-left: -14px;
`;

const NoContentWrapper = styled.div`
  min-height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function ScrollableSubcategoriesMenu({
  preselectedSubcategory,
  showDocumentsOnAction,
  subcategories,
  loading,
}) {
  const filteredVisibleSubcategories = useMemo(
    () => subcategories?.filter((subcategory) => subcategory.visible),
    [subcategories]
  );

  if (!subcategories || loading)
    return (
      <NoContentWrapper>
        <Loader />
      </NoContentWrapper>
    );
  if (!filteredVisibleSubcategories.length)
    return (
      <NoContentWrapper>
        <NoContentCard
          offset={0}
          hideIcon
          title="Sorry, there are no sub-categories"
          description={`There are no sub-categories in this category. We are
        working on adding useful information for you soon!`}
        />
      </NoContentWrapper>
    );
  return (
    <Wrapper>
      <ScrollableMenu indicatorOffset={43} faderHeight={125}>
        {filteredVisibleSubcategories.map((subcategory) => (
          <SubcategoryCard
            showDocumentsOnAction={showDocumentsOnAction}
            subcategory={subcategory}
            isSelected={subcategory.id === preselectedSubcategory}
            key={subcategory.id}
          />
        ))}
      </ScrollableMenu>
    </Wrapper>
  );
}
