import React, { useEffect, useMemo } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  editCategory,
  getCategories,
  createCategory,
  deleteCategory,
  sortCategory as updateOrder,
} from "redux/reducers/categoriesReducer";

import amplitude from "amplitude-js";

export function useCategories() {
  const dispatch = useDispatch();

  const { categories, loading, error } = useSelector(
    ({ categories }) => categories
  );

  const setCategories = React.useCallback(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const updateCategory = React.useCallback(
    (payload, id) => {
      dispatch(editCategory({ payload, id }));
    },
    [dispatch]
  );

  const addCategory = React.useCallback(
    (payload) => {
      return dispatch(createCategory(payload));
    },
    [dispatch]
  );

  const removeCategory = React.useCallback(
    (id) => {
      return dispatch(deleteCategory(id));
    },
    [dispatch]
  );

  const sortCategory = React.useCallback(
    (id, sortOrder) => {
      dispatch(updateOrder({ id, sortOrder }));
    },
    [dispatch]
  );

  useEffect(() => {
    amplitude.getInstance().logEvent("Loading Categories", { loading, error });
  }, [loading, error]);

  const memoState = useMemo(
    () => ({
      categories,
      setCategories,
      updateCategory,
      addCategory,
      removeCategory,
      sortCategory,
      loading,
      error,
    }),
    [
      error,
      loading,
      categories,
      setCategories,
      updateCategory,
      addCategory,
      removeCategory,
      sortCategory,
    ]
  );

  useEffect(() => {
    if (!memoState.categories) {
      dispatch(getCategories());
    }
  }, [dispatch, memoState.categories]);

  return { ...memoState };
}
