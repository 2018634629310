import { Button, Select, Typography } from "components";
import { useScreens } from "hooks";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { ExpertiseContent } from "./ExpertiseContent";

const ExpertiseSelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 300px;
  ${ifProp(
    "tabletScreens",
    css`
      align-items: center;
      margin-bottom: 0;
      justify-content: flex-start;
      flex-direction: row;
    `
  )}

  ${ifProp(
    "withToggle",
    css`
      .MuiSelect-root,
      .MuiSelect-icon,
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    `
  )}

  .MuiPaper-root {
    width: 100vw;
    left: 20px;
  }

  .MuiMenu-paper {
    max-height: 100vh;
  }

  .MuiList-root {
    padding: 0;
  }

  .MuiPaper-root {
    box-shadow: 0 2px 32px #dde0e1;
  }

  .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
  .MuiAutocomplete-root {
    .MuiOutlinedInput-notchedOutline {
      display: block;
    }
  }
`;

const Label = styled(({ tabletScreens, ...rest }) => <Typography {...rest} />)`
  white-space: nowrap;
  margin-right: 12px;
  width: 94px;

  ${ifProp(
    "tabletScreens",
    css`
      width: auto;
    `
  )}
`;

const ContentWrapper = styled.div`
  position: relative;
  overflow-y: auto;
  max-height: ${({ topOffset }) => `calc(100vh - ${topOffset}px)`};
  padding-bottom: 52px;
  ${ifProp(
    "tabletScreens",
    css`
      padding-bottom: 0;
    `
  )}
`;

const SelectWrapper = styled.div`
  flex: 1;
  width: 100%;
`;

const ButtonsContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  column-gap: 8px;
`;

const renderValue = (selected, label, showItemName) => {
  if (selected?.length === 0 || !selected) {
    return (
      <Typography variant="body1" color="darkGray">
        {label}
      </Typography>
    );
  }

  const text = showItemName ? selected[0] : `${selected.length} items selected`;

  return selected?.length ? text : "";
};

export const ExpertiseDropdown = ({
  onApplyClick,
  onCancelClick,
  renderToggleComponent,
  expertiseItems,
  handleSelect,
  selectedExpertise,
  label,
  showItemName,
  onCategoryNameClick,
  isCategoryNameClickable,
  noCapitalizeChipsText,
  isSmallGroupPadding,
}) => {
  const selectRef = useRef(null);
  const params = useParams();
  const [anchorEl, setAnchorEl] = useState(selectRef.current || null);
  const [open, setOpen] = useState(Boolean(params.query) || false);
  const selectContainerRef = useRef(null);
  const [containerTop, setContainerTop] = useState();
  const { tabletScreens } = useScreens();

  const containerRect = selectContainerRef?.current?.getBoundingClientRect();

  useEffect(() => {
    if (!containerRect) return;
    setContainerTop(containerRect.bottom + containerRect.height);
  }, [containerRect]);

  const onOpenSelect = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const onCloseSelect = () => {
    setOpen(false);
  };

  const onApply = () => {
    onApplyClick();
    onCloseSelect();
  };

  const onCancel = () => {
    onCancelClick();
    onCloseSelect();
  };

  return (
    <ExpertiseSelectContainer
      ref={selectContainerRef}
      tabletScreens={tabletScreens}
      topOffset={containerTop}
      withToggle={renderToggleComponent}
    >
      {renderToggleComponent ? (
        renderToggleComponent({ setOpen })
      ) : (
        <Label tabletScreens={tabletScreens}>{label}</Label>
      )}
      <SelectWrapper>
        <Select
          ref={selectRef}
          open={open}
          value={selectedExpertise.map((exp) => exp.ExpertiseName)}
          multiple
          onOpen={(e) => onOpenSelect(e)}
          onClose={onCloseSelect}
          label="Expertise"
          displayEmpty
          renderValue={(selected) =>
            renderValue(selected, `Select ${label}`, showItemName)
          }
          menuProps={{
            anchorEl,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
            open,
            variant: "menu",
          }}
        >
          <ContentWrapper
            topOffset={containerTop}
            tabletScreens={tabletScreens}
          >
            <ExpertiseContent
              onApply={onApply}
              expertiseList={expertiseItems}
              handleSingleSelected={handleSelect}
              selectedItems={selectedExpertise}
              onCloseSelect={onCloseSelect}
              onCategoryNameClick={onCategoryNameClick}
              isCategoryNameClickable={isCategoryNameClickable}
              noCapitalizeChipsText={noCapitalizeChipsText}
              isSmallGroupPadding={isSmallGroupPadding}
            />
          </ContentWrapper>
          {!tabletScreens && (
            <ButtonsContainer>
              <Button
                variant="primary"
                text="Apply"
                width="148px"
                onClick={onApply}
              />
              <Button
                variant="secondary"
                text="Cancel"
                width="148px"
                onClick={onCancel}
              />
            </ButtonsContainer>
          )}
        </Select>
      </SelectWrapper>
    </ExpertiseSelectContainer>
  );
};
