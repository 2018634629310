import { useCallback } from "react";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

const SubcategoryTagsMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  max-width: 1046px;
`;

const MenuItem = styled.div`
  white-space: nowrap;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 6px 24px;
  cursor: pointer;
  background-color: ${props => props.theme.palette.grayLight};
  border-radius: 4px;

  ${ifProp(
    "isSelected",
    css`
      border: none;
      background-color: ${props => props.theme.palette.greenMain};
      color: ${props => props.theme.palette.white};
      transition: all 0.2s ease 0s;
    `
  )}

  ${ifProp(
    "disabled",
    css`
      pointer-events: none;
    `
  )}
`;

export const SubcategoryTagsMenu = ({
  items,
  onClick,
  selectedItems,
  disabled
}) => {
  const handleClick = useCallback(item => onClick(item), [onClick]);

  return (
    <SubcategoryTagsMenuWrapper>
      {items.map(item => (
        <MenuItem
          key={item.id}
          isSelected={selectedItems.find(({ name }) => name === item.name)}
          onClick={() => handleClick(item)}
          disabled={disabled}
        >
          {item.name}
        </MenuItem>
      ))}
    </SubcategoryTagsMenuWrapper>
  );
};
