import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

// Hooks
import { useScreens, useHorizontalScrollOverflow } from 'hooks';

// Components
import { Indicator, Fader } from './components';

// Constants
import { KEYBOARD_ARROW_RIGHT, KEYBOARD_ARROW_LEFT } from 'constants/icons';

const MenuWrapper = styled.div`
  ${ifProp(
    'tabletScreens',
    css`
      position: relative;
    `
  )}
`;

const StyledScrollableTabMenu = styled.div`
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  box-shadow: 0px 0.5px 0px #a1abbb;
	scroll-behavior: smooth;
  margin: 15px 0;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  ${ifProp(
    'tabletScreens',
    css`
      box-shadow: none;
    `
  )}
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  float: left;
  ${ifProp(
    'tabletScreens',
    css`
      padding: 0;
      justify-content: flex-start;
    `
  )}
`;

export const ScrollableMenu = ({ children, indicatorOffset, faderHeight }) => {
  const { tabletScreens } = useScreens();
  const { containerRef, contentRef, onScroll, overflow } =
    useHorizontalScrollOverflow();

	const onIndicatorClick = (direction) => {
		if (direction === "right") containerRef.current.scrollLeft += 250;
		if (direction === "left") containerRef.current.scrollLeft -= 250;
	}

  return (
    <MenuWrapper tabletScreens={tabletScreens}>
      <StyledScrollableTabMenu
        tabletScreens={tabletScreens}
        ref={containerRef}
        onScroll={onScroll}
      >
        <Content tabletScreens={tabletScreens} ref={contentRef}>
          {children}
        </Content>
      </StyledScrollableTabMenu>
      <Indicator
				onClick={() => onIndicatorClick('left')}
        type={KEYBOARD_ARROW_LEFT}
        color="blueAccent"
        overflow={overflow}
        direction="left"
        indicatorOffset={indicatorOffset}
      />
      <Indicator
				onClick={() => onIndicatorClick('right')}
        type={KEYBOARD_ARROW_RIGHT}
        color="blueAccent"
        overflow={overflow}
        direction="right"
				indicatorOffset={indicatorOffset}
      />
      {(overflow === 'left' || overflow === 'both') && (
        <Fader direction="left" faderHeight={faderHeight} />
      )}
      {(overflow === 'right' || overflow === 'both') && (
        <Fader direction="right" faderHeight={faderHeight} />
      )}
    </MenuWrapper>
  );
};
