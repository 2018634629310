import React from 'react';
import PropTypes from 'prop-types';

// Utils
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

// Components
import { Typography, Icon } from 'components';

// Constants
import { SEARCH } from 'constants/index';

// Hooks
import { useScreens } from 'hooks';

const StyledNoContentCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  p {
    font-family: Heebo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.palette.darkGray};
  }
`;

const ContentWrapper = styled.div`
  text-align: center;
  width: 260px;
  ${ifProp(
    'tabletScreens',
    css`
      width: 390px;
      margin-top: ${(props) => props.offset}px;
      color: ${(props) => props.theme.palette.black};
    `
  )}
`;

const TitleWrapper = styled.div`
  margin: 16px 0 12px 0;
  ${ifProp(
    'tabletScreens',
    css`
      margin: 20px 0 12px 0;
    `
  )}
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  .MuiIcon-root {
    height: 60px;
    width: 60px;
  }
`;

export const NoContentCard = ({
  title,
  description,
  offset = 120,
  hideIcon,
  descriptionComponent,
  iconType = SEARCH,
}) => {
  const { tabletScreens } = useScreens();

  return (
    <StyledNoContentCard offset={offset}>
      <ContentWrapper tabletScreens={tabletScreens} offset={offset}>
        {!hideIcon && (
          <IconWrapper>
            <Icon type={iconType} width="80px" />
          </IconWrapper>
        )}
        <TitleWrapper tabletScreens={tabletScreens}>
          <Typography variant="h3" color="blueMain">
            {title}
          </Typography>
        </TitleWrapper>
        {description && (
          <Typography variant="body1" color="darkGray">
            {description}
          </Typography>
        )}
        {descriptionComponent ? descriptionComponent : null}
      </ContentWrapper>
    </StyledNoContentCard>
  );
};

NoContentCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hideButton: PropTypes.bool,
};

NoContentCard.defaultProps = {
  hideButton: false,
};
