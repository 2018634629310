import styled, { css } from "styled-components";
import { OutlinedInput, IconButton, Chip } from "@material-ui/core";
import { Search, Close } from "@material-ui/icons";

// Hooks
import { useScreens } from "hooks";
import { ifProp } from "styled-tools";
import { useEffect } from "react";
import { useRouteMatch } from "react-router";

const InputWrapper = styled.div`
	border: 1px solid rgba(161, 171, 187, 0.5);
	width: 100%;
	margin-right: 0;
	max-width: 340px;
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	align-items: center;
	padding: 0 4px;
	${ifProp('tabletScreens', css`
		max-width: 280px;
		margin-bottom: 0;
		margin-right: 32px;
	`)}
`

const StyledIconButton = styled(IconButton)`
  &.MuiIconButton-root {
    padding: 6px;
  }
  &.MuiIconButton-edgeEnd {
    margin-right: 0;
  }
`;

const Input = styled(({ tabletScreens, ...rest }) => (
  <OutlinedInput {...rest} />
))`
  ${({ theme, tabletScreens }) => `
		.MuiOutlinedInput-input {
			padding: 8px 0;
		}
		&.MuiOutlinedInput-root {
			flex-grow: 1;
			min-width: 100px;
		}
		&.MuiOutlinedInput-adornedEnd {
			padding-right: 10px;
		}
		&.MuiOutlinedInput-adornedStart {
			padding-left: 0;
		}
		&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
			border: none;
		}
		&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border: none;
		}
		&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
			border: none;
		}
		&&& .MuiInputBase-input {
			color: ${theme.palette.black};
			font-size: 14px;
			font-weight: ${theme.typography.body1.fontWeight};
			line-height: ${theme.typography.body1.lineHeight};

			&::placeholder {
				color: ${theme.palette.darkGray};
				opacity: 1 !important;
				font-size: 14px;
				font-weight: ${theme.typography.body1.fontWeight};
				line-height: ${theme.typography.body1.lineHeight};
			}
		}
		${
      tabletScreens &&
      css`
        &.MuiOutlinedInput-root {
          background-color: white;
        }
        &.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border: none;
        }
      `
    }
	`}
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    background: transparent;
    .MuiChip-label {
      background: transparent;
      height: 26px;
      padding: 4px 12px;
      border-radius: 4px;
      text-transform: capitalize;
      display: block;
      align-items: center;
      background-color: ${({ theme }) => theme.palette.grayLight};
      margin-right: 7px;
    }
    .MuiChip-deleteIcon {
      width: 16px;
      color: ${({ theme }) => theme.palette.blueMain};
    }
  }
`;

export const TagsInput = ({
  value,
  setValue,
  onSubmit,
  placeholder,
  tabletVariant,
  onCreateTag,
  onDeleteTag,
  tags,
}) => {
  const match = useRouteMatch();
  const { tabletScreens } = useScreens();

  const extractTagsFromInput = (input) => {
    const inputTags = input.split(",").map((tag) => tag.toLowerCase().trim());
    // Remove input duplicates
    const uniqueInputTags = inputTags.reduce(function (a, b) {
      if (a.indexOf(b) < 0) a.push(b);
      return a;
    }, []);
    // Remove state duplicates
    return uniqueInputTags.filter((newTag) => {
      const hasDuplicate = tags.find((tag) => tag.toLowerCase() === newTag);
      return !hasDuplicate;
    });
  };
  const onChange = (e) => {
    let { value } = e.target;
    if (value[value.length - 1] === "," && value.length !== 1) {
      value = value.slice(0, -1);
      const newTags = extractTagsFromInput(value);
      onCreateTag(newTags);
      setValue("");
      return;
    }
    if (value[value.length - 1] === "," && value.length === 1) {
      setValue("");
      return;
    }
    if (value.includes(",")) {
      const newTags = extractTagsFromInput(value);
      onCreateTag(newTags);
      setValue("");
      return;
    }
    setValue(e.target.value);
  };
  const onSearch = () => {
    onSubmit();
  };
  const onKeyUp = (e) => {
    if (e.code === "Enter" && value) {
      const newTags = extractTagsFromInput(value);
      onCreateTag(newTags);
      setValue("");
    }
  };
  const onKeyDown = (e) => {
    if (e.code === "Backspace" && !value) {
      const index = tags.length - 1;
      onDeleteTag(index);
      setValue("");
    }
  };

  useEffect(() => {
    const isGaNetworkPage = match.url.includes("ga-network");
    const isKnowledgeMapPage = match.url.includes("knowledge-map");

    if ((isGaNetworkPage || isKnowledgeMapPage) && match.params.query) {
      const newTags = extractTagsFromInput(value);
      onCreateTag(newTags);
      setValue("");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <InputWrapper tabletScreens={tabletScreens}>
      <StyledIconButton onClick={onSearch} edge="end">
        <Search fontSize="small" />
      </StyledIconButton>
      {tags.map((tag, i) => (
        <StyledChip
          key={i}
          label={tag}
          deleteIcon={<Close />}
          onDelete={() => onDeleteTag(i)}
        />
      ))}
      <Input
        tabletScreens={Boolean(tabletScreens && tabletVariant)}
        type="text"
        value={value}
        placeholder={!tags.length && !value ? placeholder : ""}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onChange={onChange}
      />
    </InputWrapper>
  );
};
