import React from "react";
import { Draggable } from "react-beautiful-dnd";

const getItemStyle = (isDragging, draggableStyle) => ({
  display: isDragging ? "table" : "table-row",
  ...draggableStyle,
});

export const DraggableComponent =
  (id, index, isDraggable) =>
  ({ children, ...rest }) => {
    return (
      <Draggable draggableId={id} index={index} isDragDisabled={!isDraggable}>
        {(provided, snapshot) => (
          <tr
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...rest}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {children}
          </tr>
        )}
      </Draggable>
    );
  };
