import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export const DroppableComponent = (onDragEnd) => (props) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"1"}>
        {(provided) => {
          return (
            <tbody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </tbody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};
