import styled from "styled-components";
import PropTypes from "prop-types";

//Table Actions
import { TablePaginationActions } from "./TablePaginationActions";

// Components
import { TablePagination as MUITablePagination } from "@material-ui/core";

// Hooks
import { useScreens } from "hooks";

const StyledTablePagination = styled(MUITablePagination)`
  .MuiTablePagination-spacer {
    flex: 0;
  }
  .MuiTablePagination-selectRoot {
    margin-right: auto;
  }

  .MuiTablePagination-caption,
  .MuiTablePagination-selectLabel {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.black};
    line-height: 20px;
  }
`;

export const TablePagination = ({ paginationOptions }) => {
  const { tabletScreens } = useScreens();
  return (
    <StyledTablePagination
      rowsPerPageOptions={
        tabletScreens ? [...paginationOptions.rowsPerPageOptions] : []
      }
      count={paginationOptions.rowsNumber}
      rowsPerPage={paginationOptions.rowsPerPage}
      page={paginationOptions.page}
      SelectProps={{
        inputProps: {
          "aria-label": "rows per page",
        },
        native: true,
      }}
      onPageChange={paginationOptions.handleChangePage}
      onRowsPerPageChange={paginationOptions.handleChangeRowsPerPage}
      component="div"
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} of ${count} items`
      }
      ActionsComponent={TablePaginationActions}
    />
  );
};

TablePagination.propTypes = {
  paginationOptions: PropTypes.shape({
    rowsPerPageOptions: PropTypes.array,
    rowsNumber: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    displayedRowsLabel: PropTypes.string,
  }),
};
