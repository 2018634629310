import { useCallback } from "react";

export function useFilterEmployeeCompanies({
  value: inputValue,
  employeeCompaniesRef,
  setEmployeeCompanies,
}) {
  const filterValue = useCallback(
    (s) => s.toLowerCase().includes(inputValue.toLowerCase()),
    [inputValue]
  );

  const filterByCategoryCallback = useCallback((company, selectedValue) => {
    const { value, label, category } = selectedValue || {};

    if (category === "exitDate" || category === "acquisitionDate") {
      return value !== "active"
        ? company[category] === value
        : company[category] === "";
    }

    return company[category] === label;
  }, []);

  const filterCallback = useCallback(
    (company, selectedValue) => {
      const filterByName = filterValue(company.name.companyName);

      const findByInvestmentSupportTeamMember =
        company.investmentSupportTeam.find(({ name }) => filterValue(name));

      const findByDealTeamMember = company.dealTeam.find(({ name }) =>
        filterValue(name)
      );

      const filterByCategory = filterByCategoryCallback(company, selectedValue);

      return (
        (filterByName ||
          findByInvestmentSupportTeamMember ||
          findByDealTeamMember) &&
        filterByCategory
      );
    },
    [filterValue, filterByCategoryCallback]
  );

  const filterEmployeeCompanies = useCallback(
    (option) =>
      setEmployeeCompanies(
        employeeCompaniesRef.filter((company) =>
          filterCallback(company, option)
        )
      ),
    [employeeCompaniesRef, filterCallback, setEmployeeCompanies]
  );

  return { filterEmployeeCompanies };
}
