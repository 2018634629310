// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import { getOktaApps as fetchOktaApps } from "service";

// Actions
import { GET_OKTA_APPS } from "constants/index";

const initialState = {
  oktaApps: null,
  error: null,
  loading: false
};

export const getOktaApps = createAsyncThunk(
  GET_OKTA_APPS,
  async (username, { rejectWithValue }) => {
    try {
      return await fetchOktaApps(username);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const oktaReducer = createReducer(initialState, builder =>
  builder
    .addCase(getOktaApps.fulfilled, (state, action) => {
      state.oktaApps = action.payload;
      state.loading = false;
    })
    .addCase(getOktaApps.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
