import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  // getSubcategory,
  patchKeyInformation,
  deleteKeyInformation,
  updateKeyInformation,
} from "redux/reducers/subcategoryReducer";

// Actions
import { getSubcategory as getSubcategoryAction } from "redux/reducers/subcategoryReducer";

export function useSubcategory(subcategoryId, noInitialLoad) {
  const dispatch = useDispatch();

  const { subcategory, loading, error } = useSelector(
    ({ subcategory }) => subcategory
  );

  const patchKeyInformationCallback = useCallback(
    (keyInformationData) => {
      dispatch(patchKeyInformation(keyInformationData));
    },
    [dispatch]
  );

  const deleteKeyInformationCallback = useCallback(
    async (keyInformationData) => {
      await dispatch(deleteKeyInformation(keyInformationData));
    },
    [dispatch]
  );

  const editKeyInformationCallback = useCallback(
    (keyInformationData) => {
      dispatch(updateKeyInformation(keyInformationData));
    },
    [dispatch]
  );

  const getSubcategory = useCallback(
    async (subcategoryId) => {
      if (!subcategoryId) return;
      const res = await dispatch(getSubcategoryAction(subcategoryId));

      return res?.payload;
    },
    [dispatch]
  );

  const memoState = useMemo(
    () => ({
      subcategory,
      patchKeyInformation: patchKeyInformationCallback,
      deleteKeyInformation: deleteKeyInformationCallback,
      editKeyInformation: editKeyInformationCallback,
      loading,
      error,
      getSubcategory,
    }),
    [
      error,
      loading,
      subcategory,
      patchKeyInformationCallback,
      deleteKeyInformationCallback,
      editKeyInformationCallback,
      getSubcategory,
    ]
  );

  useEffect(() => {
    if (noInitialLoad) return;
    if (!memoState.subcategory || memoState.subcategory?.id !== subcategoryId) {
      getSubcategory(subcategoryId);
    }
  }, [memoState.subcategory, subcategoryId, getSubcategory, noInitialLoad]);

  return { ...memoState };
}
