// Utils
import styled from "styled-components";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";

const Label = styled.label`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const HiddenInput = styled.input`
  display: none;
`;

export function Input({ accept, onFiles, files, type }) {
  const getFilesFromEvent = (e) =>
    new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });

  return (
    <>
      <Label htmlFor={`input-${type}`} />
      <HiddenInput
        id={`input-${type}`}
        style={{ display: "none" }}
        type="file"
        accept={accept}
        onChange={(e) => {
          files.forEach((f) => f.remove());
          getFilesFromEvent(e).then((chosenFiles) => {
            onFiles(chosenFiles);
          });
        }}
      />
    </>
  );
}
