export const onEditClick = ({
  keyHighlight,
  setKeyHighlightsList,
  setValue,
}) => {
  setKeyHighlightsList((prev) => {
    return prev.map((prev) => {
      if (prev.id === keyHighlight.id) {
        return {
          ...prev,
          isFormMode: true,
        };
      }
      return {
        ...prev,
        isFormMode: false,
      };
    });
  });
  setValue("description", keyHighlight.description);
};

export const onCancelClick = ({ setKeyHighlightsList }) => {
  setKeyHighlightsList((prev) =>
    prev.map((prevCategory) => ({
      ...prevCategory,
      isFormMode: false,
    }))
  );
};

export const onSubmit = async ({
  data,
  id,
  updateKeyHighlight,
  selectedTag,
  subcategory,
  page,
  pageSize,
  query,
}) => {
  await updateKeyHighlight({
    payload: {
      source: {
        id,
      },
      fileMetaDataItems: {
        description: data.description,
      },
    },
    id,
    query,
    size: pageSize,
    subCategoryFilter: [subcategory],
    subCategoryTagFilter: [selectedTag],
    start: page * pageSize,
  });
};

export const onSelectKeyHighlight = async ({
  keyHighlight,
  updateKeyHighlight,
  selectedTag,
  subcategory,
  id,
  page,
  pageSize,
  query,
}) => {
  await updateKeyHighlight({
    payload: {
      source: {
        id,
      },
      fileMetaDataItems: {
        isRecommendedRead: keyHighlight.isRecommendedRead ? "False" : "True",
      },
    },
    id,
    size: pageSize,
    subCategoryFilter: [subcategory],
    query,
    subCategoryTagFilter: [selectedTag],
    start: page * pageSize,
  });
};
