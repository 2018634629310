export const LOGO_LIGHT = 'logo-light';
export const LOGO = 'logo';
export const ARROW_UP = 'arrow-up';
export const LOGOUT = 'logout';
export const LOGOUT_LARGE = 'logout-large';
export const PROFILE = 'profile';
export const APPS = 'apps';
export const MENU = 'menu';
export const ADMIN = 'admin';
export const SEARCH = 'search';
export const SEARCH_MUI = 'search-mui';
export const UBER = 'uber';
export const UBEREZ = 'uberez';
export const UBERITO = 'uberito';
export const CLOSE = 'close';
export const HELP = 'help';
export const CHECKBOX_OUTLINE_SHARP = 'checkbox-outline-sharp';
export const CHECKBOX_SHARP = 'checkbox-sharp';
export const DRAG_DROP = 'drag-drop';
export const DELETE = 'delete';
export const DELETE_OUTLINE = 'delete-outline';
export const VISIBILITY = 'visibility';
export const VISIBILITY_OFF = 'visibility-off';
export const INFO = 'info';
export const TOOLTIP = 'tooltip';
export const ADD = 'add';
export const EDIT = 'edit';
export const STEPPER_COMPLITED = 'stepper-complited';
export const CHECK_CIRCLE = 'check-circle';
export const ERROR = 'error';
export const STAR_BORDER = 'star-border';
export const STAR = 'star';
export const FIRST_PAGE = 'first-page';
export const LAST_PAGE = 'last-page';
export const KEYBOARD_ARROW_LEFT = 'arrow-left';
export const KEYBOARD_ARROW_RIGHT = 'arrow-right';
export const KEYBOARD_ARROW_DOWN = 'keyboard-arrow-down';
export const ARROW_DOWN = 'arrow-down';
export const BACK_ARROW = 'back-arrow';
export const DOC = 'doc';
export const DOCX = 'docx';
export const XLS = 'xls';
export const XLSX = 'xlsx';
export const PDF = 'pdf';
export const PPT = 'ppt';
export const PPTX = 'pptx';
export const HTML = 'html';
export const CSV = 'csv';
export const TXT = 'txt';
export const FOLDER = 'folder';
export const FOLDER1 = 'folder1';
export const DEPARTMENT = 'department';
export const LOCATION = 'location';
export const ASSIGNMENT = 'assignment';
export const OFFICE = 'office';
export const MOBILE = 'mobile';
export const EXPORT = 'export';
export const FILTER = 'filter';
export const LOOP = 'loop';
export const NOTE = 'note';
