import React from "react";

// Utils
import styled, { css } from "styled-components";
import { ifNotProp } from "styled-tools";

// Components
import { Box, IconButton } from "@material-ui/core";
import { Icon } from "components";

// Constants
import {
  FIRST_PAGE,
  LAST_PAGE,
  KEYBOARD_ARROW_LEFT,
  KEYBOARD_ARROW_RIGHT,
} from "constants/icons";

// Hooks
import { useScreens } from "hooks";

const StyledIconButton = styled(({ tabletScreens, ...rest }) => (
  <IconButton {...rest} />
))`
  ${ifNotProp(
    "tabletScreens",
    css`
      padding: 0;
    `
  )}
`;

export const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const { tabletScreens } = useScreens();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box marginLeft={tabletScreens ? "46px" : "auto"}>
      <StyledIconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        tabletScreens={tabletScreens}
      >
        <Icon
          type={FIRST_PAGE}
          color={page === 0 ? "grayMiddle" : "blueAccent"}
        />
      </StyledIconButton>
      <StyledIconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        tabletScreens={tabletScreens}
      >
        <Icon
          type={KEYBOARD_ARROW_LEFT}
          color={page === 0 ? "grayMiddle" : "blueAccent"}
        />
      </StyledIconButton>
      <StyledIconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        tabletScreens={tabletScreens}
      >
        <Icon
          type={KEYBOARD_ARROW_RIGHT}
          color={
            page >= Math.ceil(count / rowsPerPage) - 1
              ? "grayMiddle"
              : "blueAccent"
          }
        />
      </StyledIconButton>
      <StyledIconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        tabletScreens={tabletScreens}
      >
        <Icon
          type={LAST_PAGE}
          color={
            page >= Math.ceil(count / rowsPerPage) - 1
              ? "grayMiddle"
              : "blueAccent"
          }
        />
      </StyledIconButton>
    </Box>
  );
};
