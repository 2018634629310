import React from "react";
import PropTypes from "prop-types";

// Components
import { SelectWithCheckboxes } from "components";

//Hooks
import { useSelectItems, useSurveyLookups } from "hooks";
import { Controller } from "react-hook-form";

export const TagsSelect = ({ label, control, name, isRequired }) => {
  const { sectors } = useSurveyLookups();

  const { itemsList } = useSelectItems(sectors);

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: isRequired }}
      render={({ field: { onChange, value: fieldValue }, fieldState }) => (
        <SelectWithCheckboxes
          onChange={onChange}
          itemsList={itemsList}
          selectedItems={fieldValue}
          label={label}
          error={!!fieldState?.error}
        />
      )}
    />
  );
};

TagsSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};
