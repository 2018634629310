import { useEffect, useMemo, useCallback } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  deleteSubcategory,
  getCategory as getCategoryAction,
  sortSubcategory as updateOrder,
} from "redux/reducers/categoryReducer";

import amplitude from "amplitude-js";

export function useCategory(categoryId) {
  const dispatch = useDispatch();

  const { category, loading, error } = useSelector(({ category }) => category);

  const getCategory = useCallback(
    (categoryId) => {
      dispatch(getCategoryAction(categoryId));
    },
    [dispatch]
  );

  const sortSubcategory = useCallback(
    (id, sortOrder, categoryId) => {
      dispatch(updateOrder({ id, sortOrder, categoryId }));
    },
    [dispatch]
  );

  const removeSubcategory = useCallback(
    (id, categoryId) => {
      return dispatch(deleteSubcategory({ id, categoryId }));
    },
    [dispatch]
  );

  useEffect(() => {
    amplitude.getInstance().logEvent("Loading Single Category", { loading, error });
  }, [loading, error]);

  const memoState = useMemo(
    () => ({
      category,
      loading,
      error,
      getCategory,
      sortSubcategory,
      removeSubcategory,
    }),
    [error, loading, category, getCategory, sortSubcategory, removeSubcategory]
  );

  useEffect(() => {
    dispatch(getCategoryAction(categoryId));
  }, [dispatch, categoryId]);

  return { ...memoState };
}
