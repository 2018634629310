import PropTypes from "prop-types";
import { useHistory } from "react-router";

// Utils
import styled, { css } from "styled-components";
import { ifNotProp, ifProp } from "styled-tools";

// Components
import {
  Paper as MUIPaper,
  // Divider as MUIDivider,
  Box as MUIBox,
} from "@material-ui/core";
import { Icon } from "components/Icon";
import { Loader } from "components";
import { MenuItem } from "components/MenuItem";
import { Typography } from "components/Typography";

// Constants
import { SEARCH_MUI } from "constants/index";

// Hooks
import { useScreens } from "hooks";

const Box = styled(MUIBox)`
  overflow-y: scroll;
`;

const Paper = styled(({ variant, tabletScreens, ...rest }) => (
  <MUIPaper {...rest} />
))`
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 15px;

  .MuiAutocomplete-listbox {
    padding: 0;

    ${ifNotProp(
      "tabletScreens",
      css`
        max-height: 180px;
      `
    )}
  }

  .MuiAutocomplete-option {
    padding: 5px;
    min-height: 34px;
    width: 100%;
  }

  .MuiAutocomplete-noOptions {
    padding: 0 5px;
  }

  ${ifProp(
    { variant: "header" },
    css`
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    `
  )}
`;

// const Divider = styled(MUIDivider)`
//   ${({ theme }) => `
//     margin: 16px 5px;
//     background: ${theme.palette.gray};
//   `}
// `;

const SuggestionIcon = styled(Icon)`
  ${({ theme }) => `
    svg {
      height: 15px;
      width: 15px;
      fill: ${theme.palette.darkGray};
    }
  `}
`;

const StyledMenuItem = styled(MenuItem)`
  margin: 4px 0;
  padding: 4px 0;
  width: 100%;
`;

const SuggestionTypography = styled(Typography)`
  margin-bottom: 8px;
  margin-left: 5px;
`;

const AllResultsMenuItem = styled(MenuItem)`
  margin-top: 6px;
  padding: 4px 5px;
  width: 100%;
`;

export function AutocompletePaper({
  variant,
  options,
  inputValue,
  suggestions,
  children,
  loading,
  ...rest
}) {
  const { tabletScreens } = useScreens();
  const history = useHistory();

  return (
    <Paper {...rest} variant={variant} tabletScreens={tabletScreens}>
      {/* {children} */}
      {/* {Boolean(suggestions?.length) && (
        <>
          <Divider />
          <SuggestionTypography variant="h6" color="darkGray">
            Search Suggestions
          </SuggestionTypography>
        </>
      )} */}
      <Box maxHeight={!tabletScreens ? 100 : 200}>
        {loading ? (
          <Loader />
        ) : !suggestions?.length ? (
          <SuggestionTypography variant="h6" color="darkGray">
            No Results
          </SuggestionTypography>
        ) : (
          suggestions?.map(({ title, link, id }) => (
            <StyledMenuItem
              key={id}
              title={title}
              iconComponent={<SuggestionIcon type={SEARCH_MUI} />}
              typographyVariant="body1"
              typographyColor="darkGray"
              onClick={() => window.open(link, "_blank")}
            />
          ))
        )}
      </Box>
      {inputValue && (
        <AllResultsMenuItem
          title="See All Documents"
          typographyColor="blueAccent"
          onClick={() => history.push(`/search/results/all/${inputValue}`)}
        />
      )}
    </Paper>
  );
}

AutocompletePaper.propTypes = {
  variant: PropTypes.string,
  options: PropTypes.array,
  inputValue: PropTypes.string,
  suggestions: PropTypes.array,
};
