import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  GET_SURVEY_CATEGORIES,
  CREATE_SURVEY_CATEGORY,
  EDIT_SURVEY_CATEGORY,
  DELETE_SURVEY_CATEGORY,
} from "constants/actions";

// Services
import {
  getSurveyCategories as fetchSurveyCategories,
  createSurveyCategory as addSurveyCategory,
  updateSurveyCategory,
  deleteSurveyCategory as removeSurveyCategory,
} from "service";

const initialState = {
  surveyCategories: null,
  error: null,
  loading: true,
};

export const getSurveyCategories = createAsyncThunk(
  GET_SURVEY_CATEGORIES,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchSurveyCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const createSurveyCategory = createAsyncThunk(
  CREATE_SURVEY_CATEGORY,
  async (payload, { rejectWithValue }) => {
    try {
      await addSurveyCategory(payload);
      return await fetchSurveyCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const editSurveyCategory = createAsyncThunk(
  EDIT_SURVEY_CATEGORY,
  async ({ payload, id }, { rejectWithValue }) => {
    try {
      await updateSurveyCategory(payload, id);
      return await fetchSurveyCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteSurveyCategory = createAsyncThunk(
  DELETE_SURVEY_CATEGORY,
  async ({ id }, { rejectWithValue }) => {
    try {
      await removeSurveyCategory(id);
      return await fetchSurveyCategories();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const surveyCategoriesReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getSurveyCategories.fulfilled, (state, action) => {
      state.surveyCategories = action.payload;
      state.loading = false;
    })
    .addCase(getSurveyCategories.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(createSurveyCategory.fulfilled, (state, action) => {
      state.surveyCategories = action.payload;
      state.loading = false;
    })
    .addCase(createSurveyCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(editSurveyCategory.fulfilled, (state, action) => {
      state.surveyCategories = action.payload;
      state.loading = false;
    })
    .addCase(editSurveyCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(deleteSurveyCategory.fulfilled, (state, action) => {
      state.surveyCategories = action.payload;
      state.loading = false;
    })
    .addCase(deleteSurveyCategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
