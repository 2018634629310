export const options = [
  {
    title: "Uber",
    category: "Portfolio Companies",
    icon: "uber",
    link: "uber",
  },
  {
    title: "Uberez Doe",
    category: "GA Network",
    icon: "uberez",
    link: "uberez",
  },
  {
    title: "Uberito Gonzales",
    category: "Knowledge Map",
    icon: "uberito",
    link: "uberito",
  },
];

export const suggestions = [
  {
    title: "Uber",
    link: "uber",
  },
  {
    title: "Uber Industries, LTD",
    link: "uber-industries-ltd",
  },
];
