import { Drawer as MuiDrawer } from '@material-ui/core';
import styled from "styled-components";

import { DrawerHeader, PageMenu, BottomMenu } from './components/';

const StyledMuiDrawer = styled(MuiDrawer)`
	.MuiDrawer-paper {
		width: 267px;
	}
	.MuiDrawer-paperAnchorLeft::-webkit-scrollbar {
		display: none;
	}
	.MuiDrawer-paperAnchorLeft {
		-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
	}
`;

export const Drawer = ({children, ...rest}) => {

	return (
			<StyledMuiDrawer {...rest} >
				<DrawerHeader />
				<PageMenu />
				{children}
				<BottomMenu />
			</StyledMuiDrawer>
	)
}