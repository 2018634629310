import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import { MenuItem } from "@material-ui/core";
import { Checkbox, Typography, Select } from "components";

const StyledCheckbox = styled.div`
  .MuiPaper-root {
    overflow: hidden;
    max-height: 340px;
  }

  .MuiList-root {
    padding: 16px 0;
    max-height: 340px;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.graySuperLight};
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(161, 171, 187, 0.5);
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.palette.grayMiddle};
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }
`;

const ItemWrapper = styled(MenuItem)`
  display: flex;
  padding: 10px 16px;
`;

const CheckboxWrapper = styled.div`
  margin-right: 8px;
`;

const renderValue = (selected, label) => {
  if (selected?.length === 0 || !selected) {
    return (
      <Typography variant="body1" color="darkGray">
        {label}
      </Typography>
    );
  }

  return selected?.length ? `${selected.length} items selected` : "";
};

export const SelectWithCheckboxes = ({
  selectedItems,
  label,
  itemsList,
  onChange,
  error,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledCheckbox>
      <Select
        value={selectedItems}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        label={label}
        multiple
        displayEmpty
        renderValue={(selected) => renderValue(selected, label)}
        menuProps={{ open: isOpen }}
        onChange={onChange}
        error={error}
        disabled={!itemsList?.length}
      >
        {itemsList?.map((item) => {
          return (
            <ItemWrapper key={item.id} value={item.name}>
              <CheckboxWrapper>
                <Checkbox
                  width="15px"
                  color="darkGray"
                  checkedColor="blueAccent"
                  checked={selectedItems.includes(item.name)}
                />
              </CheckboxWrapper>
              <Typography variant="body1">{item.name}</Typography>
            </ItemWrapper>
          );
        })}
      </Select>
    </StyledCheckbox>
  );
};

SelectWithCheckboxes.propTypes = {
  selectedItems: PropTypes.array,
  label: PropTypes.string,
  itemsList: PropTypes.array,
  onChange: PropTypes.func,
  error: PropTypes.bool,
};
