// Axios config
import apiConfig from './config';

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;

const api = apiConfig(baseURL);

export async function searchExternalPersonByNameTags(tags) {
  const query = tags.join(',');
  const { data: zoomInfo } = await await api.get(
    `/compass/ganetwork/zoominfo?searchTerm=${query}&searchType=contactName`
  );

  if (!zoomInfo) return [];

  return zoomInfo?.map((info) => ({
    contactName: info?.contactName,
    contactCompany: info?.company,
    introducerName: info?.introducerName,
    introducerJobTitle: info?.introducerJobTitle,
    country: info?.country,
    jobTitle: info?.jobTitle,
    linkedIn: info?.linkedIn,
    zoomInfoData: info?.zoomInfoData,
  }));
}
export async function clearSearchTags(){
  return [];
}
export async function searchExternalPersonByCompanyTags(tags) {
  const query = tags.join(',');

  const { data: zoomInfo } = await await api.get(
    `/compass/ganetwork/zoominfo?searchTerm=${query}&searchType=contactCompany`
  );

  if (!zoomInfo) return [];

  return zoomInfo?.map((info) => ({
    contactName: info?.contactName,
    contactCompany: info?.company,
    introducerName: info?.introducerName,
    introducerJobTitle: info?.introducerJobTitle,
    country: info?.country,
    jobTitle: info?.jobTitle,
    linkedIn: info?.linkedIn,
    zoomInfoData: info?.zoomInfoData,
  }));
}

export async function searchPersonByNameTags(tags) {
  const resultsChunks = await Promise.all(
    tags?.map((tag) => searchPersonsByName(tag)) ?? []
  );
  if (!resultsChunks?.length) return [];
  const mergedResults = resultsChunks.reduce(
    (acc, chunk) => [...acc, ...chunk],
    []
  );
  const uniqueResults = mergedResults.reduce((acc, currentValue) => {
    const hasDuplicate = acc.find(
      (person) => person.name === currentValue.name
    );
    if (!hasDuplicate) acc.push(currentValue);
    return acc;
  }, []);

  return uniqueResults;
}

export async function searchPersonByExpertiseTags(tags) {
  const resultsChunks = await Promise.all(
    tags.map((tag) => searchPersonsByExpertise(tag))
  );
  const mergedEmailList = resultsChunks.reduce(
    (acc, chunk) => [...acc, ...chunk],
    []
  );
  const filteredEmailList = mergedEmailList.filter((email) => email);
  const uniqueEmailList = filteredEmailList.reduce(function (a, b) {
    if (a.indexOf(b) < 0) a.push(b);
    return a;
  }, []);
  const query = `%22${uniqueEmailList.join('%22,%22')}%22`;

  const { data: response } = await api.get(
    `/compass/expertise-emails?searchTerm=${query}&searchType=Expertise`
  );

  const mappedPersons = extractPersonsFromResponse(response);

  return mappedPersons;
}

export async function searchPersonsByName(query) {
  const { data: response } = await api.get(
    `/compass/searchquery?searchTerm=${query}&searchType=Name`
  );

  const mappedPersons = extractPersonsFromResponse(response);

  return mappedPersons;
}

export async function searchPersonsByExpertise(query) {
  const { data: response } = await api.get(
    `/compass/expertisesearch?searchTerm=${query}&searchType=Expertise`
  );

  const persons = response.hits.hits;
  const emailAddresses = persons.map((person) => {
    return person._source.emailAddress;
  });
  return emailAddresses;
}

const extractPersonsFromResponse = (response) => {
  const expertisesByPerson =
    response?.responses?.[0]?.aggregations?.ExpertiseByPerson?.buckets;
  const persons = response?.responses?.[1]?.hits?.hits;

  return (
    persons?.map((person) => {
      const expertiseData = expertisesByPerson.find(
        (data) => data.key === person._source.fullName
      );
      return {
        name: person?._source?.fullName,
        image: person?._source?.imageUrl,
        firstName: person?._source?.firstName,
        lastName: person?._source?.lastName,
        title: person?._source?.jobTitle,
        location: person?._source?.location,
        sector: person?._source?.department,
        id: person?.fields['emailAddress.enum'].length
          ? person?.fields['emailAddress.enum'][0]
          : '',
        mainExpertise: expertiseData
          ? expertiseData?.Expertise?.ExpertiseItem?.buckets?.map(
              (expertise) => ({
                name: expertise?.key,
              })
            )
          : [],
      };
    }) ?? []
  );
};
