import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import { MenuItem } from "@material-ui/core";
import { Checkbox, Typography, Select } from "components";

const StyledCheckbox = styled.div`
  .MuiList-root {
    padding: 16px 0;
    max-height: 340px;
    overflow: auto;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.palette.graySuperLight};
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }

    ::-webkit-scrollbar-thumb {
      background-color: rgba(161, 171, 187, 0.5);
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.palette.grayMiddle};
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
    }
  }
`;

const ItemWrapper = styled(MenuItem)`
  display: flex;
  padding: 10px 16px;
`;

const CheckboxWrapper = styled.div`
  margin-right: 8px;
`;

const renderValue = (selected, label) => {
  if (selected?.length === 0 || !selected) {
    return (
      <Typography variant="body1" color="darkGray">
        {label}
      </Typography>
    );
  }

  return selected?.length ? `${selected.length} items selected` : "";
};

export const CheckboxSelect = ({ checkboxItems, label, setCheckedItems }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const onSelectItem = (item) => {
    let shouldSetDefault;
    if (
      selectedItems.length === 1 &&
      selectedItems[0].isSelected &&
      item.id !== "All"
    ) {
      shouldSetDefault = true;
    }
    const newCheckboxItems = checkboxItems.map((oldItem) => {
      if (shouldSetDefault && oldItem.id === "All")
        return { ...oldItem, isSelected: false };
      if (item.id === "All" && oldItem.id !== "All")
        return { ...oldItem, isSelected: false };
      return oldItem.id === item.id
        ? { ...oldItem, isSelected: !oldItem.isSelected }
        : { ...oldItem };
    });
    setCheckedItems(newCheckboxItems);
    setIsOpen(true);
  };

  useEffect(() => {
    const newSelectedItems = checkboxItems.filter(
      (item) => item.isSelected === true
    );
    setSelectedItems(newSelectedItems);
    if (!newSelectedItems.length)
      onSelectItem({ name: "All", id: "All", isSelected: true });
    // eslint-disable-next-line
  }, [checkboxItems, setSelectedItems]);

  return (
    <StyledCheckbox>
      <Select
        value={selectedItems}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        label={label}
        multiple
        displayEmpty
        renderValue={(selected) => renderValue(selected, label)}
        menuProps={{ open: isOpen }}
      >
        {checkboxItems?.map((item) => {
          return (
            <ItemWrapper key={item.id} onClick={() => onSelectItem(item)}>
              <CheckboxWrapper>
                <Checkbox
                  width="15px"
                  color="darkGray"
                  checkedColor="blueAccent"
                  checked={item.isSelected}
                />
              </CheckboxWrapper>
              <Typography variant="body1">{item.name}</Typography>
            </ItemWrapper>
          );
        })}
      </Select>
    </StyledCheckbox>
  );
};

CheckboxSelect.propTypes = {
  checkboxItems: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  setCheckedItems: PropTypes.func,
};
