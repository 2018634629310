// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;

const api = apiConfig(baseURL);

export async function getCategories() {
  const { data: response } = await api.get("/compass/categories");

	const { data } = response;
  return data.sort(function (a, b) {
    return a.sortOrder - b.sortOrder;
  });
}

export async function createCategory(payload) {
  const dataToSend = JSON.stringify(payload);
  const { data } = await api.post("/compass/categories", dataToSend);
  return data;
}

export async function updateCategory(payload, id) {
  const dataToSend = JSON.stringify(payload);
  const { data } = await api.patch(
    `/compass/categories/${id}`,
    dataToSend
  );

  return data;
}

export async function sortCategory(id, sortOrder) {
  const { data } = await api.patch(
    `/compass/categories/${id}/sort/${sortOrder}`);

  return data;
}

export async function deleteCategory(id) {
  const { data } = await api.delete(`/compass/categories/${id}`);

  return data;
}

export async function getCategory(categoryId) {
  const { data: response } = await api.get(
    `/compass/categories/${categoryId}/subcategories`);
  return {
    category: response.data.category,
    subcategories: response.data.subCategories
      .map((subcategory) => ({
        ...subcategory,
        categoryName: response.data.category.name,
      }))
      .sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      }),
  };
}
