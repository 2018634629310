import PropTypes from "prop-types";
import { forwardRef } from "react";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { Icon } from "components/Icon";

// Constants
import { ADD } from "constants/index";

const AddIcon = styled(
  forwardRef(({ sizeType, ...props }, ref) => <Icon ref={ref} {...props} />)
)`
  ${({ theme }) => `
    background: ${theme.palette.graySuperLight};
    border-radius: 50px;
    margin: auto;
  `}

  ${ifProp(
    { sizeType: "image" },
    css`
      width: 60px;
      height: 60px;

      svg {
        width: 60px;
        height: 60px;
      }
    `
  )}
`;

const Image = styled(({ alt, type, ...rest }) => <img alt={alt} {...rest} />)`
  width: 100%;
  opacity: 1;

  ${ifProp(
    { type: "image" },
    css`
      object-fit: contain;
    `
  )}
`;

export function PreviewIcon({ src, error, type }) {
  if (src) {
    return <Image alt="Uploaded icon" src={src} type={type} />;
  }

  return (
    <AddIcon type={ADD} color={error ? "red" : "grayMiddle"} sizeType={type} />
  );
}

PreviewIcon.propTypes = {
  src: PropTypes.string,
  error: PropTypes.bool,
  type: PropTypes.string,
};

PreviewIcon.defaultProps = {
  src: "",
  error: false,
  type: "icon",
};
