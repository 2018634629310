export const CATEGORIES = "categories";
export const SECTORS = "sectors";
export const NEWS = "news";
export const ADMIN = "admin";
export const SUBCATEGORIES = "subcategories";
export const DOCUMENTS = "documents";
export const CATEGORY = "category";
export const SUBCATEGORY = "subcategory";
export const EDIT = "edit";
export const CREATE = "create";
export const MODE = "mode";
export const STEP = "step";
export const GENERAL = "general";
export const LINKS = "links";
export const TAGS = "tags";
export const KEY_HIGHLIGHTS = "key-highlights";
export const SEARCH = "search";
export const RESULTS = "results";
export const QUERY = "query";
export const DOCUMENTS_QUERY = "documentsQuery";
export const ALL = "all";
export const TYPE = "type";
export const ID = "id";
export const USERS = "users";
export const COMPANIES = "companies";
export const FILE = "file";
export const KNOWLEDGE_MAP = "knowledge-map";
export const EMPLOYEE_PROFILE = "employee-profile";
export const EMPLOYEE_ID = "employeeId";
export const GA_NETWORK = "ga-network";
export const EXTERNAL_PROFILE = "external-profile";
export const SURVEY_CATEGORIES = "survey-categories";
export const SURVEY_QUESTIONS = "survey-questions";
export const SURVEY_MANAGEMENT = "survey-management";
export const DOCUMENT_PREVIEW = "document-preview";
export const SURVEY_QUESTIONS_CREATE = "survey-question-create";
export const SURVEY_CATEGORY_CREATE = "survey-category-create";
export const SURVEYS = "surveys";
export const SURVEY = "survey";
export const TRANSACTION_RESOURCES = "transaction-resources";
export const BUSINESS_MODELS = "business-models";
