import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Icon } from "components";
import { Typography } from "components";

// Constants
import { VISIBILITY, VISIBILITY_OFF } from "constants/icons";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

export const ItemVisibilityIndicator = ({ isVisible }) => {
  return (
    <Container>
      <IconWrapper>
        <Icon
          type={isVisible ? VISIBILITY : VISIBILITY_OFF}
          color={isVisible ? "darkGray" : "grayMiddle"}
          width="15px"
        />
      </IconWrapper>
      <Typography variant="body1" color="black">
        {isVisible ? "Visible" : "Hidden"}
      </Typography>
    </Container>
  );
};

ItemVisibilityIndicator.propTypes = {
  isVisible: PropTypes.bool,
};
