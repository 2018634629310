import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Actions
import { authentication } from "redux/reducers/authenticationReducer";

// Utils
import { getValue } from "utils";

export function useHandleRedirect() {
  const dispatch = useDispatch();
  const nonce = getValue("nonce");
  const state = getValue("state");
  const realm = getValue("realm");
  const token = getValue("access_token");

  useEffect(() => {
    if (window.location.href.includes("oidc")) {
      const redirectUri = window.location.href;

      if (window.location.href?.includes("&error=access_denied")) {
        return  window.location.href = "/not-assigned";
      }

      if (token || !nonce || !state || !realm) {
        return;
      }

      (async () => {
        try {
          await dispatch(authentication({ redirectUri, nonce, state, realm }));
          window.location.href = "/";
        } catch (e) {
          // Error already handled in authentication reducer
          console.log("Authentication error");
        }
      })();
    }
  }, [dispatch, nonce, realm, state, token]);
}
