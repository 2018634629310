import { useState, useRef, useEffect } from 'react';

export const useHorizontalScrollOverflow = () => {
  const containerRef = useRef(null);
	const contentRef = useRef(null);
	const [overflow, setOverflow] = useState('');

	function determineOverflow(content, container) {
    var containerMetrics = container.current.getBoundingClientRect();
    var containerMetricsRight = Math.floor(containerMetrics.right);
    var containerMetricsLeft = Math.floor(containerMetrics.left);
    var contentMetrics = content.current.getBoundingClientRect();
    var contentMetricsRight = Math.floor(contentMetrics.right);
    var contentMetricsLeft = Math.floor(contentMetrics.left);
    if (
      containerMetricsLeft > contentMetricsLeft &&
      containerMetricsRight < contentMetricsRight
    ) {
      return 'both';
    } else if (contentMetricsLeft < containerMetricsLeft) {
      return 'left';
    } else if (contentMetricsRight > containerMetricsRight) {
      return 'right';
    } else {
      return 'none';
    }
  }

	const onScroll = (e) => {
		setOverflow(determineOverflow(contentRef, containerRef))
	}
	useEffect(() => {
		setOverflow(determineOverflow(contentRef, containerRef))
	}, [])

  return {
    containerRef,
    contentRef,
    onScroll,
    overflow,
  };
};
