import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import { useHistory, useParams } from "react-router";

// Hooks
import { useScreens } from "hooks";
import { Typography, SearchInput } from "components";

// Constants
import {
  SUBCATEGORY,
  SEARCH,
  RESULTS,
  SUBCATEGORIES,
  DOCUMENTS,
} from "constants/index.js";

const SectionWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  margin: 20px 15px 28px;
  ${ifProp(
    "tabletScreens",
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 16px;
      padding: 12px 20px;
      border-radius: 4px 4px 0px 0px;
      background-color: ${({ theme }) => theme.palette.grayLight};
    `
  )}
`;

const StyledTypography = styled(({ tabletScreens, ...rest }) => (
  <Typography {...rest} />
))`
  margin-bottom: 16px;
  ${ifProp(
    "tabletScreens",
    css`
      margin-right: 18px;
      margin-bottom: 0;
    `
  )}
`;

export function SearchSection({ subcategory, query: initialQuery, redirect }) {
  const params = useParams();
  const history = useHistory();
  const { tabletScreens } = useScreens();
  const [query, setQuery] = useState(params?.query || initialQuery);
  const onSearch = () => {
    history.push(
      `/${SEARCH}/${RESULTS}/${SUBCATEGORY}/${query}/${subcategory.id}`
    );
  };

  const onClear = () => {
    if (query && redirect)
      history.push(`/${SUBCATEGORIES}/${subcategory.id}/${DOCUMENTS}`);
  };

  useEffect(() => {
    if (params?.query) {
      onSearch();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SectionWrapper tabletScreens={tabletScreens}>
      <StyledTypography
        variant={tabletScreens ? "h2" : "h3"}
        color="blueMain"
        tabletScreens={tabletScreens}
      >
        Documents
      </StyledTypography>
      <SearchInput
        value={query}
        setValue={setQuery}
        onSubmit={onSearch}
        placeholder="Search for information, resource..."
        tabletVariant
        clearButton
        onClear={onClear}
      />
    </SectionWrapper>
  );
}
