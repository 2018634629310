// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const ocpKey = `${process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY}`;

const api = apiConfig(baseURL, ocpKey);

export async function createKeyInformationLinks(payload) {
  const dataToSend = JSON.stringify(payload);
  const { data } = await api.post("/compass/keyinformation", dataToSend);

  return data;
}

export async function getKeyInformationList(subcategoryId) {
  const { data: response } = await api.get(
    `/compass/subcategories/${subcategoryId}/keyinformation`);

  return response.data;
}
