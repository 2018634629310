import React, { useEffect, useMemo } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import { deleteSurvey, getSurveys } from "redux/reducers/surveysReducer";

export function useSurveys() {
  const dispatch = useDispatch();

  const { surveys, loading, error } = useSelector(({ surveys }) => surveys);

  const setSurveys = React.useCallback(() => {
    dispatch(getSurveys());
  }, [dispatch]);

  const removeSurvey = React.useCallback(
    (id) => {
      return dispatch(deleteSurvey(id));
    },
    [dispatch]
  );

  const memoState = useMemo(
    () => ({
      surveys,
      setSurveys,
      removeSurvey,
      loading,
      error,
    }),
    [error, loading, surveys, setSurveys, removeSurvey]
  );

  useEffect(() => {
    if (!memoState.surveys) {
      dispatch(getSurveys());
    }
  }, [dispatch, memoState.surveys, loading]);

  return { ...memoState };
}
