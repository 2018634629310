import React from "react";
import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { Typography } from "components";

// Hooks
import { useScreens } from "hooks";

const Container = styled.div`
	width: 300px;
  margin-bottom: 8px;
  display: flex;
  justify-content: start;
	flex-direction: column;

  ${ifProp(
    "tabletScreens",
    css`
      margin-bottom: 0;
			flex-direction: row;
			align-items: center;
      

      &:not(:last-of-type) {
        margin-right: 32px;
      }
    `
  )}
  ${ifProp(
    "laptopScreens",
    css`
      margin-bottom: 0;
      width: ${({ customWidth }) => customWidth};
      justify-content: flex-start;
      &:not(:last-of-type) {
        margin-right: 32px;
      }
    `
  )}
`;

const Label = styled(({ tabletScreens, ...rest }) => <Typography {...rest} />)`
  white-space: nowrap;
  margin-right: 12px;
  width: 94px;

  ${ifProp(
    "tabletScreens",
    css`
      width: auto;
    `
  )}
`;

const SelectWrapper = styled.div`
  flex: 1;
	width: 100%

  ${ifProp(
    "tabletScreens",
    css`
      flex: 0 1 auto;
      width: 194px;
    `
  )}
  ${ifProp(
    "laptopScreens",
    css`
      flex: 1;
    `
  )}
`;

export const SelectFilterWrapper = ({ label, customWidth, children }) => {
  const { tabletScreens, laptopScreens } = useScreens();
  return (
    <Container
      tabletScreens={tabletScreens}
      laptopScreens={laptopScreens}
      customWidth={customWidth}
    >
      <Label tabletScreens={tabletScreens}>{label}</Label>
      <SelectWrapper
        tabletScreens={tabletScreens}
        laptopScreens={laptopScreens}
      >
        {children}
      </SelectWrapper>
    </Container>
  );
};

SelectFilterWrapper.propTypes = {
  label: PropTypes.string,
  customWidth: PropTypes.string,
};
