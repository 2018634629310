import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

export const MenuItem = styled.div`
  padding: 0 8px 4px;
  white-space: nowrap;
  margin-right: 10px;
  ${ifProp(
    "tabletScreens",
    css`
      padding: 6px 24px;
      cursor: pointer;
      background-color: ${(props) => props.theme.palette.grayLight};
      border-radius: 4px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
    `
  )}
  ${ifProp(
    "isSelected",
    css`
      border-bottom: 2px solid ${(props) => props.theme.palette.blueAccent};
    `
  )} 
    ${ifProp(
    "isDisabled",
    css`
      border: none;
      background-color: ${({ theme }) => theme.palette.grayLight};
      transition: all 0.2s ease 0s;
      cursor: not-allowed;
    `
  )}
  ${ifProp(
    ["isSelected", "tabletScreens"],
    css`
      border: none;
      background-color: ${(props) => props.theme.palette.blueAccent};
      transition: all 0.2s ease 0s;
    `
  )}
`;
