import { useEffect, useMemo, useCallback } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import {
  getSubcategoryTags as getSubcategoryTagsAction,
  createSubcategoryTag,
  deleteSubcategoryTag,
} from "redux/reducers/subcategoryTagsReducer";
import amplitude from "amplitude-js";

export function useSubcategoryTags() {
  const dispatch = useDispatch();

  const { subcategoryTags, loading, error } = useSelector(
    ({ subcategoryTags }) => subcategoryTags
  );

  const getSubcategoryTags = useCallback(() => {
    dispatch(getSubcategoryTagsAction());
  }, [dispatch]);

  const setSubcategoryTags = useCallback(() => {
    dispatch(getSubcategoryTagsAction());
  }, [dispatch]);

  const addSubcategoryTag = useCallback(
    (payload) => {
      dispatch(createSubcategoryTag(payload));
    },
    [dispatch]
  );

  const removeSubcategoryTag = useCallback(
    (id, name) => {
      return dispatch(deleteSubcategoryTag({ id, name }));
    },
    [dispatch]
  );

  const memoState = useMemo(
    () => ({
      subcategoryTags,
      loading,
      error,
      getSubcategoryTags,
      setSubcategoryTags,
      addSubcategoryTag,
      removeSubcategoryTag,
    }),
    [
      error,
      loading,
      subcategoryTags,
      getSubcategoryTags,
      setSubcategoryTags,
      addSubcategoryTag,
      removeSubcategoryTag,
    ]
  );

  useEffect(() => {
    amplitude
      .getInstance()
      .logEvent("Loading Subcategory Tags", { loading, error });
  }, [loading, error]);

  useEffect(() => {
    if (!memoState.subcategoryTags) {
      dispatch(getSubcategoryTagsAction());
    }
  }, [dispatch, memoState.subcategoryTags]);

  return { ...memoState };
}
