import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { DrawerMenuItem } from './DrawerMenuItem';

// Contexts
import { useDrawer } from 'contexts';

const PAGE_MENU_ITEMS = [
  {
    title: 'Home',
    pathname: '/',
  },
];

const Wrapper = styled.div`
  border-bottom: 0.5px solid rgba(161, 171, 187, 0.4);
  border-top: 1px solid rgba(161, 171, 187, 0.4);
`;

const StyledDrawerMenuItem = styled(DrawerMenuItem)`
  padding: 16px 15px;
	cursor: pointer;
`;

export const PageMenu = () => {
  const { toggleDrawer, setSelectedMenuItem, selectedMenuItem } = useDrawer();
  const history = useHistory();

  const handleClick = (path, title) => {
    history.push(path);
    setSelectedMenuItem(title);
		toggleDrawer(false);
  };

  useEffect(() => {
    PAGE_MENU_ITEMS.forEach((item) => {
      if (window.location.pathname === item.pathname) {
        setSelectedMenuItem(item.title);
      }
    });
  }, [setSelectedMenuItem]);

  return (
    <Wrapper>
      {PAGE_MENU_ITEMS.map((item) => (
        <StyledDrawerMenuItem
          title={item.title}
          typographyColor={selectedMenuItem === item.title ? 'white' : 'blueMain'}
          key={item.title}
          onClick={() => handleClick(item.pathname, item.title)}
          selected={selectedMenuItem === item.title}
        />
      ))}
    </Wrapper>
  );
};
