import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { QuestionInfoForm } from "./components";
import { QuestionDetailsForm } from "./components/QuestionDetailsForm";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
`;

export const QuestionForm = ({
  question,
  control,
  onCancelClick,
  handleSubmit,
}) => {
  return (
    <Container>
      <QuestionInfoForm
        question={question}
        control={control}
        onCancelClick={onCancelClick}
        handleSubmit={handleSubmit}
      />
      <QuestionDetailsForm question={question} control={control} />
    </Container>
  );
};

QuestionForm.propTypes = {
  question: PropTypes.object,
  onCancelClick: PropTypes.func,
};
