// Utils
import { combineReducers } from "@reduxjs/toolkit";

// Reducers
import { authenticationReducer } from "./authenticationReducer";
import { userReducer } from "./userReducer";
import { oktaReducer } from "./oktaReducer";
import { categoriesReducer } from "./categoriesReducer";
import { subcategoriesReducer } from "./subcategoriesReducer";
import { subcategoryTagsReducer } from "./subcategoryTagsReducer";
import { categoryReducer } from "./categoryReducer";
import { linearProgressReducer } from "./linearProgressReducer";
import { toastReducer } from "./toastReducer";
import { subcategoryReducer } from "./subcategoryReducer";
import { keyInformationReducer } from "./keyInformationReducer";
import { keyHighlightsReducer } from "./keyHighlightsReducer";
import { newsReducer } from "./newsReducer";
import { surveyCategoriesReducer } from "./surveyCategoriesReducer";
import { surveyQuestionsReducer } from "./surveyQuestionsReducer";
import { surveyLookupsReducer } from "./surveyLookupsReducer";
import { surveysReducer } from "./surveysReducer";

export const rootReducer = combineReducers({
  authentication: authenticationReducer,
  okta: oktaReducer,
  user: userReducer,
  categories: categoriesReducer,
  category: categoryReducer,
  subcategories: subcategoriesReducer,
  subcategoryTags: subcategoryTagsReducer,
  linearProgress: linearProgressReducer,
  toast: toastReducer,
  subcategory: subcategoryReducer,
  keyInformationList: keyInformationReducer,
  keyHighlights: keyHighlightsReducer,
  news: newsReducer,
  surveyCategories: surveyCategoriesReducer,
  surveyQuestions: surveyQuestionsReducer,
  surveyLookups: surveyLookupsReducer,
  surveys: surveysReducer,
});
