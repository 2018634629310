import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Avatar as MUIAvatar, Box } from "@material-ui/core";
import { Typography } from "components/Typography";

const UserAvatar = styled(MUIAvatar)`
  width: 55px;
  height: 55px;
`;

export function PageHeaderUserInfo({ fullName, email, imgSrc }) {
  return (
    <Box display="flex" alignItems="center" mb={2.5}>
      <UserAvatar alt={fullName} src={imgSrc} />
      <Box display="flex" flexDirection="column" ml={1}>
        <Typography variant="h6" color="black">
          {fullName}
        </Typography>
        <Typography variant="srOnly" color="darkGray">
          {email}
        </Typography>
      </Box>
    </Box>
  );
}

PageHeaderUserInfo.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string,
  imgSrc: PropTypes.string,
};
