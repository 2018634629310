// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import {
  getSubcategoryTags as fetchSubcategoryTags,
  createSubcategoryTag as addSubcategoryTag,
  deleteSubcategoryTag as removeSubcategoryTag,
} from "service";

// Actions
import {
  GET_SUBCATEGORY_TAGS,
  CREATE_SUBCATEGORY_TAGS,
  DELETE_SUBCATEGORY_TAG,
} from "constants/index";

const initialState = {
  subcategoryTags: null,
  error: null,
  loading: true,
};

export const getSubcategoryTags = createAsyncThunk(
  GET_SUBCATEGORY_TAGS,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchSubcategoryTags();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const createSubcategoryTag = createAsyncThunk(
  CREATE_SUBCATEGORY_TAGS,
  async (payload, { rejectWithValue }) => {
    try {
      await addSubcategoryTag(payload);
      return await fetchSubcategoryTags();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteSubcategoryTag = createAsyncThunk(
  DELETE_SUBCATEGORY_TAG,
  async ({ id, name }, { rejectWithValue }) => {
    try {
      await removeSubcategoryTag(id, name);
      return await fetchSubcategoryTags();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const subcategoryTagsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getSubcategoryTags.fulfilled, (state, action) => {
      state.subcategoryTags = action.payload;
      state.loading = false;
    })
    .addCase(getSubcategoryTags.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(createSubcategoryTag.fulfilled, (state, action) => {
      state.subcategoryTags = action.payload;
      state.loading = false;
    })
    .addCase(createSubcategoryTag.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(deleteSubcategoryTag.fulfilled, (state, action) => {
      state.subcategoryTags = action.payload;
      state.loading = false;
    })
    .addCase(deleteSubcategoryTag.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
