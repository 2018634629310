import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

// Utils
import { Controller } from "react-hook-form";
import styled from "styled-components";

// Components
import MenuItem from "@material-ui/core/MenuItem";
import { InputLabel } from "components/InputLabel";
import { Select } from "components";

const SelectWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;

  .MuiList-padding {
    margin: 0;
    padding: 8px;
  }
`;

export function FormSelect({
  inputProps: {
    label,
    tooltip,
    optionsData,
    preselected,
    labelTypographyVariant,
    ...inputProps
  },
  controllerProps,
}) {
  const isFirstRenderRef = useRef(true);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <Controller
      render={({
        field: { onChange, value: fieldValue, ...restFieldProps },
        formState: { errors },
      }) => {
        if (isFirstRenderRef.current) {
          onChange(
            preselected
              ? preselected
              : isNaN(fieldValue)
              ? fieldValue[0].value
              : fieldValue
          );
          isFirstRenderRef.current = false;
        }

        return (
          <>
            <InputLabel
              text={label}
              tooltip={tooltip}
              typographyVariant={labelTypographyVariant}
            />
            <SelectWrapper>
              <Select
                fieldProps={restFieldProps}
                onChange={onChange}
                onOpen={(e) => setAnchorEl(e.currentTarget)}
                variant="outlined"
                error={!!errors?.[controllerProps.name]}
                disabled={inputProps.disabled}
                value={
                  preselected
                    ? preselected
                    : isNaN(fieldValue)
                    ? fieldValue[0].value
                    : fieldValue
                }
                menuProps={{
                  anchorEl: anchorEl,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {optionsData.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </SelectWrapper>
          </>
        );
      }}
      {...controllerProps}
    />
  );
}

FormSelect.propTypes = {
  inputProps: PropTypes.shape({
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    tooltip: PropTypes.string,
    preselected: PropTypes.number,
    labelTypographyVariant: PropTypes.string,
  }),
  controllerProps: PropTypes.shape({
    control: PropTypes.object,
    name: PropTypes.string.isRequired,
    rules: PropTypes.object,
    tooltip: PropTypes.string,
  }),
};

FormSelect.defaultProps = {
  inputProps: PropTypes.shape({
    label: "",
    autoFocus: false,
    placeholder: "",
    tooltip: "",
    labelTypographyVariant: "h6",
    preselected: null,
  }),
};
