import React from "react";

// Utils
import styled from "styled-components";

// Components
import { ExpertiseGroup } from "./ExpertiseGroup";
import { ExpertiseSearch } from "./ExpertiseSearch";

const Container = styled.div`
  padding: 24px;
  position: relative;
`;

export const ExpertiseContent = ({
  expertiseList,
  handleSingleSelected,
  selectedItems,
  onCloseSelect,
  onApply,
  onCategoryNameClick,
  isCategoryNameClickable,
  noCapitalizeChipsText,
  isSmallGroupPadding,
}) => {
  return (
    <Container>
      <ExpertiseSearch
        expertiseList={expertiseList}
        handleSingleSelected={handleSingleSelected}
        selectedItems={selectedItems}
        onCloseSelect={onCloseSelect}
        isCategoryNameClickable={isCategoryNameClickable}
        onCategoryNameClick={onCategoryNameClick}
        onApply={onApply}
        noCapitalizeChipsText={noCapitalizeChipsText}
      />
      {expertiseList.map((expertiseGroup) => {
        return (
          <ExpertiseGroup
            key={expertiseGroup.CategoryId}
            expertiseGroup={expertiseGroup}
            handleSingleSelected={handleSingleSelected}
            onCategoryNameClick={onCategoryNameClick}
            isCategoryNameClickable={isCategoryNameClickable}
            isSmallGroupPadding={isSmallGroupPadding}
          />
        );
      })}
    </Container>
  );
};
