import React from 'react';

// Components
import {
  Icon,
  Typography,
  EditDeleteButtons,
  TextInput,
  FormTableRowActionButtons,
  CustomLink,
} from 'components';
import { Box, IconButton as MUIIconButton } from '@material-ui/core';

// Utils
import styled from 'styled-components';

// Constants
import { STAR_BORDER, STAR } from 'constants/icons';

import {
  onCancelClick,
  onEditClick,
  onSelectKeyHighlight,
  onSubmit,
} from './keyHighlightsUtils';

import { formatDate } from 'utils/formatDate';
import { getIconType } from 'utils/getIconType';

const NameWrapper = styled.div`
  width: 73%;
`;
const Description = styled(Typography)`
  margin-top: 8px;
  strong {
    background-color: rgba(230, 239, 255, 0.6);
  }
`;
const IconButton = styled(MUIIconButton)`
  &:hover {
    background: none;
  }
`;

const TextInputWrapper = styled.div`
  width: 280px;
  .MuiFormControl-root {
    margin-bottom: 0;
  }
`;
const Title = styled(Typography)`
  strong {
    background-color: rgba(230, 239, 255, 0.6);
  }
`;
export const useRows = ({
  keyHighlightsList,
  control,
  setKeyHighlightsList,
  setValue,
  handleSubmit,
  updateKeyHighlight,
  selectedTag,
  subcategory,
  page,
  pageSize,
  query,
}) => {
  const rows = React.useMemo(() => {
    return keyHighlightsList?.map((keyHighlight) => {
      const isRecommended = keyHighlight.isRecommendedRead;

      return [
        {
          component: (
            <IconButton
              onClick={() =>
                onSelectKeyHighlight({
                  keyHighlight,
                  updateKeyHighlight,
                  id: keyHighlight.id,
                  selectedTag,
                  subcategory,
                  page,
                  pageSize,
                  query,
                })
              }
            >
              <Icon
                type={isRecommended ? STAR : STAR_BORDER}
                color={isRecommended ? 'orange' : 'blueAccent'}
                width="20px"
              />
            </IconButton>
          ),
          name: 'star',
          width: '2%',
          color: isRecommended && 'rgba(255, 157, 66, 0.08)',
        },
        {
          component: (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Icon
                type={getIconType(keyHighlight.documentType)}
                color="white"
                width="34px"
              />
              <NameWrapper>
                <CustomLink
                  href={`${process.env.REACT_APP_BOX_URL}/file/${keyHighlight.id}`}
                  variant="body1"
                  newTab
                >
                  <Title
                    variant="body1"
                    dangerouslySetInnerHTML={{ __html: keyHighlight.name }}
                    color="blueAccent"
                  />
                </CustomLink>
              </NameWrapper>
            </Box>
          ),
          name: 'name',
          width: '30%',
          color: isRecommended && 'rgba(255, 157, 66, 0.08)',
        },
        {
          component: keyHighlight.isFormMode ? (
            <TextInputWrapper>
              <TextInput
                inputProps={{
                  placeholder: 'Description',
                  multiline: true,
                }}
                controllerProps={{
                  control,
                  name: 'description',
                  rules: {
                    maxLength: {
                      value: 450,
                      message: 'Max Length: 450 symbols',
                    },
                  },
                }}
              />
            </TextInputWrapper>
          ) : (
            <Description
              variant="caption"
              color="black"
              dangerouslySetInnerHTML={{ __html: keyHighlight.description }}
            ></Description>
          ),
          name: 'description',
          width: '30%',
          color: isRecommended && 'rgba(255, 157, 66, 0.08)',
        },
        {
          component: (
            <Typography variant="body1" color="black">
              {formatDate(
                keyHighlight?.source?.modifiedAt ||
                  keyHighlight.lastModifiedDate
              )}
            </Typography>
          ),
          name: 'date',
          width: '30%',
          color: isRecommended && 'rgba(255, 157, 66, 0.08)',
        },
        {
          component: keyHighlight.isFormMode ? (
            <FormTableRowActionButtons
              control={control}
              onCancelClick={() =>
                onCancelClick({
                  setKeyHighlightsList,
                })
              }
              onSaveClick={handleSubmit((data) =>
                onSubmit({
                  data,
                  id: keyHighlight.id,
                  updateKeyHighlight,
                  selectedTag,
                  subcategory,
                  page,
                  pageSize,
                  query,
                })
              )}
            />
          ) : (
            <EditDeleteButtons
              isEditOnly
              onEditClick={() =>
                onEditClick({
                  keyHighlight,
                  setKeyHighlightsList,
                  setValue,
                })
              }
            />
          ),
          name: 'action',
          width: '8%',
          color: isRecommended && 'rgba(255, 157, 66, 0.08)',
        },
      ];
    });
  }, [
    keyHighlightsList,
    control,
    setValue,
    setKeyHighlightsList,
    handleSubmit,
    updateKeyHighlight,
    selectedTag,
    subcategory,
    page,
    pageSize,
    query,
  ]);

  return rows;
};
