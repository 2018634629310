import React, { useEffect, useState } from "react";

export const useSelectItems = (items) => {
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    if (!items) return;
    setItemsList(
      items?.map((item) => ({
        ...item,
        isSelected: item?.isSelected || false,
      })) ?? []
    );
  }, [items]);

  const onSetFirstSelected = React.useCallback((items) => {
    setItemsList(
      items.map((item, i) => {
        if (i === 0) {
          return {
            ...item,
            isSelected: true,
          };
        }
        return (
          {
            ...item,
            isSelected: false,
          } ?? []
        );
      })
    );
  }, []);

  const handleSingleSelected = React.useCallback((selectedItem) => {
    setItemsList((prev) => {
      return prev.map((prevItem) => {
        if (prevItem.id === selectedItem.id) {
          return {
            ...prevItem,
            isSelected: !prevItem.isSelected,
          };
        }
        return prevItem;
      });
    });
  }, []);

  const handleOnlyOneSelected = (selectedItem) => {
    setItemsList((prev) => {
      return prev.map((prevItem) => {
        if (prevItem.id === selectedItem.id) {
          return {
            ...prevItem,
            isSelected: true,
          };
        }
        return {
          ...prevItem,
          isSelected: false,
        };
      });
    });
  };

  const handleAllSelected = () => {
    setItemsList((prev) => {
      if (prev.every((prevItem) => prevItem.isSelected)) {
        return prev.map((prevItem) => ({
          ...prevItem,
          isSelected: false,
        }));
      }
      return prev.map((prevItem) => ({
        ...prevItem,
        isSelected: true,
      }));
    });
  };

  const cancelSelection = () => {
    setItemsList((prev) => {
      return prev.map((prevItem) => ({
        ...prevItem,
        isSelected: false,
      }));
    });
  };

  const selectedItems = React.useMemo(() => {
    return itemsList?.filter((item) => item.isSelected);
  }, [itemsList]);

  const numOfSelected = React.useMemo(() => {
    return selectedItems?.length;
  }, [selectedItems]);

  return {
    itemsList,
    setItemsList,
    handleSingleSelected,
    handleAllSelected,
    numOfSelected,
    selectedItems,
    cancelSelection,
    handleOnlyOneSelected,
    onSetFirstSelected,
  };
};
