import React from "react";

// Components
import { Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Hooks
import { useModal } from "contexts";
import { useScreens } from "hooks";

const ModalContainer = styled(
  ({ tabletScreens, laptopScreens, modalWidth, isScrollDisabled, ...rest }) => (
    <Dialog {...rest} />
  )
)`
  .MuiPaper-root {
    margin: 0;
    width: 92%;
    min-height: 300px;
    max-width: 92%;
    ${ifProp(
      "tabletScreens",
      css`
        ${({ modalWidth }) => `
      width: ${modalWidth ?? "75%"};
      max-width: ${modalWidth ?? "75%"};
      `}
      `
    )}
    ${ifProp(
      "laptopScreens",
      css`
        ${({ modalWidth }) => `
      width: ${modalWidth ?? "840px"};
      max-width: ${modalWidth ?? "840px"};
      
      `}
      `
    )}
  }
  .MuiBackdrop-root {
    background-color: rgba(1, 8, 19, 0.2);
  }
  .MuiDialog-paper {
    ${ifProp(
      "isScrollDisabled",
      css`
        overflow: hidden;
      `,
      css`
        overflow: auto;
      `
    )}
  }
`;

const Button = styled(({ tabletScreens, ...rest }) => <IconButton {...rest} />)`
  padding: 0;
  color: ${({ theme }) => theme.palette.darkGray};
  &:hover {
    background: none;
  }
  margin: ${ifProp("tabletScreens", "32px 32px 0 auto", "24px 24px 0 auto")};
`;

const Close = styled(({ tabletScreens, ...rest }) => <CloseIcon {...rest} />)`
  font-size: ${ifProp("tabletScreens", "32px", "24px")};
`;

export const Modal = () => {
  const { modalContent, onCloseModal, modalWidth, isScrollDisabled } =
    useModal();
  const { tabletScreens, laptopScreens } = useScreens();

  return (
    <>
      {modalContent && (
        <ModalContainer
          open={true}
          onClose={onCloseModal}
          fullWidth
          tabletScreens={tabletScreens}
          laptopScreens={laptopScreens}
          modalWidth={modalWidth}
          isScrollDisabled={isScrollDisabled}
        >
          <Button
            aria-label="close"
            onClick={onCloseModal}
            tabletScreens={tabletScreens}
          >
            <Close tabletScreens={tabletScreens} />
          </Button>

          {modalContent}
        </ModalContainer>
      )}
    </>
  );
};
