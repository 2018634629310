// Utils
import styled, { css } from "styled-components";

// Components
import { IconButton as MUIIconButton } from "@material-ui/core";
import { Icon, Typography } from "components";

// Constants
import { FILTER } from "constants/index";
import { useScreens } from "hooks";
import { ifProp } from "styled-tools";

const IconButton = styled(MUIIconButton)`
  ${({ theme, isSelected, largeTabletScreens }) => `
    height: 100%;
    width: 100%;
		margin-left: ${largeTabletScreens ? "32px" : "0"};
    border-radius: 4px;
    border: 1px solid ${theme.palette.blueAccent};
		&&&.MuiButtonBase-root.MuiIconButton-root {
			background-color: ${isSelected ? theme.palette.blueAccent : "white"};
		}
		

    > span {
      margin-right: 8px;
    }

    path {
      fill: ${isSelected ? "white" : theme.palette.blueAccent};
    }
  `}
`;

const StyledButton = styled.div`
  width: 100%;
  height: 35px;
  max-width: 140px;
  text-decoration: none;
  ${ifProp(
    "tabletScreens",
    css`
      max-width: 150px;
    `
  )}
`;

export function FilterButton({ isSelected, filterOnly, ...props }) {
  const { largeTabletScreens } = useScreens();
  return (
    <StyledButton {...props}>
      <IconButton
        isSelected={isSelected}
        largeTabletScreens={largeTabletScreens}
      >
        <Icon type={FILTER} color={"white"} />
        {
          <Typography variant="h6" color={isSelected ? "white" : "blueAccent"}>
            {filterOnly ? "Filter" : "Sort/Filter"}
          </Typography>
        }
      </IconButton>
    </StyledButton>
  );
}
