import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Constants
import { Tag } from "components/Tag/Tag";

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tags = ({
  tags,
  isSelectable,
  selectedId,
  setSelected,
  resetNameTags,
  noCapitalize,
}) => {
  const onClick = (tag) => {
    setSelected && setSelected(tag);
    resetNameTags && resetNameTags();
  };
  return (
    <TagsContainer>
      {tags?.map((tag) => {
        const isSelected = tag.isSelected;
        return (
          <Tag
            key={tag.name}
            isSelectable={isSelectable}
            isSelected={isSelected}
            onClick={() => onClick(tag)}
            noCapitalize={noCapitalize}
          >
            {tag.count ? `${tag.name} (${tag.count})` : tag.name}
          </Tag>
        );
      })}
    </TagsContainer>
  );
};

Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      isVisible: PropTypes.bool,
    })
  ),
  withVisibilityIcon: PropTypes.bool,
  isSelectable: PropTypes.bool,
};
