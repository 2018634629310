import { useEffect, useState } from "react";

// Service
import { getEmployeeDetails } from "service";
import amplitude from "amplitude-js";

export function useEmployeeDetails(id) {
  const [employeeDetails, setEmployeeDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!employeeDetails) {
      (async () => {
        try {
          const data = await getEmployeeDetails(id);

          setEmployeeDetails(data);
          setLoading(false);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      })();
    }
  }, [id, employeeDetails]);

  useEffect(() => {
    amplitude
      .getInstance()
      .logEvent("Loading Employee Details", { loading, error });
  }, [loading, error]);

  return {
    employeeDetails,
    loading,
    error,
  };
}
