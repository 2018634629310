import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import { GET_SURVEY_LOOKUPS } from "constants/actions";

import { getSurveyLookups as fetchSurveyLookups } from "service";

const initialState = {
  surveyLookups: null,
  error: null,
  loading: true,
};

export const getSurveyLookups = createAsyncThunk(
    GET_SURVEY_LOOKUPS,
  async (_, { rejectWithValue }) => {
    try {
      return await fetchSurveyLookups();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const surveyLookupsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getSurveyLookups.fulfilled, (state, action) => {
      state.surveyLookups = action.payload;
      state.loading = false;
    })
    .addCase(getSurveyLookups.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
