import PropTypes from "prop-types";

// Styles
import "react-dropzone-uploader/dist/styles.css";

// Components
import { Controller } from "react-hook-form";
import { Typography } from "components/Typography";
import { Box } from "@material-ui/core";
import { DropzoneComponent } from "./DropzoneComponent";

export function FileUploader({
  fileUploaderProps: {
    label,
    title,
    subtitle,
    initialFiles,
    setError,
    error,
    maxSizeBytes,
    accept,
    type,
		previewUrl,
  },
  controllerProps,
}) {
  return (
    <Box mb={3}>
      <Typography variant="h6" color="black">
        {label}
      </Typography>
      <Controller
        {...controllerProps}
        render={({ ...formProps }) => (
          <DropzoneComponent
            {...formProps}
            handleSetError={setError}
            error={error}
            initialFiles={initialFiles}
            maxSizeBytes={maxSizeBytes}
            accept={accept}
            type={type}
						previewUrl={previewUrl}
          />
        )}
      />
      <Typography variant="h6" color="blueAccent">
        {title}
      </Typography>
      <Typography variant="overline" color="darkGray">
        {subtitle}
      </Typography>
    </Box>
  );
}

FileUploader.propTypes = {
  fileUploaderProps: PropTypes.shape({
    label: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    initialFiles: PropTypes.string,
    setError: PropTypes.func,
    error: PropTypes.string,
    type: PropTypes.string,
  }),
  controllerProps: PropTypes.shape({
    control: PropTypes.object,
    name: PropTypes.string.isRequired,
  }),
};

FileUploader.defaultProps = {
  fileUploaderProps: {
    label: "File Uploader",
    title: "File Uploader Title",
    subtitle: "File Uploader Subtitle",
    type: "icon",
  },
};
