import styled, { css } from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledLoader = styled.div`
  ${({ fullPage, theme }) => `
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		${fullPage && css`
		top: 0;
		left: 0;
		position: absolute;
		`}
		.MuiCircularProgress-colorPrimary {
			color: ${theme.palette.blueMain};
		}
	`}
`;

export function Loader({ fullPage = false }) {
  return (
    <StyledLoader fullPage={fullPage}>
      <CircularProgress />
    </StyledLoader>
  );
}
