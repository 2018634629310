import { useCallback, useMemo, useState } from "react";

// Utils
import { format } from "date-fns";
import { tableHeadData } from "../../EmployeeCompaniesTable/useRows";

export function useValueOptions(companies, selectedCategory) {
  const categoryOptions = useMemo(
    () =>
      tableHeadData
        .filter(({ name }) => name !== "name" && name !== "dealTeam" && name !== "investmentSupportTeam")
        .map(({ title, name }) => ({
          value: name,
          label: title,
        })),
    []
  );

  const getLabel = useCallback((item, key) => {
    if (key === "exitDate" && !item[key]) {
      return "Active";
    }

    if (key === "exitDate" || key === "acquisitionDate") {
      return format(new Date(item[key]), "MMM, dd, yyyy");
    }

    return item[key];
  }, []);

  const mapCompany = useCallback(
    (k, item) => ({
      value: k === "exitDate" && !item[k] ? "active" : item[k],
      label: getLabel(item, k),
      category: k,
    }),
    [getLabel]
  );

  const reduceCompanies = useCallback(
    (a, item) => [...a, ...Object.keys(item).map((k) => mapCompany(k, item))],
    [mapCompany]
  );

  const filterUniqueCompanies = useCallback(
    ({ value }, i, arr) =>
      i === arr.findIndex(({ value: indexValue }) => indexValue === value),
    []
  );

  const valueOptionsMemo = useMemo(
    () =>
      companies
        .map(({ name, dealTeam, ...rest }) => rest)
        .reduce(reduceCompanies, [])
        .filter(filterUniqueCompanies),

    [companies, reduceCompanies, filterUniqueCompanies]
  );

  const [valueOptions, setValueOptions] = useState(
    valueOptionsMemo.filter(
      ({ category }) => category === selectedCategory.value
    )
  );

  return { valueOptions, setValueOptions, valueOptionsMemo, categoryOptions };
}
