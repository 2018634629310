// Components
import { Typography as MuiTypography } from "@material-ui/core";

// Utils
import styled from "styled-components";

export const Typography = styled(({ theme, color, ...rest }) => (
  <MuiTypography {...rest} />
))`
  ${({ theme, color }) => `
	color: ${theme.palette[color || "black"]};
  `}
  a {
    font-weight: 500;
    color: ${({ theme }) => theme.palette.blueAccent};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &:active {
      color: ${({ theme }) => theme.palette.blueMain};
    }
    &:visited {
      color: ${({ theme }) => theme.palette.blueMain};
    }
  }
`;
