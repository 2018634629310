import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

// Components
import { Typography } from 'components/Typography';
import { Tooltip } from 'components/Tooltip';



const StyledLabel = styled.label`
	display: block;
	margin-bottom: 8px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

export const InputLabel = ({ text, tooltip, typographyVariant, className }) => {
	return (
		<StyledLabel className={className}>
			<Typography variant={typographyVariant} color="black">
					{text}
			</Typography>
			{tooltip && <Tooltip text={tooltip} />}
		</StyledLabel>
	)
}

InputLabel.propTypes = {
  text: PropTypes.string,
  tooltip: PropTypes.string,
	typographyVariant: PropTypes.string
};

InputLabel.defaultProps = {
  tooltip: '',
	typographyVariant: 'h6'
};