import { useCallback } from 'react';

// Utils
import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

// Components
import { Icon } from 'components/Icon';
import { PageHeaderPopper } from './PageHeaderPopper';
import { Typography } from 'components/Typography';
import { PageHeaderDivider } from './PageHeaderDivider';
import { MenuItem } from 'components/MenuItem';

// Constants
import { APPS } from 'constants/index';

// Hooks
import { useOktaApps, useMinWidth, useUserData } from 'hooks';

const AppsIcon = styled(({ tabletScreens, ...rest }) => <Icon {...rest} />)`
  ${({ theme }) => `
    svg {
      fill: ${theme.palette.white};
    }
  `}

  ${ifProp(
    'tabletScreens',
    css`
      width: 32px;
      height: 32px;
    `
  )}
`;

const AppsWrapper = styled.div`
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  max-height: 180px;
  justify-content: space-between;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 16px 12px 0 0;
  justify-content: space-between;
  &&.MuiMenuItem-root {
    width: 88px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: initial;
`;

const StyledDivider = styled(PageHeaderDivider)`
  margin-top: 12px;
`;

export function PageHeaderSwitcher() {
  const tabletScreens = useMinWidth('tablet');
  const { user } = useUserData();
  const { oktaApps } = useOktaApps(user?.username);

  const handleClick = useCallback(
    (handleClose, linkUrl) => () => {
      handleClose();
      window.location.href = linkUrl;
    },
    []
  );

  return (
    <PageHeaderPopper
      iconComponent={<AppsIcon type={APPS} tabletScreens={tabletScreens} />}
    >
      {({ handleClose }) => (
        <>
          <Typography variant="h3" color="black">
            Apps
          </Typography>
          <AppsWrapper>
            {oktaApps?.map(({ id, label, linkUrl, logoUrl }) => (
              <StyledMenuItem
                key={id}
                iconComponent={<Image alt={label} src={logoUrl} />}
                title={
                  label.length > 12 ? `${label.substring(0, 11)}...` : label
                }
                typographyVariant="body1"
                onClick={handleClick(handleClose, linkUrl)}
              />
            ))}
          </AppsWrapper>
          <StyledDivider />
          {/* <Typography variant="h3" color="black">
            Apps
          </Typography>
          <MenuItem title="View All Apps" /> */}
        </>
      )}
    </PageHeaderPopper>
  );
}
