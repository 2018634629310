// Utils
import { createAction, createReducer } from "@reduxjs/toolkit";

// Constants
import { DISMISS_TOAST } from "constants/index";
import {
  createCategory,
  editCategory,
  deleteCategory,
} from "./categoriesReducer";
import { deleteSubcategory } from "./categoryReducer";
import { editKeyHighlight } from "./keyHighlightsReducer";

// Actions
import { updateSubcategory } from "./subcategoriesReducer";
import {
  patchKeyInformation,
  updateKeyInformation,
  deleteKeyInformation,
} from "./subcategoryReducer";
import {
  createSubcategoryTag,
  deleteSubcategoryTag,
} from "./subcategoryTagsReducer";
import {
  updateSingleNews,
  deleteSingleNews,
  createSingleNews,
} from "./newsReducer";
import {
  createSurveyCategory,
  deleteSurveyCategory,
  editSurveyCategory,
} from "./surveyCategoriesReducer";
import { createSurveyQuestion, deleteSurveyQuestion, editSurveyQuestion } from "./surveyQuestionsReducer";
import { deleteSurvey } from "./surveysReducer";

const initialState = {
  toast: null,
  open: false,
};

export const dismissToastFromStore = createAction(DISMISS_TOAST);

export const toastReducer = createReducer(initialState, (builder) =>
  builder
    // Subcategory
    .addCase(updateSubcategory.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Sub-category was updated and saved.",
        variant: "success",
      };
    })
    .addCase(updateSubcategory.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Sub-category was not saved.",
        variant: "error",
      };
    })
    .addCase(deleteSubcategory.fulfilled, (state, action) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Subcategory was successfully removed.",
        variant: "success",
      };
    })
    .addCase(deleteSubcategory.rejected, (state, action) => {
      state.open = true;
      if (action.payload !== "Request failed with status code 409") {
        state.toast = {
          message: "Something went wrong",
          subtitle: "Subcategory was not removed.",
          variant: "error",
        };
      }
    })
    // KeyInformation
    .addCase(patchKeyInformation.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Key information was created and saved.",
        variant: "success",
      };
    })
    .addCase(patchKeyInformation.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Key information was not saved.",
        variant: "error",
      };
    })
    .addCase(updateKeyInformation.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Key information was updated and saved.",
        variant: "success",
      };
    })
    .addCase(updateKeyInformation.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Key information was not saved.",
        variant: "error",
      };
    })
    .addCase(deleteKeyInformation.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Key information was successfully removed.",
        variant: "success",
      };
    })
    .addCase(deleteKeyInformation.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Key information was not removed.",
        variant: "error",
      };
    })
    // Category
    .addCase(createCategory.fulfilled, (state, action) => {
      state.open = true;
      state.toast = {
        message: "New Category was successfully created!",
        subtitle: "New Box folder was created.",
        variant: "success",
      };
    })
    .addCase(createCategory.rejected, (state, action) => {
      state.open = true;
      if (action.payload === "Request failed with status code 409") {
        state.toast = {
          message: "The Category name is already in use",
          subtitle: "Category was not created.",
          variant: "error",
        };
        return;
      }
      state.toast = {
        message: "Something went wrong",
        subtitle: "Category was not created.",
        variant: "error",
      };
    })
    .addCase(editCategory.fulfilled, (state, action) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Category was updated and saved.",
        variant: "success",
      };
    })
    .addCase(editCategory.rejected, (state, action) => {
      state.open = true;
      if (action.payload === "Request failed with status code 409") {
        state.toast = {
          message: "The Category name is already in use",
          subtitle: "Category was not updated.",
          variant: "error",
        };
        return;
      }
      state.toast = {
        message: "Something went wrong",
        subtitle: "Category was not updated.",
        variant: "error",
      };
    })
    .addCase(deleteCategory.fulfilled, (state, action) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Category was successfully removed.",
        variant: "success",
      };
    })
    .addCase(deleteCategory.rejected, (state, action) => {
      state.open = true;

      if (action.payload !== "Request failed with status code 409") {
        state.toast = {
          message: "Something went wrong",
          subtitle: "Category was not removed.",
          variant: "error",
        };
      }
    })
    // Subcategory Tag
    .addCase(createSubcategoryTag.fulfilled, (state, action) => {
      state.open = true;
      state.toast = {
        message: "Sub-Category Tag was successfully created!",
        variant: "success",
      };
    })
    .addCase(createSubcategoryTag.rejected, (state, action) => {
      state.open = true;
      if (action.payload === "Request failed with status code 409") {
        state.toast = {
          message: "The Sub-Category Tag name is already in use",
          subtitle: "Sub-Category Tag was not created.",
          variant: "error",
        };
        return;
      }
      state.toast = {
        message: "Something went wrong",
        subtitle: "Sub-Category Tag was not created.",
        variant: "error",
      };
    })
    .addCase(deleteSubcategoryTag.fulfilled, (state, action) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Sub-Category Tag was successfully removed.",
        variant: "success",
      };
    })
    .addCase(deleteSubcategoryTag.rejected, (state, action) => {
      state.open = true;

      if (action.payload !== "Request failed with status code 409") {
        state.toast = {
          message: "Something went wrong",
          subtitle: "Sub-Category Tag was not removed.",
          variant: "error",
        };
      }
    })
    // Key Highlights
    .addCase(editKeyHighlight.fulfilled, (state, action) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "File was successfully updated.",
        variant: "success",
      };
    })
    .addCase(editKeyHighlight.rejected, (state, action) => {
      state.open = true;

      if (action.payload !== "Request failed with status code 409") {
        state.toast = {
          message: "Something went wrong",
          subtitle: "File was not updated.",
          variant: "error",
        };
      }
    })
    // News
    .addCase(updateSingleNews.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "News was updated and saved.",
        variant: "success",
      };
    })
    .addCase(updateSingleNews.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        variant: "error",
      };
    })
    .addCase(deleteSingleNews.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "News was successfully removed.",
        variant: "success",
      };
    })
    .addCase(deleteSingleNews.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        variant: "error",
      };
    })
    .addCase(createSingleNews.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "News was created and saved.",
        variant: "success",
      };
    })
    .addCase(createSingleNews.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        variant: "error",
      };
    })
    // Survey categories
    .addCase(createSurveyCategory.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Survey category was successfully created!",
        variant: "success",
      };
    })
    .addCase(createSurveyCategory.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Survey category was not created.",
        variant: "error",
      };
    })
    .addCase(editSurveyCategory.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Survey category was successfully updated!",
        variant: "success",
      };
    })
    .addCase(editSurveyCategory.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Survey category was not updated.",
        variant: "error",
      };
    })
    .addCase(deleteSurveyCategory.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Survey category was successfully removed!",
        variant: "success",
      };
    })
    .addCase(deleteSurveyCategory.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Survey category was not removed.",
        variant: "error",
      };
    })
    // Survey Questions
    .addCase(createSurveyQuestion.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Survey question was successfully created!",
        variant: "success",
      };
    })
    .addCase(createSurveyQuestion.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Survey question was not created.",
        variant: "error",
      };
    })
    .addCase(editSurveyQuestion.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Survey question was successfully updated!",
        variant: "success",
      };
    })
    .addCase(editSurveyQuestion.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Survey question was not updated.",
        variant: "error",
      };
    })
    .addCase(deleteSurveyQuestion.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Survey question was successfully removed!",
        variant: "success",
      };
    })
    .addCase(deleteSurveyQuestion.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Survey question was not removed.",
        variant: "error",
      };
    })
    .addCase(deleteSurvey.fulfilled, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Changes were successfully saved",
        subtitle: "Survey was successfully removed!",
        variant: "success",
      };
    })
    .addCase(deleteSurvey.rejected, (state, _) => {
      state.open = true;
      state.toast = {
        message: "Something went wrong",
        subtitle: "Survey was not removed.",
        variant: "error",
      };
    })
    .addCase(dismissToastFromStore, (state, _) => {
      state.open = false;
      state.toast = null;
    })
);
