import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Avatar } from "@material-ui/core";
import { CustomLink as Link } from "components/CustomLink";
import { Typography } from "components/Typography";

const CompanyInfoContainer = styled.div`
  display: flex;
`;

const CompanyInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

const CompanyIndustryWrapper = styled.div`
  display: "flex";
`;

const CompanyIndustryLabel = styled(Typography)`
  font-weight: 500;
  margin-right: 8px;
`;

export const CompanyInfo = ({ companyInfo }) => {
  return (
    <CompanyInfoContainer>
      <Avatar src={companyInfo.logo} alt={companyInfo.companyName} />
      <CompanyInfoWrapper>
        <Link variant="h6" to="">
          {companyInfo.companyName}
        </Link>
        <CompanyIndustryWrapper>
          <CompanyIndustryLabel variant="overline">
            Industry:
          </CompanyIndustryLabel>
          <Typography variant="overline">{companyInfo.industry}</Typography>
        </CompanyIndustryWrapper>
      </CompanyInfoWrapper>
    </CompanyInfoContainer>
  );
};

CompanyInfo.propTypes = {
  companyInfo: PropTypes.shape({
    logo: PropTypes.string,
    companyName: PropTypes.string,
    industry: PropTypes.string,
  }),
};
