import React from "react";

// Utils
import styled from "styled-components";
import { formatDate } from "utils/formatDate";

// Components
import { DocumentInfo } from "./DocumentInfo";
import { ColoredBulletLabel, Typography } from "components";

// Hooks
import { useScreens } from "hooks";

const StyledDateTypography = styled(Typography)`
  white-space: nowrap;
`;

export const useRows = (documents, hideParentLabel, pageLabel) => {
  const { tabletScreens, laptopScreens } = useScreens();

  const getColorBulletLabelCell = (laptopScreens, isRecommended) => {
    if (!laptopScreens) return null;

    return isRecommended ? (
      <ColoredBulletLabel
        label="Recommended"
        color="orange"
        labelColor="orange"
        labelVariant="h6"
      />
    ) : (
      <></>
    );
  };

  const rows = React.useMemo(
    () =>
      documents?.map((document, i) => {
        const tags =
          document?.tags?.map((tag) => ({
            name: tag,
            ...tag,
          })) ?? [];

        return [
          {
            component: (
              <DocumentInfo
                document={document}
                hideParentLabel={hideParentLabel}
                tags={tags}
                pageLabel={pageLabel}
                docIndex={i}
              />
            ),
            name: "documentInfo",
            isRecommended: document.isRecommendedRead,
            width: tabletScreens ? "57%" : "100%",
          },
          {
            component: getColorBulletLabelCell(
              laptopScreens,
              document.isRecommendedRead
            ),
            name: "recommendedLabel",
          },
          {
            component: tabletScreens ? (
              <StyledDateTypography variant="body1" color="darkGray">
                {formatDate(document.modifiedDate)}
              </StyledDateTypography>
            ) : null,
            name: "dateCreated",
            isFlexEnd: true,
          },
        ];
      }),
    [documents, tabletScreens, laptopScreens, hideParentLabel, pageLabel]
  );

  return rows;
};
