import React, { useEffect, useMemo } from "react";
// Utils
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getSurveyLookups } from "redux/reducers/surveyLookupsReducer";

export function useSurveyLookups() {
  const dispatch = useDispatch();

  const { surveyLookups, loading, error } = useSelector(
    ({ surveyLookups }) => surveyLookups
  );

  const setSurveyLookups = React.useCallback(() => {
    dispatch(getSurveyLookups());
  }, [dispatch]);

  const sectors = React.useMemo(() => {
    if (!surveyLookups) return;
    const sectorsLookup = surveyLookups?.find(
      (lookupItem) => lookupItem.id === "sectorTypes"
    );

    return sectorsLookup?.items;
  }, [surveyLookups]);

	const countries = React.useMemo(() => {
    if (!surveyLookups) return;
    const countriesLookup = surveyLookups?.find(
      (lookupItem) => lookupItem.id === "countryTypes"
    );

    return countriesLookup?.items;
  }, [surveyLookups]);

	const deals = React.useMemo(() => {
    if (!surveyLookups) return;
    const dealsLookup = surveyLookups?.find(
      (lookupItem) => lookupItem.id === "surveyForTypes"
    );

    return dealsLookup?.items;
  }, [surveyLookups]);

  const answerTypes = React.useMemo(() => {
    if (!surveyLookups) return;
    const sectorsLookup = surveyLookups?.find(
      (lookupItem) => lookupItem.id === "responseTypes"
    );

    return sectorsLookup?.items?.map((answerType) => answerType?.name) ?? [];
  }, [surveyLookups]);

	const businessTypes = React.useMemo(() => {
    if (!surveyLookups) return;
    const businessTypesLookup = surveyLookups?.find(
      (lookupItem) => lookupItem.id === "businessTypes"
    );

    return businessTypesLookup?.items.map(({name, value}) => ({label: name, value}));
  }, [surveyLookups]);

  const memoState = useMemo(
    () => ({
      surveyLookups,
      sectors,
			deals,
			countries,
			businessTypes,
      answerTypes,
      setSurveyLookups,
      loading,
      error,
    }),
    [error, loading, surveyLookups, setSurveyLookups, sectors, answerTypes, deals, countries, businessTypes]
  );

  useEffect(() => {
    if (!memoState.surveyLookups) {
      dispatch(getSurveyLookups());
    }
  }, [dispatch, memoState.surveyLookups]);

  return { ...memoState };
}
