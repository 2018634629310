import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { MenuItem as MUIMenuItem } from "@material-ui/core";
import { Typography } from "components/Typography";

const StyledMenuItem = styled(MUIMenuItem)`
  min-height: 20px;
  border-radius: 4px;
	.MuiListItem-button:hover {
		background-color: ${(props) => props.theme.palette.blueAccent};
	}
`;

export function MenuItem({
  iconComponent,
  title,
  onClick,
  typographyVariant,
  typographyColor,
  className,
}) {
  return (
    <StyledMenuItem disableGutters onClick={onClick} className={className}>
      {iconComponent}
      <Typography variant={typographyVariant} color={typographyColor}>
        {title}
      </Typography>
    </StyledMenuItem>
  );
}

MenuItem.propTypes = {
  iconComponent: PropTypes.object,
  title: PropTypes.string,
  onClick: PropTypes.func,
  typographyVariant: PropTypes.string,
  typographyColor: PropTypes.string,
};

MenuItem.defaultProps = {
  iconComponent: null,
  typographyVariant: "h6",
  typographyColor: "blueAccent",
};
