// Utils
import { createReducer, createAsyncThunk } from '@reduxjs/toolkit';

// Services
import {
  searchKeyHighlights as fetchKeyHighlights,
  updateKeyHighlights,
} from 'service';

// Actions
import { GET_KEY_HIGHLIGHTS, EDIT_KEY_HIGHLIGHTS } from 'constants/actions';

const initialState = {
  keyHighlights: null,
  tagsAggregation: [],
  totalCount: 0,
  error: null,
  loading: true,
  aggregatedDocCount: 0,
};

export const getKeyHighlights = createAsyncThunk(
  GET_KEY_HIGHLIGHTS,
  async (
    { size, subCategoryFilter, subCategoryTagFilter, start, query },
    { rejectWithValue }
  ) => {
    try {
      return await fetchKeyHighlights({
        size,
        subCategoryFilter,
        subCategoryTagFilter,
        start,
        query,
      });
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const editKeyHighlight = createAsyncThunk(
  EDIT_KEY_HIGHLIGHTS,
  async (
    {
      payload,
      id,
      size,
      subCategoryFilter,
      subCategoryTagFilter,
      start,
      query,
    },
    { rejectWithValue }
  ) => {
    try {
      await updateKeyHighlights(payload, id);
      return await fetchKeyHighlights({
        size,
        subCategoryFilter,
        subCategoryTagFilter,
        start,
        query,
      });
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const keyHighlightsReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getKeyHighlights.pending, (state) => {
      state.loading = true;
    })
    .addCase(getKeyHighlights.fulfilled, (state, action) => {
      if (
        !action.meta.arg.subCategoryTagFilter?.length ||
        action.meta.arg.subCategoryTagFilter[0].id === 'all'
      ) {
        state.aggregatedDocCount = action.payload.hits.total.value;
        state.tagsAggregation =
          action.payload.aggregations?.all?.subCategoryTags?.subs?.subs?.subs?.buckets;
      }
      state.keyHighlights = action.payload.hits.hits;

      state.totalCount = action.payload.hits.total.value;
      state.loading = false;
    })
    .addCase(getKeyHighlights.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(editKeyHighlight.fulfilled, (state, action) => {
      state.loading = false;
      state.keyHighlights = action.payload.hits.hits;
      state.totalCount = action.payload.hits.total.value;
    })
    .addCase(editKeyHighlight.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
);
