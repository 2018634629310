import { Typography } from "components";
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

export const StyledTag = styled.div`
  height: 26px;
  padding: 4px 12px;
  border-radius: 4px;
  margin: 6px 4px;
  ${ifProp(
    "isSelected",
    css`
      background-color: ${({ theme }) => theme.palette.blueAccent};
    `,
    css`
      background-color: ${({ theme }) => theme.palette.grayLight};
    `
  )}
  text-transform: ${({ noCapitalize }) =>
    noCapitalize ? "none" : "capitalize"};
  display: flex;
  align-items: center;
  ${ifProp(
    "isSelectable",
    css`
      cursor: pointer;
    `
  )}
`;

export const Tag = ({
  isSelected,
  isSelectable,
  onClick,
  children,
  noCapitalize,
}) => {
  return (
    <StyledTag
      isSelected={isSelected}
      isSelectable={isSelectable}
      onClick={onClick}
      noCapitalize={noCapitalize}
    >
      <Typography variant="caption" color={isSelected ? "white" : "black"}>
        {children}
      </Typography>
    </StyledTag>
  );
};
