import PropTypes from "prop-types";
import { useCallback } from "react";

// Utils
import styled from "styled-components";
import { useHistory } from "react-router";

// Components
import { Box } from "@material-ui/core";
import { Typography } from "components/Typography";
import { Icon } from "components/Icon";

const TitleTypography = styled(Typography)`
  margin-left: 8px;
`;

const CategoryTypography = styled(Typography)`
  margin-left: 12px;
`;

export function AutocompleteOption({ icon, title, category, link, count }) {
  const history = useHistory();

  const handleSelect = useCallback(() => {
    if (category === "Suggestions") {
      return window.open(link);
    }

    history.push(`${link}`);
  }, [category, history, link]);

  return (
    <Box display="flex" alignItems="center" onClick={handleSelect} width="100%">
      {icon && <Icon type={icon} />}
      <TitleTypography variant="h6" color="blueAccent">
        {title}
      </TitleTypography>
      {Boolean(category) && (
        <CategoryTypography variant="srOnly" color="darkGray">
          &#8226; {`${category} (${count})`}
        </CategoryTypography>
      )}
    </Box>
  );
}

AutocompleteOption.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
  link: PropTypes.string,
};
