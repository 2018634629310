import { Checkbox as MUICheckbox } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Checkbox = styled(
  ({ theme, color, checkedColor, width, ...rest }) => <MUICheckbox {...rest} />
)`
  padding: 0;
  ${({ width, color, checkedColor, theme }) => `
  font-size: ${width};
  width: ${width};
  height: ${width};
  svg {
    fill: ${theme.palette[color]};
  }
  &.Mui-checked {
    color: ${theme.palette[checkedColor]};
    svg {
      fill: ${theme.palette[checkedColor]};
    }
  }

`}
`;

Checkbox.propTypes = {
  color: PropTypes.string,
  checkedColor: PropTypes.string,
  width: PropTypes.string,
};

Checkbox.defaultProps = {
  color: "darkGray",
  checkedColor: "darkGray",
  width: "15px",
};
