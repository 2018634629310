// Utils
import styled, { css } from "styled-components";

// Components
import { IconButton as MUIIconButton } from "@material-ui/core";
import { Icon, Typography } from "components";
import { CSVLink } from "react-csv";

// Constants
import { EXPORT } from "constants/index";
import { ifProp } from "styled-tools";
import { useScreens } from "hooks";

const IconButton = styled(MUIIconButton)`
  ${({ theme }) => `
    height: 100%;
    width: 100%;
    border-radius: 4px;
    border: 1px solid ${theme.palette.blueAccent};

    > span {
      margin-right: 8px;
    }

    path {
      fill: ${theme.palette.blueAccent};
    }
  `}
`;

const StyledCSVLink = styled(CSVLink)`
  width: 100%;
  height: 35px;
  max-width: 140px;
  text-decoration: none;
  margin-left: auto;
	${ifProp(
    'tabletScreens',
    css`
			max-width: 150px;
    `
  )}
`;

export function CSVExport({ ...csvProps }) {
	const {tabletScreens } = useScreens()
  return (
    <StyledCSVLink {...csvProps} tabletScreen={tabletScreens}>
      <IconButton>
        <Icon type={EXPORT} />
        <Typography variant="h6" color="blueAccent">
          Export
        </Typography>
      </IconButton>
    </StyledCSVLink>
  );
}
