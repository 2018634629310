import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

import { TextField as MUITextField, IconButton } from "@material-ui/core";
import { Icon } from "components";

// Hooks
import { useMinWidth } from "hooks";

// Constants
import { CLOSE, SEARCH_MUI } from "constants/index";
import { useCallback } from "react";
import { useHistory } from "react-router";

const inputBody1Typography = (theme) => `
  &&& .MuiInputBase-input {
    padding: 0;
    color: ${theme.palette.black};
    font-size: 14px;
    font-weight: ${theme.typography.body1.fontWeight};
    line-height: ${theme.typography.body1.lineHeight};

    &::placeholder {
      color: ${theme.palette.darkGray};
      font-size: 14px;
      font-weight: ${theme.typography.body1.fontWeight};
      line-height: ${theme.typography.body1.lineHeight};
    }
  }
`;

const TextField = styled(({ tabletScreens, autocompleteVariant, ...rest }) => (
  <MUITextField {...rest} />
))`
  ${({ theme }) => inputBody1Typography(theme)}

  ${ifProp(
    "tabletScreens",
    css`
      ${({ theme }) => `
        &&& .MuiInputBase-input {
          padding: 0;
          color: ${theme.palette.black};
          font-size: 16px;
          font-weight: ${theme.typography.h4.fontWeight};
          line-height: ${theme.typography.h4.lineHeight};

          &::placeholder {
            color: ${theme.palette.darkGray};
            font-size: 16px;
            font-weight: ${theme.typography.h4.fontWeight};
            line-height: ${theme.typography.h4.lineHeight};
          }
        }
      `}
    `
  )}

${ifProp(
    { autocompleteVariant: "header" },
    css`
      ${({ theme }) => `
        &&& .MuiInputBase-root {
          padding: 0 20px;
        }

        .MuiInputBase-input {
          margin-left: -12px;
        }

        ${inputBody1Typography(theme)}
      `}
    `
  )}
`;

const CustomIconButton = styled(IconButton)`
  ${({ theme }) => `
    height: 36px;
    width: 36px;

    svg {
      fill: ${theme.palette.black};
    }
  `}
`;

const CloseIconButton = styled(CustomIconButton)`
  right: -12px;
`;

const SearchIconButton = styled(CustomIconButton)`
  left: -12px;
`;

export function AutocompleteInput({ variant, handleClose, ...props }) {
  const history = useHistory();
  const tabletScreens = useMinWidth("tablet");

  const handleClick = useCallback(() => {
    if (props.inputProps.value) {
      history.push(`/search/results/all/${props.inputProps.value}`);
    }
  }, [history, props.inputProps.value]);

  if (variant === "header") {
    props.InputProps.startAdornment = (
      <SearchIconButton onClick={handleClick}>
        <Icon type={SEARCH_MUI} />
      </SearchIconButton>
    );

    props.InputProps.endAdornment = (
      <CloseIconButton onClick={handleClose}>
        <Icon type={CLOSE} />
      </CloseIconButton>
    );
  }

  return (
    <TextField
      {...props}
      placeholder="Search for information, resource ..."
      variant="outlined"
      tabletScreens={tabletScreens}
      autocompleteVariant={variant}
    />
  );
}

AutocompleteInput.propTypes = {
  variant: PropTypes.string,
  handleClose: PropTypes.func,
};

AutocompleteInput.defaultProps = {
  variant: null,
  handleClose: () => {},
};
