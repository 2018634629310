import styled from 'styled-components';
import { switchProp } from 'styled-tools';

// Components
import { Icon } from "components";


export const Indicator = styled(({indicatorOffset, ...rest}) => <Icon {...rest}/>)`
	position: absolute;
	appearance: none;
	opacity: 0;
	position: absolute;
	cursor: pointer;
	top: 0;
	bottom: 0;
	transform: scale(1.4);
	top: ${props => props.indicatorOffset}px;
	transition: opacity 0.1s ease 0s;
	${switchProp("direction", {left: 'left', right: 'right'})}: -20px;
	opacity: ${switchProp("overflow", {
		both: 1, 
		left: switchProp("direction", {left: 1, right: 0}), 
		right: switchProp("direction", {left: 0, right: 1}),
		none: 0, 
	})};		
`