// Utils
import { createReducer, createAsyncThunk } from "@reduxjs/toolkit";

// Services
import {
  patchKeyInformation as addKeyInformation,
  updateKeyInformation as editKeyInformation,
  deleteKeyInformation as removeKeyInformation,
  getSubcategory as fetchSubcategory,
} from "service";

// Actions
import {
  GET_SUBCATEGORY,
  PATCH_KEY_INFORMATION,
  UPDATE_KEY_INFORMATION,
  DELETE_KEY_INFORMATION,
} from "constants/index";

const initialState = {
  subcategory: null,
  error: null,
  loading: true,
};

export const getSubcategory = createAsyncThunk(
  GET_SUBCATEGORY,
  async (subcategoryId, { rejectWithValue }) => {
    try {
      return await fetchSubcategory(subcategoryId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const patchKeyInformation = createAsyncThunk(
  PATCH_KEY_INFORMATION,
  async (keyInformationData, { rejectWithValue }) => {
    try {
      await addKeyInformation(keyInformationData);
      return await fetchSubcategory(keyInformationData.parentId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const updateKeyInformation = createAsyncThunk(
  UPDATE_KEY_INFORMATION,
  async (keyInformationData, { rejectWithValue }) => {
    try {
      await editKeyInformation(keyInformationData);
      return await fetchSubcategory(keyInformationData.parentId);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const deleteKeyInformation = createAsyncThunk(
  DELETE_KEY_INFORMATION,
  async (keyInformationData, { rejectWithValue }) => {
    try {
      await removeKeyInformation(keyInformationData);
      return keyInformationData.id;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const subcategoryReducer = createReducer(initialState, (builder) =>
  builder
    .addCase(getSubcategory.pending, (state) => {
      state.loading = true;
    })
    .addCase(getSubcategory.fulfilled, (state, action) => {
      state.subcategory = action.payload;
      state.loading = false;
    })
    .addCase(getSubcategory.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    })
    .addCase(patchKeyInformation.fulfilled, (state, action) => {
      state.subcategory = action.payload;
    })
    .addCase(deleteKeyInformation.fulfilled, (state, action) => {
      const keyInformationIndex = state.subcategory.keyInformation.findIndex(
        ({ id }) => id === action.payload
      );
      state.subcategory.keyInformation.splice(keyInformationIndex, 1);
    })
    .addCase(updateKeyInformation.fulfilled, (state, action) => {
      state.subcategory = action.payload;
    })
);
