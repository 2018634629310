import React from "react";
import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";

// Components
import { Table, TableCell, TableBody, Paper } from "@material-ui/core";
import { TablePagination } from "components";
import { TableHead } from "./components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0px 8px;
`;

const StyledTableCell = styled(({ isRecommended, customWidth, ...rest }) => (
  <TableCell {...rest} />
))`
  border: none;
  padding: 0;
  border-radius: 4px;
  width: ${({ customWidth }) => customWidth || "auto"};
  &:first-of-type {
    ${ifProp(
      "isRecommended",
      css`
        border-left: ${({ theme }) => `6px solid ${theme.palette.orange}`};
      `
    )}
  }
`;

const TableInnerCell = styled.div`
  padding: 20px 20px 20px 14px;
  overflow: hidden;
  display: flex;
  justify-content: ${({ isFlexEnd }) =>
    isFlexEnd ? "flex-end" : "flex-start"};
`;

const StyledTableRow = styled(Paper)`
  width: 100%;
  overflow: hidden;
  margin-bottom: 8px;
`;

export const CardsTable = ({
  tableRowsData,
  tableHeadData,
  rawData,
  paginationOptions,
}) => {
  return (
    <Container>
      <StyledTable>
        <TableHead tableHeadData={tableHeadData} />
        <TableBody>
          {tableRowsData?.map((row, i) => (
            <StyledTableRow key={rawData[i].id} elevation={2} component="tr">
              {row.map((rowItem) => {
                if (!rowItem.component) return null;
                return (
                  <StyledTableCell
                    key={rowItem.name}
                    isRecommended={rowItem.isRecommended}
                    customWidth={rowItem.width}
                  >
                    <TableInnerCell isFlexEnd={rowItem.isFlexEnd}>
                      {rowItem.component}
                    </TableInnerCell>
                  </StyledTableCell>
                );
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </StyledTable>
      {paginationOptions && (
        <TablePagination paginationOptions={paginationOptions} />
      )}
    </Container>
  );
};

CardsTable.propTypes = {
  tableRowsData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        component: PropTypes.element,
        width: PropTypes.string,
        name: PropTypes.string.isRequired,
      })
    )
  ),
  rawData: PropTypes.array,
  isRecommended: PropTypes.bool,
  tableHeadData: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        title: PropTypes.string,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        isHidden: PropTypes.bool,
      }),
      PropTypes.shape({
        component: PropTypes.element,
        name: PropTypes.string.isRequired,
        color: PropTypes.string,
        isHidden: PropTypes.bool,
      }),
    ])
  ),
  paginationOptions: PropTypes.shape({
    rowsPerPageOptions: PropTypes.array,
    rowsNumber: PropTypes.number,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
    displayedRowsLabel: PropTypes.string,
  }),
};
