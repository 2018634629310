import React, { forwardRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import { Select as MuiSelect } from "@material-ui/core";

const StyledSelect = styled(
  forwardRef((props, ref) => <MuiSelect {...props} ref={ref} />)
)`
  width: 100%;

  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.blueAccent};
    border-width: 1px;
  }
  .MuiOutlinedInput-input {
    padding: 8px 16px;
    height: 36px;
    box-sizing: border-box;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }

  .MuiPaper-root {
    box-shadow: 2px 12px 24px rgba(221, 224, 225, 0.7);
  }

  .MuiPaper-rounded {
    border-radius: 0 0 8px 8px;
  }

  .MuiMenu-paper {
    max-height: 250px;
  }

  .MuiList-root {
    padding: 16px 0;
  }

  .MuiMenuItem-root {
    font-size: 14px;
    line-height: 20px;
    min-height: 0;
    padding: 10px 12px;
  }
  .MuiOutlinedInput-input {
    font-size: 14px;
  }
  .MuiListItem-button:hover {
    background: ${({ theme }) => theme.palette.greySuperLight};
    border-radius: 4px;
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: rgba(43, 89, 163, 0.08);
  }
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected {
    border-radius: 4px;
  }
  &.MuiInputBase-root.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.greySuperLight};
  }
  &.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border-color: rgba(161, 171, 187, 0.5);
  }
  &.MuiOutlinedInput-notchedOutline,
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${({ theme }) => `1px solid ${theme.palette.red}`};
  }
  &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(161, 171, 187, 0.5);
    border-width: 1px;
  }
  &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.blueAccent};
    border-width: 1px;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(161, 171, 187, 0.5);
  }
  &.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.palette.red};
    border-width: 1px;
  }
  .MuiFormHelperText-root.Mui-error {
    display: flex;
    justify-content: flex-end;
    color: ${({ theme }) => theme.palette.red};
    margin-right: 0;
    font-size: 10px;
  }
`;

export const Select = forwardRef(
  (
    {
      children,
      value,
      onOpen,
      onClose,
      renderValue,
      menuProps = {},
      fieldProps = {},
      onChange,
      displayEmpty,
      disabled,
      error,
      multiple,
      className,
    },
    ref
  ) => {
    return (
      <StyledSelect
        {...fieldProps}
        ref={ref}
        value={value}
        variant="outlined"
        onClose={onClose}
        onOpen={onOpen}
        displayEmpty={displayEmpty}
        onChange={onChange}
        multiple={multiple}
        MenuProps={{
          disablePortal: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          ...menuProps,
        }}
        renderValue={renderValue}
        disabled={disabled}
        error={error}
        className={className}
      >
        {children}
      </StyledSelect>
    );
  }
);

Select.propTypes = {
  value: PropTypes.any,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  renderValue: PropTypes.func,
  menuProps: PropTypes.object,
  fieldProps: PropTypes.object,
  onChange: PropTypes.func,
  displayEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  multiple: PropTypes.bool,
};
