// Utils
import styled from "styled-components";

// Components
import { IconButton as MUIIconButton } from "@material-ui/core";
import { Icon } from "components/Icon";

// Constants
import { MENU } from "constants/index";

// Contexts
import { useDrawer } from "contexts";

const IconButton = styled(MUIIconButton)`
  margin-left: -12px;
  margin-right: 8px;
`;

const MenuIcon = styled(Icon)`
  ${({ theme }) => `
    svg {
      fill: ${theme.palette.white};
    }
  `}
`;

export function PageHeaderDrawer() {
  const { toggleDrawer } = useDrawer();

  return (
    <IconButton onClick={() => toggleDrawer(true)}>
      <MenuIcon type={MENU} />
    </IconButton>
  );
}
