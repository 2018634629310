import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Button, Typography } from "components";

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 17px auto 40px auto;
  width: 76%;
`;

const Title = styled(Typography)`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(161, 171, 187, 0.4);
`;

const Subtitle = styled(Typography)`
  margin-bottom: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${({ isCentered }) =>
    isCentered ? "center" : "space-between"};
  margin-top: 40px;
`;

export const FormActionMessageModal = ({
  title,
  subtitle,
  description,
  confirmText,
  cancelText,
  onConfirm,
  onCancel,
}) => {
  return (
    <ModalContentWrapper>
      <Title variant="h2" color="black">
        {title}
      </Title>
      <Subtitle variant="h3" color="black">
        {subtitle}
      </Subtitle>
      {description && (
        <Typography variant="h6" color="darkGray">
          {description}
        </Typography>
      )}
      <ButtonsContainer isCentered={!confirmText}>
        <Button
          variant="secondary"
          text={cancelText}
          onClick={onCancel}
          width="186px"
        />
        {confirmText && (
          <Button
            variant="primary"
            text={confirmText}
            onClick={onConfirm}
            width="186px"
          />
        )}
      </ButtonsContainer>
    </ModalContentWrapper>
  );
};

FormActionMessageModal.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};
