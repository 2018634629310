import React, { useContext, useState } from "react";

// Components
import { Modal } from "components/Modal";

const ModalContext = React.createContext();

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModalContext cannot be used without ModalContext");
  }

  return context;
};

export const ModalProvider = ({ children }) => {
  const [modalContent, setModalContent] = useState(null);
  const [modalWidth, setModalWidth] = useState("");
  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  const onOpenModal = (modalContent, options) => {
    setModalContent(modalContent);
    setModalWidth(options?.modalWidth);
    setIsScrollDisabled(options?.isScrollDisabled);
  };

  const onCloseModal = () => {
    setModalContent(null);
  };

  const value = React.useMemo(
    () => ({
      modalContent,
      modalWidth,
      isScrollDisabled,
      onOpenModal,
      onCloseModal,
    }),
    [modalContent, modalWidth, isScrollDisabled]
  );

  return (
    <ModalContext.Provider value={value}>
      <Modal />
      {children}
    </ModalContext.Provider>
  );
};
