import { useState } from "react";

// Service
import { logout } from "service";

export function useHandleLogoutWithRedirect() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  async function handleLogout() {
    setLoading(true);

    try {
      await logout();

      localStorage.clear();
      window.open(process.env.REACT_APP_OKTA_URL, "_parent");
    } catch (e) {
      setError(e);
    }
  }

  return {
    handleLogout,
    loading,
    error,
  };
}
