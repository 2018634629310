import React from "react";
import styled from "styled-components";

// Components
import { ColoredBulletLabel, CustomLink, Typography, Icon } from "components";

// Utils
import { formatDate } from "utils/formatDate";

// Constants
import { SURVEYS, EDIT } from "constants/routeSegments";
import { DELETE } from "constants/icons";
import { useModal } from "contexts";
import { FormActionMessageModal } from "components/FormActionMessageModal";

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-of-type {
    margin-right: 16px;
  }
`;
export const useRows = (surveys, removeSurvey) => {
  const { onOpenModal, onCloseModal } = useModal();

  const rows = React.useMemo(() => {
    const deleteSurvey = async (id) => {
      onCloseModal();
      await removeSurvey(id);

    };

    const onDeleteClick = (survey) => {
      onOpenModal(
        <FormActionMessageModal
          title={`Are you sure you want to delete this survey`}
          cancelText="Cancel"
          confirmText="Delete"
          onCancel={onCloseModal}
          onConfirm={() => deleteSurvey(survey.id)}
        />,
        { modalWidth: "516px" }
      );
    };

    return surveys?.map((survey) => {
      return [
        {
          component: survey?.isDraft ? (
            <CustomLink to={`/${SURVEYS}/${EDIT}/1/${survey.id}`}>
              {survey?.topic}
            </CustomLink>
          ) : (
            <Typography variant="body1" color="darkGray">
              {survey?.topic}
            </Typography>
          ),
          name: "topic",
        },
        {
          component: survey?.isDraft ? (
            <Typography variant="body1" color="black">
              Draft
            </Typography>
          ) : (
            <ColoredBulletLabel
              labelVariant="body1"
              color="greenLight"
              label="Submitted"
              labelColor="darkGray"
            />
          ),
          name: "status",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {survey?.user?.fullName}
            </Typography>
          ),
          name: "createdBy",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {formatDate(survey?.submitterDateTime)}
            </Typography>
          ),
          name: "createdDate",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {formatDate(survey?.targetLaunchDate)}
            </Typography>
          ),
          name: "targetLaunchDate",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {survey?.teamMembers}
            </Typography>
          ),
          name: "teamMembers",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {survey?.sector?.[0]}
            </Typography>
          ),
          name: "sector",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {survey?.dealEntityName?.[0]}
            </Typography>
          ),
          name: "dealEntityName",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {survey?.businessType}
            </Typography>
          ),
          name: "businessType",
        },
        {
          component: (
            <Typography
              variant="body1"
              color={survey?.isDraft ? "black" : "darkGray"}
            >
              {survey?.dealType}
            </Typography>
          ),
          name: "dealType",
        },
        {
          component: survey?.isDraft ? (
            <ButtonWrapper>
              <Icon type={DELETE} width="16px" color="red" />
              <Typography
                variant="h6"
                color="red"
                onClick={() => {
                  onDeleteClick(survey);
                }}
              >
                Delete
              </Typography>
            </ButtonWrapper>
          ) : null,
          name: "delete",
        },
      ];
    });
  }, [surveys, onCloseModal, onOpenModal, removeSurvey]);

  return rows;
};
