// Utils
import { getValue } from "utils";

// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;
const oidcRealm = `${process.env.REACT_APP_OIDCREALM}`;
const env = `${process.env.REACT_APP_ENV}`;

const api = apiConfig(baseURL);

export async function getAuthData(redirectUri, nonce, state, realm) {
  const { data } = await api.post("/es/authenticate-oidc", {
    redirect_uri: redirectUri,
    nonce,
    state,
    realm,
  });

  return data;
}

export async function getAuthRedirectUrl() {
  const { data } = await api.get(`/es/prepare-oidc?realm=${oidcRealm}`);

  return data;
}

export const fetchIsAdmin = async () => {
  const data = await api.post("/compass/GAInsightsAdminUserFunction", {
    env,
  });

  return data;
};

export async function getUserData() {
  const data = await Promise.all([api.get("/es/authenticate"), fetchIsAdmin()]);

  const { full_name, email, username } = data?.[0]?.data;
  const { isAdmin } = data?.[1]?.data;

  return { full_name, email, isAdmin, username };
}

export async function refreshToken(refresh_token) {
  const { data } = await api.post("/es/refresh-oidc", {
    grant_type: "refresh_token",
    refresh_token,
  });

  return data;
}

export async function logout() {
  const token = getValue("access_token");
  const refresh_token = getValue("refresh_token");

  const { data } = await api.post("/es/logout-oidc", {
    token,
    refresh_token,
  });

  return data;
}
