export const formatDate = (dateString) => {
  var options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", options);
};
