import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import { Typography } from "components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ColoredBullet = styled.div`
  ${({ bulletSize }) => `
width: ${bulletSize || "8px"};
height: ${bulletSize || "8px"};

`}
  border-radius: 50%;
  background-color: ${({ theme, color }) =>
    theme.palette?.[color || "blueMain"]};
  margin-right: 4px;
`;

export const ColoredBulletLabel = ({
  label,
  color,
  labelColor,
  labelVariant,
  bulletSize,
}) => {
  return (
    <Container>
      <ColoredBullet color={color} bulletSize={bulletSize} />
      <Typography
        variant={labelVariant || "body1"}
        color={labelColor || "black"}
      >
        {label}
      </Typography>
    </Container>
  );
};

ColoredBulletLabel.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string,
  labelColor: PropTypes.string,
  labelVariant: PropTypes.string,
  bulletSize: PropTypes.string,
};
