import React from "react";

// Utils
import styled from "styled-components";

// Components
import { IconButton as MUIIconButton } from "@material-ui/core";
import { Icon, TextInput, Typography } from "components";

// Constants
import { ADD, DELETE } from "constants/icons";
import { useFieldArray } from "react-hook-form";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const IconButton = styled(MUIIconButton)`
  padding: 0;
  margin-left: 10px;
`;
const InputWrapper = styled.div`
  max-width: 418px;
  width: 100%;
`;

const AnswerInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => `
  path {
    fill: ${theme.palette.red};
  }
`}
`;

const DeleteButtonWrapper = styled.div`
  margin-bottom: 24px;
`;

const AnswerInput = ({ control, index, remove }) => {
  return (
    <AnswerInputWrapper>
      <InputWrapper>
        <TextInput
          inputProps={{
            label: "",
            autoFocus: true,
            placeholder: "Name",
          }}
          controllerProps={{
            control,
            name: `answers.${index}.answerText`,
          }}
        />
      </InputWrapper>
      <DeleteButtonWrapper>
        <IconButton
          onClick={() => {
            remove(index);
          }}
        >
          <StyledIcon type={DELETE} />
          <Typography variant="h6" color="red">
            Delete
          </Typography>
        </IconButton>
      </DeleteButtonWrapper>
    </AnswerInputWrapper>
  );
};

export const Answers = ({ control }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "answers",
  });

  const addHandler = () => {
    append({ answerText: "" });
  };

  return (
    <Container>
      <TitleWrapper>
        <Typography variant="h6">Answer</Typography>
        <IconButton onClick={() => {}}>
          <Icon type={ADD} width="16px" color="blueAccent" />
          <Typography variant="h6" color="blueAccent" onClick={addHandler}>
            Add Answer
          </Typography>
        </IconButton>
      </TitleWrapper>
      {fields.map((field, index) => {
        return (
          <AnswerInput
            key={field.id}
            control={control}
            index={index}
            remove={remove}
          />
        );
      })}
    </Container>
  );
};
