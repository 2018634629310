// Axios config
import apiConfig from "./config";

const baseURL = `${process.env.REACT_APP_APIGATEWAY_ENDPOINT}`;

const api = apiConfig(baseURL);

export async function getSurveyQuestions() {
  const { data } = await api.get("/compass/GetQuestions");

  return data.sort(function (a, b) {
    return a.sortOrder - b.sortOrder;
  });
}

export async function createSurveyQuestion(payload) {
  const dataToSend = JSON.stringify(payload);

  const { data } = await api.post("/compass/CreateQuestion", dataToSend);
  return data;
}

export async function updateSurveyQuestion(payload, id) {
  const fullPayload = {
    ...payload,
    id,
  };
  const dataToSend = JSON.stringify(fullPayload);
  const { data } = await api.put(`/compass/UpdateQuestion`, dataToSend);

  return data;
}

export async function deleteSurveyQuestion(id) {
  const dataToSend = JSON.stringify({ questionId: id });
  const { data } = await api.post(`/compass/DeleteQuestion`, dataToSend);

  return data;
}

export async function getSurveyQuestionsForASurvey({ businessType, sectors }) {
  // TODO - replace with real payload
  const payload = { businessType, sectors };

  const dataToSend = JSON.stringify(payload);

  const { data } = await api.post(
    `/compass/SurveyQuestions?code=o3/m0PJ45vrrWJjuIhz4XprLNER4qnWqClXuj8iKx2VFA
  mTcwFT9eQ==`,
    dataToSend
  );

  return data;
}
