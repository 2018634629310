// Components
import { ScrollableMenu } from "components";
import { Typography } from "components/Typography";
import { MenuItem } from "./components";

// Hooks
import { useCategories, useScreens } from "hooks";

import amplitude from "amplitude-js";

export const ScrollableCategoriesMenu = ({
  showAll,
  preselectedCategory,
  onSelect,
  loading,
}) => {
  const { tabletScreens } = useScreens();
  const { categories } = useCategories();

  const handleTabChange = (id, name) => {
    if (loading) return;
    if (onSelect) {
      onSelect(id, name);
    }
    amplitude.getInstance().logEvent("Category", {
      name,
    });
  };

  const getVariant = (isSelected) =>
    isSelected && !tabletScreens ? "h6" : "body1";

  const getColor = (isSelected, isDisabled) => {
    if (isDisabled) {
      return "gray";
    }
    if (isSelected) {
      return !tabletScreens ? "blueAccent" : "white";
    }
    return !tabletScreens ? "darkGray" : "black";
  };

  return (
    <ScrollableMenu indicatorOffset={3} faderHeight={33}>
      {showAll && (
        <MenuItem
          isSelected={preselectedCategory === 0}
          onClick={() => handleTabChange(0)}
          tabletScreens={tabletScreens}
          isDisabled={loading}
        >
          <Typography
            variant={getVariant(preselectedCategory === 0)}
            color={getColor(preselectedCategory === 0, loading)}
          >
            {tabletScreens ? "All Categories" : "All"}
          </Typography>
        </MenuItem>
      )}
      {categories?.map(
        (category) =>
          category?.visible && (
            <MenuItem
              tabletScreens={tabletScreens}
              isSelected={preselectedCategory === category.id}
              onClick={() => handleTabChange(category.id, category.name)}
              key={category.id}
              isDisabled={loading}
            >
              <Typography
                variant={getVariant(preselectedCategory === category.id)}
                color={getColor(preselectedCategory === category.id, loading)}
              >
                {category.name}
              </Typography>
            </MenuItem>
          )
      )}
    </ScrollableMenu>
  );
};
