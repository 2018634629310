import PropTypes from "prop-types";

// Utils
import styled, { css } from "styled-components";

// Components
import { Icon, Typography } from "components";
import { Box, IconButton as MUIIconButton } from "@material-ui/core";
import { InputLabel } from "components/InputLabel";

// Constants
import { ADD } from "constants/index";
import { ifProp } from "styled-tools";

const IconButton = styled(({ isButtonDisabled, ...rest }) => (
  <MUIIconButton {...rest} />
))`
  width: 90px;
  height: 20px;
  padding: 0;
  border-radius: 4px;
  margin-bottom: 10px;

  .MuiIcon-root {
    margin-right: 3px;
  }
  ${ifProp(
    "isButtonDisabled",
    css`
      cursor: auto;
      &:hover {
        background: transparent;
      }
    `
  )}
`;

export function FieldArrayWrapper({
  children,
  addHandler,
  inputLabelProps,
  iconLabel,
  isButtonDisabled,
}) {
  return (
    <Box mt={2.5}>
      <InputLabel {...inputLabelProps} />
      {addHandler && iconLabel && (
        <IconButton
          isButtonDisabled={isButtonDisabled}
          onClick={!isButtonDisabled && addHandler}
        >
          <Icon
            type={ADD}
            width="16px"
            color={isButtonDisabled ? "gray" : "blueAccent"}
          />
          <Typography
            variant="h6"
            color={isButtonDisabled ? "gray" : "blueAccent"}
          >
            {iconLabel}
          </Typography>
        </IconButton>
      )}
      <Box display="flex" flexDirection="column">
        {children}
      </Box>
    </Box>
  );
}

FieldArrayWrapper.propTypes = {
  iconLabel: PropTypes.string,
  addHandler: PropTypes.func,
};
