import { useContext, useState, createContext, useMemo } from "react";

// Hooks
import {
  useHandle409,
  useHandle4xx,
  useHandle500,
  useResetState
} from "./hooks";

// A context will be the way that we allow components lower down
// the tree to trigger the display of an error page
const ErrorHandlerContext = createContext();

const initValue = { status: null, type: "", props: null };

export function ErrorHandlerProvider({ children }) {
  const [{ status, props, type }, setError] = useState(initValue);

  const actionsValue = useMemo(() => ({ setError }), [setError]);

  useResetState(status, setError, initValue);

  useHandle409(status, type, props);

  useHandle500(status, type);

  useHandle4xx(status);

  return (
    <ErrorHandlerContext.Provider value={actionsValue}>
      {children}
    </ErrorHandlerContext.Provider>
  );
}

export const useErrorHandler = () => useContext(ErrorHandlerContext);
