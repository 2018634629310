import React from "react";
import PropTypes from "prop-types";

// Utils
import styled from "styled-components";

// Components
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { Typography } from "components/Typography";

const RadioButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  &:not(last-of-type) {
    margin-right: 20px;
  }
`;

const CustomRadioButton = styled(Radio)`
  &.MuiRadio-root {
    padding: 0;
    margin-right: 4px;
    color: ${({ theme }) => theme.palette.gray};
    width: 20px;
    height: 20px;
    &:hover {
      background: none;
    }
  }
  &.Mui-checked {
    color: ${({ theme }) => theme.palette.blueAccent};
  }
`;

export const RadioButtons = ({ options, selectedValue, setSelectedValue }) => {
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  return (
    <RadioGroup
      row
      aria-label="gender"
      name="gender1"
      value={selectedValue}
      onChange={handleChange}
    >
      {options?.map((option) => {
        return (
          <RadioButtonWrapper key={option.value}>
            <CustomRadioButton
              checked={option.value === selectedValue}
              value={option.value}
            />
            <Typography variant="body1" color="black">
              {option.label}
            </Typography>
          </RadioButtonWrapper>
        );
      })}
    </RadioGroup>
  );
};

RadioButtons.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};
