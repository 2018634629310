import { useEffect } from "react";

// Utils
import { useHistory } from "react-router-dom";

export function useHandle4xx(status) {
  const history = useHistory();

  useEffect(() => {
    if ([401, 404].includes(status)) {
      // if (status === 401) {
      //   localStorage.clear();
      // }

      // history.push("/error");
    }
  }, [status, history]);
}
